import React from "react";
import "react-loading-skeleton/dist/skeleton.css";
import CustomLoader from "../custom/CustomLoader";
import SkeletonPost from "./SkeletonPost";
import SkeletonSearchList from "./SkeletonSearchList";
import SkeletonNotificationItem from "./SkeletonNotificationItem";
import SkeletonEvent from "./SkeletonEvent";
import SkeletonVault from "./SkeletonVault";
import SkeletonTable from "./SkeletonTable";
import SkeletonSusbscription from "./SkeletonSusbscription";
import SkeletonCardManagement from "./SkeletonCardManagement";
import SkeletonBox from "./SkeletonBox";
import SkeletonPaymentHistory from "./SkeletonPaymentHistory";
import SkeletonThreadList from "./SkeletonThreadList";
import SkeletonVaultContentCard from "./SkeletonVaultContentCard";
import SkeletonComments from "./SkeletonComments";
import SkeletonConversation from "./SkeletonConversation";
import SkeletonLiveStreamComment from "./SkeletonLiveStreamComment";
import SkeletonViewChatMedia from "./SkeletonViewChatMedia";
import SkeletonSearchInfluencers from "./SkeletonSearchInfluencers";
import SkeletonStory from "./SkeletonStory";

const SkeletonLoading = ({
  type,
  count = 20,
  rows = 20,
  col = 6,
  height = undefined,
  width = undefined,
  borderRadius = undefined,
  loadMore = false,
  style = {},
}) => {
  switch (type) {
    case "post":
      return <SkeletonPost count={count} />;

    case "searchList":
      return <SkeletonSearchList count={count} />;

    case "notificationItem":
      return <SkeletonNotificationItem count={count} />;

    case "event":
      return <SkeletonEvent count={count} />;

    case "vault":
      return <SkeletonVault count={count} />;

    case "vaultContentCard":
      return <SkeletonVaultContentCard count={count} />;

    case "table":
      return <SkeletonTable rows={rows} col={col} />;

    case "subscription":
      return <SkeletonSusbscription count={count} />;

    case "cardManagement":
      return <SkeletonCardManagement count={2} />;

    case "paymentHistory":
      return <SkeletonPaymentHistory count={8} />;

    case "threadList":
      return <SkeletonThreadList count={count} />;

    case "conversation":
      return <SkeletonConversation count={count} />;

    case "searchInfluencers":
      return <SkeletonSearchInfluencers count={count} loadMore={loadMore} />;

    case "box":
      return (
        <SkeletonBox
          count={count}
          height={height}
          width={width}
          borderRadius={borderRadius}
          style={style}
        />
      );

    case "viewChatMedia":
      return (
        <SkeletonViewChatMedia
          count={count}
          height={height}
          width={width}
          borderRadius={borderRadius}
        />
      );

    case "comments":
      return <SkeletonComments count={count} />;

    case "liveStreamComment":
      return <SkeletonLiveStreamComment />;

    case "story":
      return <SkeletonStory count={count} />;

    default:
      return (
        <div className="noContentFound text-center">
          <CustomLoader />
        </div>
      );
  }
};

export default SkeletonLoading;
