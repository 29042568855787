import { CURRENCY_SYMBOL } from ".";

export const defaultAmounts = [
  { label: `${CURRENCY_SYMBOL}5`, value: `5` },
  { label: `${CURRENCY_SYMBOL}10`, value: `10` },
  { label: `${CURRENCY_SYMBOL}20`, value: `20` },
  { label: `${CURRENCY_SYMBOL}50`, value: `50` },
];

export const notificationTabs = [
  { label: "All Activities", value: "all" },
  { label: "Interactions", value: "interactions" },
  { label: "Post", value: "post" },
  { label: "Alerts", value: "alerts" },
];

export const vaultSortOptionsConfig = [
  {
    label: "Influencer",
    value: "Influencer",
  },
  {
    label: "Locked",
    value: "Locked",
  },
  {
    label: "Unlocked",
    value: "Unlocked",
  },
  {
    label: "Most Popular",
    value: "Most Popular",
  },
  {
    label: "High Price",
    value: "High Price",
  },
  {
    label: "Low Price",
    value: "Low Price",
  },
];

export const settingsPageTabs = [
  { label: "Profile", value: "profile" },
  { label: "Notifications", value: "notifications" },
];

export const profilePageTabs = [
  { label: "Profile Details", value: "profile" },
  { label: "Achievements", value: "achievements" },
];

export const creatorRanksConfig = {
  diamondSupporter: {
    label: "Diamond Supporter",
    value: "diamondSupporter",
    tooltipText:
      "Congrats! You've earned the exclusive Diamond Badge for spending the most for the",
    rank: 1,
  },
  goldSupporter: {
    label: "Gold Supporter",
    value: "goldSupporter",
    tooltipText: "Congrats! You're in the top 5 spenders for the",
    rank: 3,
  },
  silverSupporter: {
    label: "Silver Supporter",
    value: "silverSupporter",
    tooltipText: "Congrats! You're in the top 5 spenders for the",
    rank: 5,
  },
};

export const ranksConfig = {
  topPatron: {
    label: "Top Patron",
    value: "topPatron",
    tooltipText:
      "Congrats! You've earned the exclusive Top Patron Badge for spending the most amount on the platform!",
    rank: 10,
    rankFor: "spend",
  },
  topContributor: {
    label: "Top Contributor",
    value: "topContributor",
    tooltipText:
      "Congrats! You're in the top 50 spenders and have earned the exclusive Top Contributor Badge!",
    rank: 50,
    rankFor: "spend",
  },
  tippingLegend: {
    label: "Tipping Legend",
    value: "tippingLegend",
    tooltipText:
      "Congrats! You're in the top 10 tippers and have earned the exclusive Tipping Legend Badge!",
    rank: 10,
    rankFor: "tip",
  },
  commentKing: {
    label: "Comment King",
    value: "commentKing",
    tooltipText:
      "Congrats! You're in the top 10 commenters and have earned the exclusive Comment King Badge!",
    rank: 10,
    rankFor: "comment",
  },
};

export const badgesConfig = [
  {
    label: "I have a Name",
    value: "iHaveAName",
    tooltipText: "Awarded after creating a unique username.",
  },
  {
    label: "I have a Face",
    value: "iHaveAFace",
    tooltipText: "Awarded after uploading a profile picture.",
  },
  {
    label: "You Know Me",
    value: "youKnowMe",
    tooltipText: "Awarded after selecting your areas of interest.",
  },
  {
    label: "Vault Explorer",
    value: "vaultExplorer",
    tooltipText: "Awarded when you unlock your first vault.",
  },
  {
    label: "Treasure Hunter",
    value: "treasureHunter",
    tooltipText: "Awarded when you unlock 5 vaults.",
  },
  {
    label: "Master Explorer",
    value: "masterExplorer",
    tooltipText: "Awarded when you unlock 10 vaults.",
  },
  {
    label: "Tipper",
    value: "tipper",
    tooltipText: "Awarded after sending first tip.",
  },
  {
    label: "Big Tipper",
    value: "bigTipper",
    tooltipText: "Awarded after sending $100-$499 in tips.",
  },
  {
    label: "Super Tipper",
    value: "superTipper",
    tooltipText: "Awarded when you tip $500 and above in total.",
  },
  {
    label: "I Am Alive",
    value: "iAmAlive",
    tooltipText: "Awarded after attending first event",
  },
  {
    label: "Event Enthusiast",
    value: "eventEnthusiast",
    tooltipText: "Awarded after attending 5 events",
  },
  {
    label: "Always There",
    value: "alwaysThere",
    tooltipText: "Awarded after attending 10 events",
  },
  {
    label: "1st Subscription",
    value: "firstSubscription",
    tooltipText: "Awarded after your first subscription",
  },
  {
    label: "5 Subscriptions",
    value: "fiveSubscriptions",
    tooltipText: "Awarded after your 5th subscription",
  },
  {
    label: "10 Subscriptions",
    value: "tenSubscriptions",
    tooltipText: "Awarded after your 10th subscription",
  },
  {
    label: "1st Post Unlock",
    value: "firstPostUnlock",
    tooltipText: "Awarded after you unlock your first post",
  },
  {
    label: "5 Post Unlock",
    value: "fivePostUnlock",
    tooltipText: "Awarded after you unlock 5 posts",
  },
  {
    label: "10 Post Unlock",
    value: "tenPostUnlock",
    tooltipText: "Awarded after you unlock 10 posts",
  },
  {
    label: "1st Comment",
    value: "firstComment",
    tooltipText: "Awarded after your first comment",
  },
];

export const ranksAndBadgesConfig = [
  {
    label: "Diamond Supporter",
    value: "diamondSupporter",
    tooltipText:
      "Congrats! You've earned the exclusive Diamond Badge for spending the most for the",
    rank: 1,
  },
  {
    label: "Gold Supporter",
    value: "goldSupporter",
    tooltipText: "Congrats! You're in the top 5 spenders for the",
    rank: 3,
  },
  {
    label: "Silver Supporter",
    value: "silverSupporter",
    tooltipText: "Congrats! You're in the top 5 spenders for the",
    rank: 5,
  },
  {
    label: "Top Patron",
    value: "topPatron",
    tooltipText:
      "Congrats! You've earned the exclusive Top Patron Badge for spending the most amount on the platform!",
    rank: 10,
    rankFor: "spend",
  },
  {
    label: "Top Contributor",
    value: "topContributor",
    tooltipText:
      "Congrats! You're in the top 50 spenders and have earned the exclusive Top Contributor Badge!",
    rank: 50,
    rankFor: "spend",
  },
  {
    label: "Tipping Legend",
    value: "tippingLegend",
    tooltipText:
      "Congrats! You're in the top 10 tippers and have earned the exclusive Tipping Legend Badge!",
    rank: 10,
    rankFor: "tip",
  },
  {
    label: "Comment King",
    value: "commentKing",
    tooltipText:
      "Congrats! You're in the top 10 commenters and have earned the exclusive Comment King Badge!",
    rank: 10,
    rankFor: "comment",
  },
  { label: "I have a Name", value: "iHaveAName" },
  { label: "I have a Face", value: "iHaveAFace" },
  { label: "You Know Me", value: "youKnowMe" },
  { label: "Vault Explorer", value: "vaultExplorer" },
  { label: "Treasure Hunter", value: "treasureHunter" },
  { label: "Master Explorer", value: "masterExplorer" },
  { label: "Tipper", value: "tipper" },
  { label: "Big Tipper", value: "bigTipper" },
  { label: "Super Tipper", value: "superTipper" },
  { label: "I Am Alive", value: "iAmAlive" },
  { label: "Event Enthusiast", value: "eventEnthusiast" },
  { label: "Always There", value: "alwaysThere" },
  { label: "1st Subscription", value: "firstSubscription" },
  { label: "5 Subscriptions", value: "fiveSubscriptions" },
  { label: "10 Subscriptions", value: "tenSubscriptions" },
  { label: "1st Post Unlock", value: "firstPostUnlock" },
  { label: "5 Post Unlock", value: "fivePostUnlock" },
  { label: "10 Post Unlock", value: "tenPostUnlock" },
  { label: "1st Comment", value: "firstComment" },
];

export const EmailNotificationsList = [
  { label: "New Uploaded Content", value: "post" },
  { label: "New Audio Post", value: "audio" },
  { label: "New Image Post", value: "image" },
  { label: "New Video Post", value: "video" },
  { label: "New Poll Post", value: "poll" },
  { label: "New Text Post", value: "newTextPost" },
  { label: "New Direct Message", value: "message" },
  { label: "New Comments", value: "comment" },
  {
    label: "New Live Stream",
    value: "newLiveStream",
  },
  {
    label: "Live Stream Started",
    value: "liveStreamStarted",
  },
  {
    label: "New Pay Per View Content",
    value: "newPPVContent",
  },
  {
    label: "New Vault Folder",
    value: "newVaultFolder",
  },
  {
    label: "Receive Daily Digest",
    value: "dailyDigest",
  },
];

export const PushNotificationsList = [
  { label: "New Uploaded Content", value: "post" },
  { label: "New Audio Post", value: "audio" },
  { label: "New Image Post", value: "image" },
  { label: "New Video Post", value: "video" },
  { label: "New Poll Post", value: "poll" },
  { label: "New Text Post", value: "newTextPost" },
  { label: "New Direct Message", value: "message" },
  { label: "New Comments", value: "comment" },
  {
    label: "New Live Stream",
    value: "newLiveStream",
  },
  {
    label: "Live Stream Started",
    value: "liveStreamStarted",
  },
  {
    label: "New Pay Per View Content",
    value: "newPPVContent",
  },
  {
    label: "New Vault Folder",
    value: "newVaultFolder",
  },
];

export const transactionTypes = [
  { value: "subscription", label: "Subscription" },
  { value: "post", label: "Post" },
  { value: "tips", label: "Donation" },
  { value: "message", label: "Message Post" },
  { value: "liveEvent", label: "event" },
  { value: "payperview", label: "ppv" },
  { value: "vault", label: "vault" },
];

export const purchaseTypeConfig = [
  {
    label: "Free",
    value: "free",
  },
  {
    label: "Purchased",
    value: "purchased",
  },
  {
    label: "Not Purchased",
    value: "notpurchased",
  },
];

export const postedDateConfig = [
  {
    label: "Last 7 Days",
    value: 7,
  },
  {
    label: "Last 30 days",
    value: 30,
  },
  {
    label: "Last 3 Months",
    value: 90,
  },
  {
    label: "Last 1 year",
    value: 365,
  },
];

export const durationConfig = [
  {
    label: "Short (Upto 4 mins)",
    value: { upper: 4, lower: 0 },
  },
  {
    label: "Medium (4 mins - 20 mins)",
    value: { upper: 20, lower: 4 },
  },
  {
    label: "Long (More than 20 mins)",
    value: { lower: 20, upper: 0 },
  },
];

export const postFilterOptionsConfig = [
  {
    label: "Post",
    value: "post",
  },
  {
    label: "Image",
    value: "image",
  },
  {
    label: "Video",
    value: "video",
  },
  {
    label: "Audio",
    value: "audio",
  },
  {
    label: "Locked",
    value: "locked",
  },
];

// 3d-payment-for-europe-country
export const europeCountyList = [
  "AT",
  "BE",
  "BG",
  "CY",
  "CZ",
  "DK",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "HU",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "NL",
  "PL",
  "PT",
  "RO",
  "SK",
  "SI",
  "ES",
  "SE",
];

export const videoResolutionsConfig = {
  threeTwoZero: 320,
  fiveFourZero: 540,
  sevenTwoZero: 720,
  oneZeroEightZero: 1080,
};

export const bitrateConfig = {
  fiveFourZero: 200000,
  oneZeroEightZero: 400000,
  sevenTwoZero: 800000,
  threeTwoZero: 1500000,
};

export const tiersConfig = ["freemium", "basic", "plus", "premium"];

export const MIN_SIGNUP_AGE = 13;

export const postDataListners = [
  { key: "post_created", value: "post_created" },
  { key: "post_updated", value: "post_updated" },
  { key: "post_deleted", value: "post_deleted", isRemove: true },
  { key: "like_created", value: "like_created" },
  { key: "like_removed", value: "like_removed", isRemove: true },
  { key: "comment_created", value: "comment_created" },
  { key: "comment_updated", value: "comment_updated" },
  { key: "comment_deleted", value: "comment_deleted", isRemove: true },
];
