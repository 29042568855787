import React, { Component } from "react";
import { createPaymentIntent } from "../../http-calls/index";
import { Button } from "reactstrap";
import {
  deepClone,
  errorHandler,
  formatCurrencyValue,
  handleBrokenImageURL,
} from "../../helper-methods";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../redux/actions/loaderData";
import { DEFAULT_COVER_PICTURE } from "../../config";
import ProcessPayment from "../../payment/index";
import PaymentHoc from "../PaymentHoc";
import ShowMediaModal from "../modals/ShowMediaModal";
import { updateOnlyUserData } from "../../redux/actions";
import IMAGE_ICON from "../../assets/img/image_icon.svg";
import SvgIcons from "../SvgIcons";

class ChatImage extends Component {
  state = {
    isContentLoaded: false,
    hasPurchased: false,
    paymentModal: {
      isVisible: false,
    },
    apiData: {
      url3DSecure: "/payment/intent/message",
      url: "/payment/message",
      payload: {
        influencerId:
          this.props.message?._from?._id || this.props.message?._from, // Influencer id
        messageId:
          this.props.message?.messageId || this.props.message?.tempMessageId,
        contentId: this.props.message?.content[0]?._contentId,
      },
    },
    showMediaModal: {
      isOpen: false,
      externalLink: "",
      contentType: "",
    },
  };

  _purchasePremiumContent = (card = null) => {
    let {
      // isFormValid,
      apiData,
    } = this.state;
    this._dismissPaymentModal();
    this.props.showLoader("Unlocking your content");

    if (card) {
      apiData.payload.card = card;
    }

    apiData.influencer = this.props.message._from;

    return new Promise(async (resolve, reject) => {
      try {
        await ProcessPayment.startPaymentProcess(apiData);
        this.props.hideLoader();
        this._paymentSuccess();
      } catch (error) {
        if (error && error.isRiskAssessment) {
          this.props.updateOnlyUserData({
            ...this.props.userData.user,
            isRiskAssessment: error.isRiskAssessment,
          });
        }
        errorHandler(error);
        this.props.hideLoader();
      }
    });
  };

  _showPaymentModal = () => {
    const { paymentModal } = deepClone(this.state);
    paymentModal.isVisible = true;
    this.setState({ paymentModal });
  };

  _dismissPaymentModal = () => {
    const { paymentModal } = deepClone(this.state);
    paymentModal.isVisible = false;
    this.setState({ paymentModal });
  };

  _toggleShowMediaModal = (
    isOpen = false,
    externalLink = "",
    contentType = ""
  ) => {
    this.setState({
      showMediaModal: {
        isOpen,
        externalLink,
        contentType,
      },
    });
  };

  _handleBrokenImageURL = handleBrokenImageURL();

  _renderMessageView = () => {
    const { message, influencer, imageQuality } = this.props;
    // const { isMediaModalOpen, showMediaLink, shownContentType } = this.state;
    let {
      // contentUrl,
      hasPurchased,
    } = this.state;

    if (message.isPublic || message.isMyMessage) {
      // Generate messsage view
      // Thumbnail view
      return (
        <div className="mediaPhotoWrap">
          <img
            src={
              message.content?.[0]?._contentId?.url?.[imageQuality] ||
              DEFAULT_COVER_PICTURE
            }
            onClick={() =>
              this._toggleShowMediaModal(
                true,
                message?.content?.[0]?._contentId?.url,
                "image"
              )
            }
            alt="Media"
            loading="lazy"
            onError={(e) =>
              this._handleBrokenImageURL({
                event: e,
                url: message?.content?.[0]?._contentId?.url?.[imageQuality],
                fallbackImg: IMAGE_ICON,
              })
            }
          />
        </div>
      );
    } else {
      // Not public
      // Check if already purchased
      if (message.hasPurchased || hasPurchased) {
        // Already purchased
        // Thumbnail view
        return (
          <>
            <div className="mediaPhotoWrap">
              <img
                src={
                  message?.content?.[0]?._contentId?.url?.[imageQuality] ||
                  message.content?.[0]?._contentId?.url ||
                  DEFAULT_COVER_PICTURE
                }
                onClick={() =>
                  this._toggleShowMediaModal(
                    true,
                    message?.content?.[0]?._contentId?.url,
                    "image"
                  )
                }
                alt="Media"
                loading="lazy"
                onError={(e) =>
                  this._handleBrokenImageURL({
                    event: e,
                    url: message?.content?.[0]?._contentId?.url?.[imageQuality],
                    fallbackImg: IMAGE_ICON,
                  })
                }
              />
            </div>
            <div className="paidMessageText">
              Paid{" "}
              {message.content &&
              message.content[0] &&
              message.content[0].amount
                ? formatCurrencyValue(message.content[0].amount)
                : message.amount
                ? formatCurrencyValue(message.amount)
                : ""}{" "}
              for this message
            </div>
          </>
        );
      } else {
        // Not purchased (Show lockedview)
        return (
          <div className="mediaPhotoWrap">
            <img src={DEFAULT_COVER_PICTURE} alt="Media Img" loading="lazy" />
            <div className="postLockedOverlay">
              <i className="fa fa-picture-o mediaIcon" />

              <SvgIcons type="lock" className="iconLock" />

              <Button
                className="paymentBtnTier"
                onClick={this._showPaymentModal}
                disabled={influencer && !influencer.paymentEnabled}
              >
                Unlock image for{" "}
                {message.content &&
                message.content[0] &&
                message.content[0].amount
                  ? formatCurrencyValue(message.content[0].amount)
                  : message.amount
                  ? formatCurrencyValue(message.amount)
                  : ""}
              </Button>
            </div>
          </div>
        );
      }
    }
  };

  // Payment Intent For Apple Pay
  _createPaymentIntent = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { apiData } = this.state;

        const res = await createPaymentIntent(
          apiData?.url3DSecure,
          apiData?.payload
        );

        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  // Payment Done via Apple pay
  _paymentSuccess = () => {
    // Once purchased successfully, set hasPurchased
    this.setState({
      hasPurchased: true,
    });
    this.props.markAsPurchased();
  };

  render() {
    const { paymentModal, showMediaModal } = deepClone(this.state);
    const { message } = this.props;
    return (
      <>
        {this._renderMessageView()}

        {showMediaModal.externalLink ? (
          <ShowMediaModal
            isOpen={showMediaModal.isOpen}
            externalLink={showMediaModal.externalLink}
            contentType={showMediaModal.contentType}
            toggle={() => this._toggleShowMediaModal()}
            imageQuality={this.props.imageQuality}
            setImageQuality={this.props.setImageQuality}
          />
        ) : null}

        <PaymentHoc
          content={{
            name: "Messages",
            price: message?.content?.[0]?.amount
              ? message.content[0].amount
              : message?.amount
              ? message.amount
              : null,
          }}
          createPaymentIntent={() => this._createPaymentIntent()} // create intent fo Apple pay
          onPaymentSuccess={() => this._paymentSuccess()} // success with apple pay
          onDismiss={this._dismissPaymentModal}
          isVisible={paymentModal.isVisible}
          influencer={message._from}
          onPaymentConfirmation={(card) => this._purchasePremiumContent(card)}
          amount={
            message?.content?.[0]?.amount
              ? message.content[0].amount
              : message?.amount
              ? message.amount
              : null
          }
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    chatData: state.chatData,
    loaderData: state.loaderData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateOnlyUserData: (userData) => dispatch(updateOnlyUserData(userData)),
    showLoader: (loaderText) => dispatch(showLoader(loaderText)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatImage);
