import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { APP_LOGO } from "../../config";

const TextDetectModal = ({ isOpen, toggle }) => {
  const _onClose = () => {
    toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => _onClose()}
      className="modal-dialog-centered"
    >
      <ModalBody>
        <div className="d-flex justify-content-center mb-4">
          <img src={APP_LOGO} alt="logo" />
        </div>

        <p>
          Strike: Help us keep your account and our creator community safe.
          Using 3rd party payment systems is a violation of TrueFanz community
          guidelines.
        </p>

        <p>
          Notice, we work hard to ensure TrueFanz is a safe and compliant way
          for creators to earn and share. Please don't bypass the platform. This
          is a warning
        </p>

        <p className="mb-0 pb-0">Kind regards,</p>
        <p>TrueFanz Content Moderation Teama</p>
      </ModalBody>

      <ModalFooter>
        <Button className="modalBtnSave" onClick={() => _onClose()}>
          I Agree
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TextDetectModal;
