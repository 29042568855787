import React from "react";

const ButtonLoading = () => {
  return (
    <>
      <i className="fa fa-spinner fa-spin mr-1" />
    </>
  );
};

export default ButtonLoading;
