import React, { Fragment, useRef, useState } from "react";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  showToast,
  formatTimeFromNow,
  mentionUserInputParse,
  parseForTextField,
  errorHandler,
  getLowResolutionLink,
  EventEmitter,
  deepClone,
} from "../helper-methods";
import CustomPrompt from "../components/modals/CustomPrompt";
import {
  updateComment,
  replyOnComment,
  deleteComment,
  postCommentOnEvent,
  replyOnPPVComment,
  postCommentOnPPV,
  getAllSubComments,
} from "../http-calls/index";
import { DEFAULT_PROFILE_PICTURE } from "../config/index";
import TextareaAutosize from "react-textarea-autosize";
import { useEffect } from "react";
import {
  autoHeightStyleComment,
  autoHeightStyleCommentEdit,
} from "../assets/styles/js";
import { useSelector } from "react-redux";
import ButtonLoading from "../components/ButtonLoading";

const Comments = ({
  isSubComment,
  toggleReplySection,
  comment: commentObj,
  onComment,
  feed,
  feedIndex,
  type,
  getComments,
  likeComment,
  getSubComments,
  commentIdLikeLoading,
}) => {
  const userData = useSelector((state) => state?.userData);
  const replyTextAreaRef = useRef(null);

  const [subComments, setSubComments] = useState({});
  const [subCommentsPagination, setSubCommentsPagination] = useState(10);
  const [subCommentsLoading, setSubCommentsLoading] = useState(false);

  const [comment, setComment] = useState("");
  const [replyComment, setReplyComment] = useState("");
  const [isReplySectionShown, setIsReplySectionShown] = useState(false);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [isEditFieldOpened, setIsEditFieldOpened] = useState(false);
  const [isPromptOpen, setIsPromptOpen] = useState(false);

  useEffect(() => {
    if (commentObj?.content) {
      setComment(commentObj.content);
    }
  }, [commentObj?.content]);

  // for dropdown
  const _toggleDropdown = () => {
    setIsDropDownOpen((prev) => !prev);
  };

  const _getAllSubComments = async (id, isNewCreated = false) => {
    try {
      if (!isNewCreated) {
        setSubCommentsLoading(id);
      }
      const res = await getAllSubComments(id, type, {
        limit: isNewCreated ? 1 : 1000,
      });
      setSubComments((prev) => {
        if (isNewCreated) {
          return {
            [id]: prev[id] ? prev[id].concat(res.comments) : res.comments,
          };
        }
        return {
          [id]: res?.comments || [],
        };
      });
    } catch (error) {
      errorHandler(error);
    } finally {
      setSubCommentsLoading(false);
    }
  };

  const _toggleReplySection = () => {
    if (isSubComment) {
      toggleReplySection();
    } else {
      setIsReplySectionShown((prev) => !prev);
    }
  };

  const _updateComment = (value, isEmoji = false) => {
    if (isEmoji) {
      setComment((prev) => prev + value);
    } else {
      setComment(value);
    }
  };

  const _replyComment = (value) => {
    setReplyComment(value);
  };

  const _clearReplyComment = () => {
    _replyComment("");
  };

  const _onEnterPressed = (event, isReply) => {
    const code = event.keyCode || event.which;

    if (code === 13 && !event.shiftKey && !event.ctrlKey) {
      if (event) event.preventDefault();

      let data = {};

      if (replyComment?.trim().length) {
        // const newReplyComment = replyComment.replace(/\n/g, "<br/>");
        data = {
          content: replyComment?.trim(),
          isSubCommnet: true,
          parentComment: commentObj?._id,
          category: "comment",
        };
        setIsReplySectionShown(false);
        _clearReplyComment();
      }

      if (!comment?.trim().length) {
        return;
      }

      if (isReply) {
        if (data?.content?.trim().length) {
          onComment(feedIndex, true);
          if (type === "ppv") {
            postCommentOnPPV(data, commentObj?._postId)
              .then((response) => {
                getComments();
                _clearReplyComment();
                setIsReplySectionShown(false);
                showToast("Replied successfully", "success");
              })
              .catch((error) => {
                onComment(feedIndex, false);
                errorHandler(error);
              });
          } else if (type === "event") {
            postCommentOnEvent(data, commentObj?._postId)
              .then((response) => {
                getComments();
                _clearReplyComment();
                setIsReplySectionShown(false);
                showToast("Replied successfully", "success");
              })
              .catch((error) => {
                onComment(feedIndex, false);
                errorHandler(error);
              });
          } else {
            replyOnComment(data, commentObj?._postId)
              .then((response) => {
                getComments();
                _clearReplyComment();
                setIsReplySectionShown(false);
                showToast("Replied Successfully", "success");
              })
              .catch((error) => {
                onComment(feedIndex, false);
                errorHandler(error);
              });
          }
        } else {
          setIsEditFieldOpened(false);
          setIsReplySectionShown(false);
        }
      } else {
        // for comment editing
        // const newComment = comment.replace(/\n/g, "<br/>");
        updateComment({ content: comment?.trim() }, commentObj?._id)
          .then((resp) => {
            showToast("Updated Successfully", "success");
            getComments();
          })
          .catch((error) => {
            errorHandler(error);
          });

        setIsEditFieldOpened(false);
        setIsReplySectionShown(false);
      }
    }
  };

  const _toggleCommentField = () => {
    setIsEditFieldOpened((prev) => !prev);
  };

  const _onPromptSuccess = () => {
    onComment(feedIndex, false);

    _togglePromptOpen();

    deleteComment(commentObj?._id, type ? type : "post")
      .then((resp) => {
        getComments();
        if (commentObj?.parentComment && getSubComments) {
          getSubComments();
        }
        showToast("Deleted Successfully", "success");
      })
      .catch((error) => {
        onComment(feedIndex, true);
        errorHandler(error);
      });
  };

  const _togglePromptOpen = (isOpen = false) => {
    setIsPromptOpen(isOpen);
  };

  const _postComment = (isReply = false) => {
    if (!comment?.trim().length) {
      return;
    }

    if (isReply) {
      onComment(feedIndex, true);

      if (replyComment?.trim().length) {
        // const newReplyComment = replyComment.replace(/\n/g, "<br/>");

        const data = {
          content: replyComment?.trim(),
          isSubCommnet: true,
          parentComment: commentObj?._id,
          category: "comment",
        };

        setIsReplySectionShown(false);
        _clearReplyComment();

        if (commentObj?.hasOwnProperty("_payperview")) {
          replyOnPPVComment(data, commentObj?._postId)
            .then((response) => {
              getComments();
              setIsReplySectionShown(false);
              showToast("Replied Successfully", "success");

              _getAllSubComments(commentObj?._id, true);
            })
            .catch((error) => {
              onComment(feedIndex, false);
              errorHandler(error);
            });
        } else {
          replyOnComment(
            data,
            commentObj?._postId,
            commentObj?.hasOwnProperty("_event") ? true : false
          )
            .then((response) => {
              getComments();
              setIsReplySectionShown(false);
              showToast("Replied Successfully", "success");

              _getAllSubComments(commentObj?._id, true);
            })
            .catch((error) => {
              onComment(feedIndex, false);
              errorHandler(error);
            });
        }
      } else {
        setIsEditFieldOpened(false);
      }
    } else {
      // for comment editing
      // const newComment = comment.replace(/\n/g, "<br/>");
      updateComment({ content: comment?.trim() }, commentObj?._id)
        .then((resp) => {
          showToast("Updated Successfully", "success");
          getComments();
        })
        .catch((error) => {
          errorHandler(error);
        });

      setIsEditFieldOpened(false);
    }
  };

  const _likeComment = (id, isLiked) => {
    likeComment(id, isLiked);

    const newSubComments = deepClone(subComments);
    console.log(newSubComments);
    if (newSubComments?.[commentObj?._id]?.length) {
      const findComment = newSubComments[commentObj?._id].find(
        (each) => each._id === id
      );
      if (findComment) {
        findComment._isLiked = !findComment._isLiked;
        setSubComments(newSubComments);

        setTimeout(() => {
          _getAllSubComments(commentObj?._id);
        }, 2000);
      }
    }
  };

  const _postSubCommentTrigger = ({ postId, interaction }) => {
    console.log({ postId, interaction });
    console.log(interaction?.parentComment, commentObj?._id);
    if (interaction?.parentComment === commentObj?._id) {
      _getAllSubComments(interaction?.parentComment);
    }
  };

  useEffect(() => {
    if (isReplySectionShown) {
      setTimeout(() => {
        replyTextAreaRef.current.focus();
      }, 0);
    }
  }, [isReplySectionShown]);

  useEffect(() => {
    if (subComments?.[commentObj?._id]?.length) {
      EventEmitter.subscribe("post_subcomment_trigger", _postSubCommentTrigger);
    }

    return () => {
      EventEmitter.unsubscribe("post_subcomment_trigger");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subComments?.[commentObj?._id]?.length]);

  return (
    <>
      <div className="d-flex">
        <img
          src={
            getLowResolutionLink(commentObj?._user?.profilePicture) ||
            DEFAULT_PROFILE_PICTURE
          }
          onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
          alt="avatar"
          className="userImg-Comment"
          loading="lazy"
        />
        <div className="w-100">
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ marginBottom: 2 }}
          >
            <div className="d-flex">
              <p className="userName-Comment">
                {commentObj?._user?.userType === "Fan"
                  ? `${
                      commentObj?._user?.name.full ||
                      commentObj?._user?.username
                    }`
                  : `@${commentObj?._user?.username}`}
              </p>

              {commentObj?.createdAt ? (
                <div className="commentTime">
                  {formatTimeFromNow(commentObj.createdAt)}
                </div>
              ) : null}
            </div>

            {/* comment edit/delete options */}
            {userData?.user?._id === commentObj?._user?._id &&
            !commentObj?.isLocalComment &&
            !feed?.isDeleted ? (
              <Dropdown
                nav
                className="customDropdown commentOptions"
                isOpen={isDropDownOpen}
                toggle={() => _toggleDropdown()}
              >
                <DropdownToggle nav>
                  <i className="fa fa-ellipsis-h" />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => _toggleCommentField()}>
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => _togglePromptOpen(true)}
                    className="textWarning"
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : null}
          </div>

          <div>
            <div className="comments-Post">
              {isEditFieldOpened ? (
                <>
                  <TextareaAutosize
                    style={autoHeightStyleCommentEdit}
                    minRows={1}
                    placeholder="Add Comment..."
                    onChange={(e) => _updateComment(e.target.value)}
                    onKeyPress={(ev) => _onEnterPressed(ev, false)}
                    value={parseForTextField(comment)}
                  />
                  {/* <Button
                      color="link"
                      className="emojiBtn"
                      style={{ bottom: "8px" }}
                      onClick={._editEmojiSectionToggle}
                    >
                      <i className="fa fa-smile-o" />
                    </Button> */}

                  <Button
                    className="sendMsg"
                    style={{ bottom: 19.5, right: 16 }}
                    disabled={!comment.trim().length}
                    onClick={() => _postComment()}
                  >
                    <img src="/assets/img/send.png" alt="Send" loading="lazy" />
                  </Button>

                  {/* {isEditEmojiSectionShown ? (
                      <Picker
                        onEmojiClick={onEditEmojiClick}
                        skinTone={SKIN_TONE_NEUTRAL}
                      />
                    ) : null} */}
                </>
              ) : (
                <pre>{mentionUserInputParse(comment)}</pre>
              )}
            </div>

            <div className="d-flex align-items-center" style={{ marginTop: 2 }}>
              {!feed?.isDeleted && (
                <Button
                  className="replyBtn"
                  onClick={() => _toggleReplySection()}
                  disabled={commentObj?.isLocalComment}
                >
                  Reply
                </Button>
              )}
              <Button
                className="commentLikeBtn"
                onClick={() => likeComment(commentObj._id, commentObj._isLiked)}
                disabled={
                  commentObj?.isLocalComment ||
                  commentIdLikeLoading === commentObj?._id
                }
              >
                {commentObj._isLiked ? (
                  <i className="fa fa-heart" />
                ) : (
                  <i className="fa fa-heart-o" />
                )}

                {commentObj?._likes?.length ? (
                  <span>{commentObj?._likes?.length || 0}</span>
                ) : null}
              </Button>
            </div>

            {/* Comment Thread */}
            {subComments?.[commentObj?._id]?.length ? (
              <>
                {subComments?.[commentObj?._id]
                  ?.slice(0, subCommentsPagination)
                  ?.map((subComment, subIndex) => (
                    <Fragment key={`subcomment_${subIndex}`}>
                      <Comments
                        comment={subComment}
                        isSubComment={true}
                        toggleReplySection={() => _toggleReplySection()}
                        getComments={() => getComments()}
                        getSubComments={() =>
                          _getAllSubComments(commentObj?._id)
                        }
                        likeComment={() =>
                          _likeComment(subComment._id, subComment._isLiked)
                        }
                        commentIdLikeLoading={commentIdLikeLoading}
                        feed={feed}
                        feedIndex={feedIndex}
                        onComment={(index, isAdded) =>
                          onComment(index, isAdded)
                        }
                        type={type}
                      />
                    </Fragment>
                  ))}
              </>
            ) : null}

            {commentObj?._subComments &&
            (!subComments?.[commentObj?._id] ||
              commentObj?._subComments >
                subComments?.[commentObj?._id]?.length) &&
            commentObj?._subComments -
              (subComments?.[commentObj?._id]?.length || 0) >
              0 ? (
              <Button
                className="replyBtn mb-2"
                disabled={subCommentsLoading === commentObj?._id ? true : false}
                onClick={() => _getAllSubComments(commentObj?._id)}
              >
                {subCommentsLoading === commentObj?._id ? (
                  <ButtonLoading />
                ) : (
                  <>
                    View{" "}
                    {commentObj._subComments -
                      (subComments?.[commentObj?._id]?.length || 0)}{" "}
                    more{" "}
                    {commentObj._subComments -
                      (subComments?.[commentObj?._id]?.length || 0) >
                    1
                      ? "replies"
                      : "reply"}
                  </>
                )}
              </Button>
            ) : subComments?.[commentObj?._id]?.length ? (
              <>
                {subComments?.[commentObj?._id]?.length -
                  subCommentsPagination >
                0 ? (
                  <Button
                    className="replyBtn mb-2"
                    onClick={() =>
                      setSubCommentsPagination((prev) => prev + 10)
                    }
                  >
                    View{" "}
                    {subComments?.[commentObj?._id]?.length -
                      subCommentsPagination}{" "}
                    more{" "}
                    {subComments?.[commentObj?._id]?.length -
                      subCommentsPagination >
                    1
                      ? "replies"
                      : "reply"}
                  </Button>
                ) : null}
              </>
            ) : null}

            {/* comment area inside thread */}
            {isReplySectionShown ? (
              <>
                <div className="d-flex mb-3 position-relative">
                  <img
                    src={
                      getLowResolutionLink(userData?.user?.profilePicture) ||
                      DEFAULT_PROFILE_PICTURE
                    }
                    onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                    alt="Profile"
                    className="userImg-Comment"
                    loading="lazy"
                  />
                  <TextareaAutosize
                    inputRef={replyTextAreaRef}
                    style={autoHeightStyleComment}
                    name=""
                    // id=""
                    minRows={1}
                    placeholder="Add Comment..."
                    value={parseForTextField(replyComment)}
                    onChange={(e) => _replyComment(e.target.value)}
                    onKeyPress={(ev) => _onEnterPressed(ev, true)}
                  />
                  {/* <Button
                      color="link"
                      className="emojiBtn"
                      onClick={._emojiSectionToggle}
                    >
                      <i className="fa fa-smile-o" />
                    </Button> */}

                  <Button
                    className="sendMsg"
                    style={{ bottom: 7 }}
                    disabled={!replyComment.trim().length}
                    onClick={() => _postComment(true)}
                  >
                    <img src="/assets/img/send.png" alt="Send" loading="lazy" />
                  </Button>
                </div>

                {/* {isEmojiSectionShown ? (
                    <Picker
                      onEmojiClick={onEmojiClick}
                      skinTone={SKIN_TONE_NEUTRAL}
                    />
                  ) : null} */}
              </>
            ) : null}
          </div>
        </div>
      </div>

      <CustomPrompt
        isOpen={isPromptOpen}
        message="Are you sure you want to delete?"
        onSuccess={() => _onPromptSuccess()}
        onDismiss={() => _togglePromptOpen()}
      />
    </>
  );
};

export default Comments;
