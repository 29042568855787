import React, { useState, useCallback, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Button } from "reactstrap";
import { APP_LOGO, DEFAULT_PROFILE_PICTURE } from "../config";
import { useHistory } from "react-router-dom";
import { getWindowDimensions } from "../helper-methods";
import { useDispatch, useSelector } from "react-redux";

const responsive = {
  tablet: {
    breakpoint: { max: 1199, min: 768 },
    items: 1,
    partialVisibilityGutter: 220,
  },
  miniTablet: {
    breakpoint: { max: 767, min: 576 },
    items: 1,
    partialVisibilityGutter: 210,
  },
  mobile: {
    breakpoint: { max: 575, min: 100 },
    items: 1,
    partialVisibilityGutter: 90,
  },
};

// const history = useHistory();

const SuggestedCreatorsCard = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const feedData = useSelector((state) => state?.feedData);

  const [screenWidth, setScreenWidth] = useState(0);

  const _getCarouselMediaForCover = useCallback((influencer) => {
    const newCarousel = {};

    if (!influencer?.carouselMediaForCover?.url) {
      return newCarousel;
    }

    newCarousel.url = influencer?.carouselMediaForCover?.url;

    const carouselCrop = influencer?.carouselCrop;

    if (carouselCrop?.length) {
      const findCropObj = carouselCrop?.find(
        (each) => each.carouselIndex === 0 || each.carouselIndex === 1
      );

      if (findCropObj) {
        newCarousel.crop = findCropObj;
      }
    }

    return newCarousel;
  }, []);

  const _changeWidth = () => {
    const { screenWidth } = getWindowDimensions();
    if (screenWidth <= 1000) {
      setScreenWidth(screenWidth);
    }
  };

  const _redirectToInfluencerProfilePage = (influencer) => {
    history.push(`/influencer/${influencer?.username}`);
  };

  useEffect(() => {
    _changeWidth();

    window.addEventListener("resize", _changeWidth, true);

    return () => {
      window.removeEventListener("resize", _changeWidth, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="creatorsSuggestionMobileWrap">
      <div className="d-flex mb-2 justify-content-between align-items-center">
        <h4 className="mb-0">Recommendations</h4>

        <Button color="link" onClick={() => history.push("/search")}>
          See More
        </Button>
      </div>
      <Carousel
        responsive={responsive}
        partialVisible={true}
        draggable={false}
        showDots={false}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        renderButtonGroupOutside={true}
        slidesToSlide={1}
        // removeArrowOnDeviceType={["mobile"]}
        arrows={true}
      >
        {feedData?.suggestedCreators?.map((each, index) => (
          <div
            {...(screenWidth <= 575.98
              ? {
                  style: {
                    width: screenWidth - 110,
                    height: (screenWidth - 110) / 3,
                  },
                }
              : {})}
            className="searchResultsNewWrap"
          >
            <div className="searchResultsNew creatorSuggestions">
              {_getCarouselMediaForCover(each)?.url ? (
                <div className="imgCropWrap">
                  <img
                    src={_getCarouselMediaForCover(each)?.url}
                    alt="cover"
                    onError={(e) => (e.target.src = APP_LOGO)}
                    className={
                      "infCoverImg " +
                        _getCarouselMediaForCover(each)?.crop?.height ===
                      100
                        ? "widthAuto"
                        : "heightAuto"
                    }
                    style={{
                      transform: `translate(-${
                        (_getCarouselMediaForCover(each)?.crop?.x ||
                        _getCarouselMediaForCover(each)?.crop?.x === 0
                          ? _getCarouselMediaForCover(each)?.crop?.x
                          : 0) + "%"
                      }, -${
                        (_getCarouselMediaForCover(each)?.crop?.y ||
                        _getCarouselMediaForCover(each)?.crop?.y === 0
                          ? _getCarouselMediaForCover(each)?.crop?.y
                          : 0) + "%"
                      })`,
                    }}
                  />
                </div>
              ) : (
                <div className="defaultCoverImg">
                  <img src={APP_LOGO} alt="cover" />
                </div>
              )}
              <div className="creatorSuggestionsInfo">
                <img
                  src={each?.profilePicture || DEFAULT_PROFILE_PICTURE}
                  alt="Profile Img"
                  loading="lazy"
                />
                <div>
                  {/* <h5>{each?.name?.full}</h5> */}
                  <Button
                    color="link"
                    onClick={() => _redirectToInfluencerProfilePage(each)}
                  >
                    @{each?.username}
                  </Button>
                </div>
                <Button onClick={() => _redirectToInfluencerProfilePage(each)}>
                  Follow
                </Button>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default SuggestedCreatorsCard;
