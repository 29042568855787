import React, { useMemo } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Col,
  Row,
} from "reactstrap";
import SvgIcons from "../SvgIcons";
import {
  badgesConfig,
  creatorRanksConfig,
  ranksConfig,
} from "../../config/helper-config";
import { useSelector } from "react-redux";
import CustomTooltip from "../custom/CustomTooltip";

const ViewAchievementsModal = ({ isOpen, toggle }) => {
  const _closeModal = () => {
    toggle();
  };

  const userData = useSelector((state) => state?.userData?.user || {});
  console.log(userData);
  const filteredBadge = useMemo(() => {
    if (userData?.earnBadges?.length) {
      return badgesConfig
        .map((each) => {
          const findBadge = userData?.earnBadges?.find(
            (subEach) => subEach?.name === each.value
          );
          if (findBadge) {
            return { ...each, influencer: [], isLocked: false };
          }
          return {
            ...each,
            influencer: [],
            isLocked: true,
          };
        })
        .sort((a, b) => a.isLocked - b.isLocked);
    }

    return badgesConfig.map((each) => ({
      ...each,
      influencer: [],
      isLocked: true,
    }));
  }, [userData?.earnBadges]);

  const filteredRanks = useMemo(() => {
    const newData = [];

    if (userData?.ranks?.length) {
      userData?.ranks
        ?.filter((each) => !each.isLifetimeRank)
        ?.forEach((each) => {
          switch (each.rankFor) {
            case "spend": {
              if (each.rank <= 10) {
                newData.push({
                  ...ranksConfig["topPatron"],
                  rank: each.rank,
                });
              } else if (each.rank <= 50) {
                newData.push({
                  ...ranksConfig["topContributor"],
                  rank: each.rank,
                });
              }
              break;
            }
            case "tip": {
              if (each.rank <= 10) {
                newData.push({
                  ...ranksConfig["tippingLegend"],
                  rank: each.rank,
                });
              }
              break;
            }
            case "comment": {
              if (each.rank <= 10) {
                newData.push({
                  ...ranksConfig["commentKing"],
                  rank: each.rank,
                });
              }
              break;
            }
            default: {
            }
          }
        });
    }

    if (userData?.influencerRank?.length) {
      userData?.influencerRank?.forEach((each) => {
        const findRank = each.topFansRank.find(
          (each) => each._fan === userData?._id
        )?.rank;

        if (findRank) {
          if (findRank === 1) {
            newData.push({
              ...creatorRanksConfig["diamondSupporter"],
              username: each.username,
              rank: findRank,
            });
          } else if (findRank <= 3) {
            newData.push({
              ...creatorRanksConfig["goldSupporter"],
              username: each.username,
              rank: findRank,
            });
          } else if (findRank <= 5) {
            newData.push({
              ...creatorRanksConfig["silverSupporter"],
              username: each.username,
              rank: findRank,
            });
          }
        }
      });
    }

    if (newData?.length) {
      newData.sort((a, b) => a.rank - b.rank);
    }

    return newData;
  }, [userData?.ranks, userData?.influencerRank, userData?._id]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered modal-lg modal-dialog-scrollable"
    >
      <ModalHeader toggle={() => _closeModal()}>
        Your {filteredRanks?.length ? `Ranks &` : ""} Achievements
      </ModalHeader>

      <ModalBody>
        {filteredRanks?.length ? (
          <>
            <h5 className="mb-0">Ranks</h5>
            <hr className="mt-2 mb-1" />

            <Row>
              {filteredRanks.map((each, index) => (
                <Col
                  xs="6"
                  lg="4"
                  key={`view_filteredBadge_${each.value}_${index}`}
                >
                  <div
                    className={`badgeUnlocked ${
                      each.isLocked ? "badgeLocked" : ""
                    }`}
                  >
                    <i className="fa fa-lock" />

                    <div className="rankWrap">Rank #{each.rank}</div>

                    <SvgIcons type={each.value} />
                    <p>
                      {each.label}{" "}
                      {each.tooltipText && !each.isLocked ? (
                        <>
                          <i
                            id={`view_filteredBadge_${each.value}_${index}`}
                            className="fa fa-info-circle"
                          />
                          <CustomTooltip
                            text={`${each.tooltipText} ${
                              each?.username ? "@" + each?.username : ""
                            }`}
                            target={`view_filteredBadge_${each.value}_${index}`}
                          />
                        </>
                      ) : null}
                    </p>
                  </div>
                </Col>
              ))}
            </Row>

            <h5 className="mb-0 mt-4">Achievements</h5>
            <hr className="mt-2 mb-1" />
          </>
        ) : null}

        <Row>
          {filteredBadge.map((each, index) => (
            <Col
              xs="6"
              lg="4"
              key={`view_filteredBadge_${each.value}_${index}`}
            >
              <div
                className={`badgeUnlocked ${
                  each.isLocked ? "badgeLocked" : ""
                }`}
              >
                <i className="fa fa-lock" />

                <SvgIcons type={each.value} />
                <p>
                  {each.label}{" "}
                  {each.tooltipText ? (
                    <>
                      <i
                        id={`view_filteredBadge_${each.value}_${index}`}
                        className="fa fa-info-circle"
                      />
                      <CustomTooltip
                        text={`${each.tooltipText}${each?.influencer?.map(
                          (e, i) => " " + e?.name?.full
                        )}`}
                        target={`view_filteredBadge_${each.value}_${index}`}
                      />
                    </>
                  ) : null}
                </p>
              </div>
            </Col>
          ))}
        </Row>
      </ModalBody>

      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => _closeModal()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewAchievementsModal;
