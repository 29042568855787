import React, { useMemo } from "react";
import { Alert } from "reactstrap";
import ReadMoreReadLess from "./ReadMoreReadLess";
import { ranksAndBadgesConfig } from "../../config/helper-config";
import SvgIcons from "../SvgIcons";

const CustomAchievementAlert = ({
  title,
  value,
  description,
  onDismiss = () => {},
}) => {
  const _onDismiss = () => {
    onDismiss();
  };

  const findBadge = useMemo(() => {
    if (!value) return;

    return ranksAndBadgesConfig.find((each) => each.value === value);
  }, [value]);

  if (!findBadge) return <></>;

  return (
    <Alert
      className="achievementsNotificationsWrap"
      isOpen={true}
      toggle={_onDismiss}
    >
      {/* this div will show once & disappear */}
      <div className="imageContainer">
        <img src="/assets/img/fireworks.gif" alt="Fireworks" />

        <h5>New Achievement !!</h5>
      </div>

      {/* after the above div disappears, this div will show */}
      <div className="achievementsNotifications">
        <SvgIcons type={findBadge?.value} />
        <div className="ml-3">
          <h4>{findBadge?.label}</h4>
          <ReadMoreReadLess text={title + ` 🎉 💐 🎖️`} count={120} />
        </div>
      </div>
    </Alert>
  );
};

export default CustomAchievementAlert;
