import { createSlice } from "@reduxjs/toolkit";
import {
  storeFeeds,
  clearAllFeedData,
  updateAPost,
  updatePostById,
  updateList,
  getAndUpdateAllCreatorBundles,
  fetchPosts,
  deletePostById,
  addSuggestedCreators,
  addSuggestedEvents,
  updateSuggestedCreator,
  updateSuggestedEvent,
} from "../actions";

const initialState = {
  feeds: [],
  total: 0,
  bundles: [],
  subCount: 0, // subscription count
  // recommendations
  suggestedCreators: [],
  // recommendations
  suggestedEvents: [],
};

const feedDataSlice = createSlice({
  name: "feedData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(storeFeeds, (state, action) => {
        state.feeds = action.payload.feeds;
        state.total = action.payload.total;
        state.subCount = action.payload.subCount;
      })

      .addCase(updateAPost, (state, action) => {
        const { index, attr, value } = action.payload;
        state.feeds[index][attr] = value;
      })

      .addCase(updateList, (state, action) => {
        const { index, post } = action.payload;
        state.feeds[index] = post;
      })

      .addCase(clearAllFeedData, (state, action) => {
        state.feeds = [];
        state.total = 0;
        state.bundles = [];
        state.subCount = 0;
      })

      .addCase(fetchPosts.fulfilled, (state, action) => {
        const { posts, total, subCount, isConcat } = action.payload;

        if (isConcat) {
          state.feeds = state.feeds.concat(posts);
        } else {
          state.feeds = posts;
        }

        state.total = total;
        state.subCount = subCount;
      })

      .addCase(updatePostById.fulfilled, (state, action) => {
        const { post } = action.payload;

        const findPostIndex = state?.feeds?.findIndex(
          (each) => each._id === post._id
        );

        if (findPostIndex >= 0) {
          state.feeds[findPostIndex] = {
            ...state.feeds[findPostIndex],
            ...post,
          };
        }
      })

      .addCase(getAndUpdateAllCreatorBundles.fulfilled, (state, action) => {
        state.bundles = action.payload;
      })

      .addCase(deletePostById, (state, action) => {
        const id = action.payload;

        const findPostIndex = state?.feeds?.findIndex(
          (each) => each._id === id
        );

        if (findPostIndex >= 0) {
          const toDelete = state.feeds.splice(findPostIndex, 1);
          if (toDelete?.length) {
            state.total--;
          }
        }
      })

      .addCase(addSuggestedCreators, (state, action) => {
        const { data } = action.payload || {};
        state.suggestedCreators = data || [];
      })

      .addCase(addSuggestedEvents, (state, action) => {
        const { data } = action.payload || {};
        state.suggestedEvents = data || [];
      })

      .addCase(updateSuggestedCreator, (state, action) => {
        const { data } = action.payload || {};
        const findDataIndex = state?.suggestedCreators?.findIndex(
          (each) => each._id === data?._id
        );
        if (findDataIndex >= 0) {
          state.suggestedCreators[findDataIndex] = {
            ...state.suggestedCreators[findDataIndex],
            ...data,
          };
        } else {
          if (data?._id) {
            state.suggestedCreators.push(data);
          }
        }
      })

      .addCase(updateSuggestedEvent, (state, action) => {
        const { data } = action.payload || {};
        const findDataIndex = state?.suggestedEvents?.findIndex(
          (each) => each._id === data?._id
        );
        if (findDataIndex >= 0) {
          state.suggestedEvents[findDataIndex] = {
            ...state.suggestedEvents[findDataIndex],
            ...data,
          };
        } else {
          if (data?._id) {
            state.suggestedEvents.push(data);
          }
        }
      });
  },
});

export const feedDataReducer = feedDataSlice.reducer;
