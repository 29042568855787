import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { convertSecondsToHourMinSec } from "../../../../helper-methods";
import { toggleFloatingMode } from "../../../../redux/actions";

const useVideoPlayer = ({ videoRef, videoDuration }) => {
  const dispatch = useDispatch();

  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    isMuted: false,
    progress: 0,
    volume: 1,
    speed: 1,
    isFullScreen: false,
    isTheatreMode: false,
    loading: false,
  });
  const [duration, setDuration] = useState(null);

  // skip value for skipping forward or backward
  const SKIP_VALUE = 10;

  // function to handling Play/Pause
  const _toggleVideoLoading = (isLoading = false) => {
    try {
      setPlayerState((prev) => ({
        ...prev,
        loading: isLoading,
      }));
    } catch (error) {
      console.log({ error });
    }
  };

  // function to handling Play/Pause
  const _togglePlayPause = () => {
    try {
      setPlayerState((prev) => ({
        ...prev,
        isPlaying: !prev.isPlaying,
      }));
    } catch (error) {
      console.log({ error });
    }
  };

  // function to handling Mute/Unmute
  const _toggleMuteUnmute = () => {
    try {
      if (!videoRef?.current) return;

      videoRef.current.muted = playerState.isMuted ? false : true;

      setPlayerState((prev) => ({
        ...prev,
        isMuted: !prev.isMuted,
        // volume: prev.isMuted ? 1 : 0,
      }));
    } catch (error) {
      console.log({ error });
    }
  };

  // function to handle time update
  const _handleTimeUpdate = () => {
    try {
      if (!videoRef?.current) return;

      const progress =
        (videoRef?.current?.currentTime / videoRef?.current?.duration) * 100;

      if (isNaN(progress)) return;

      setPlayerState((prev) => ({
        ...prev,
        progress,
      }));
    } catch (error) {
      console.log({ error });
    }
  };

  // function to handle time update manually when user seeks progress bar
  const _handleSeekProgress = (e) => {
    try {
      if (!videoRef?.current) return;

      videoRef.current.currentTime =
        (+e.target.value / 100) * videoRef?.current?.duration;

      setPlayerState((prev) => ({
        ...prev,
        progress: +e.target.value,
      }));
    } catch (error) {
      console.log({ error });
    }
  };

  // function to handle full screen toggle
  const _toggleFullScreen = () => {
    try {
      setPlayerState((prev) => ({
        ...prev,
        isFullScreen: !prev.isFullScreen,
        isTheatreMode: false,
      }));
    } catch (error) {
      console.log({ error });
    }
  };

  // function to toggle threatre mode
  const _toggleTheatreMode = () => {
    try {
      setPlayerState((prev) => ({
        ...prev,
        isTheatreMode: !prev.isTheatreMode,
      }));
    } catch (error) {
      console.log({ error });
    }
  };

  // function to toggle floating mode
  const _toggleFloatingMode = (feedId) => {
    try {
      dispatch(toggleFloatingMode(feedId));

      setPlayerState((prev) => ({
        ...prev,
        isTheatreMode: false,
      }));
    } catch (error) {
      console.log({ error });
    }
  };

  // function to handle video playback rate
  const _handlePlaybackSpeed = (e) => {
    try {
      if (!videoRef?.current) return;

      videoRef.current.playbackRate = +e.target.value;

      setPlayerState((prev) => ({
        ...prev,
        speed: +e.target.value,
      }));
    } catch (error) {
      console.log({ error });
    }
  };

  // function to handle volume change
  const _handleVolumeChange = (e) => {
    try {
      if (!videoRef?.current) return;

      const volume = +e.target.value / 100;

      videoRef.current.volume = volume;

      setPlayerState((prev) => ({
        ...prev,
        volume,
        isMuted: volume === 0,
      }));
    } catch (error) {
      console.log({ error });
    }
  };

  // function to handle skipping video
  const _handleSkipVideo = (skipType) => {
    try {
      if (!videoRef?.current) return;

      if (skipType === "forward") {
        videoRef.current.currentTime += SKIP_VALUE;
      } else if (skipType === "backward") {
        videoRef.current.currentTime -= SKIP_VALUE;
      }
    } catch (error) {
      console.log({ error });
    }
  };

  // function to handle event when video ends
  const _handleVideoEnd = () => {
    try {
      setPlayerState((prev) => ({
        ...prev,
        isPlaying: false,
      }));
    } catch (error) {
      console.log({ error });
    }
  };

  // function to get video duration
  const _getCurrentVideoDuration = () => {
    try {
      return (
        convertSecondsToHourMinSec(videoRef?.current?.currentTime) || "00:00"
      );
    } catch (error) {
      console.log({ error });
    }
  };

  // get the video duration on load
  const _loadedmetadata = () => {
    try {
      if (videoRef?.current) {
        setDuration(
          convertSecondsToHourMinSec(videoRef?.current?.duration) ||
            convertSecondsToHourMinSec(videoDuration)
        );
      }
    } catch (error) {
      console.log({ error });
    }
  };

  // update play/pause state
  useEffect(() => {
    const videoElement = videoRef?.current;

    if (!videoElement) return;

    const handleLoadedData = () => {
      // Once video is loaded, check the playback state
      if (playerState.isPlaying) {
        videoElement.play().catch((error) => {
          if (error.name !== "AbortError") {
            console.error("Error during play():", error);
          }
        });
      } else {
        videoElement.pause();
      }
    };

    // Attach listener to handle video load events
    videoElement.addEventListener("loadeddata", handleLoadedData);

    // Play or pause directly if no src change is in progress
    if (videoElement.readyState >= 2) {
      handleLoadedData();
    }

    return () => {
      videoElement.removeEventListener("loadeddata", handleLoadedData);
    };
  }, [playerState.isPlaying, videoRef]);

  // update mute/unmute state
  useEffect(() => {
    try {
      if (videoRef?.current) {
        playerState.isMuted
          ? (videoRef.current.muted = true)
          : (videoRef.current.muted = false);
      }
    } catch (error) {
      console.log({ error });
    }
  }, [playerState.isMuted, videoRef]);

  return {
    playerState,
    duration,
    togglePlayPause: _togglePlayPause,
    toggleMuteUnmute: _toggleMuteUnmute,
    toggleFullScreen: _toggleFullScreen,
    toggleTheatreMode: _toggleTheatreMode,
    toggleFloatingMode: _toggleFloatingMode,
    handleTimeUpdate: _handleTimeUpdate,
    handleSeekProgress: _handleSeekProgress,
    handlePlaybackSpeed: _handlePlaybackSpeed,
    handleVolumeChange: _handleVolumeChange,
    handleSkipVideo: _handleSkipVideo,
    handleVideoEnd: _handleVideoEnd,
    getCurrentVideoDuration: _getCurrentVideoDuration,
    toggleVideoLoading: _toggleVideoLoading,
    loadedmetadata: _loadedmetadata,
  };
};

export default useVideoPlayer;
