import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllStories, likeStory } from "../../http-calls";

const clearAllStories = createAction("clearAllStories");
const updateStoryLikeStatus = createAction("storiesData/updateStoryLikeStatus");
const updateStoryViewStatus = createAction("storiesData/updateStoryViewStatus");
const getAndUpdateStoriesAsync = createAsyncThunk(
  "storiesData/getAndUpdateStoriesAsync",
  async (payload, thunkAPI) => {
    const res = await getAllStories(payload);
    console.log(res);
    return {
      stories: res?.stories,
      storiesNonSub: res?.storiesNonSub,
      liveEvent: res?.liveEvent,
      liveEventNonSub: res?.liveEventNonSub,
    };
  }
)
const nonSubToSubStoryAsync=createAsyncThunk(
  "storiesData/nonSubToSubStoryAsync",
  async (payload, thunkAPI) => {
    return payload;
  }
)
const likeStoryAsync = createAsyncThunk(
  "storiesData/likeStoryAsync",
  async ({ id, isLiked  }, thunkAPI) => {
    try {
      // Call the like API to update the like status
      const response = await likeStory(id, { isLiked });

      if (!response.error) {
        // If the API call is successful, dispatch the action to update the like status in Redux
        thunkAPI.dispatch(updateStoryLikeStatus({ id, isLiked }));
      }

      return response;
    } catch (error) {
      console.error("Error updating like status", error);
      return thunkAPI.rejectWithValue("Failed to update like status");
    }
  }
);

const ViewStoryAsync = createAsyncThunk(
  "storiesData/likeStoryAsync",
  async ({ id, isViewed }, thunkAPI) => {
    try {
      // Call the like API to update the like status
      const response = await likeStory(id, { isViewed });

      if (!response.error) {
        // If the API call is successful, dispatch the action to update the like status in Redux
        thunkAPI.dispatch(updateStoryViewStatus({ id, isViewed }));
      }

      return response;
    } catch (error) {
      console.error("Error updating like status", error);
      return thunkAPI.rejectWithValue("Failed to update like status");
    }
  }
);


export { clearAllStories, getAndUpdateStoriesAsync, likeStoryAsync, updateStoryLikeStatus, ViewStoryAsync ,updateStoryViewStatus,nonSubToSubStoryAsync};
