import React, { useEffect, useRef, useState } from "react";
import {
  TabContent,
  TabPane,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { APP_LOGO } from "../config";
import { useHistory } from "react-router-dom";
import { RegexConfig } from "../config/RegexConfig";
import { checkDuplicateFields, updateFanDetails } from "../http-calls";
import {
  errorHandler,
  extractQueryParams,
  showToast,
  uploadFileOnServer,
} from "../helper-methods";
import { useDispatch, useSelector } from "react-redux";
import { getAndUpdateUserData } from "../redux/actions/userData";
import ImageCropUploaderModal from "../components/modals/ImageCropUploaderModal"

const SignupProtectedPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const [formFields, setFormFields] = useState({
    username: "",
  });

  const [profilePicture, setProfilePicture] = useState({
    url: "",
    previewBlob: "",
    uploadData: null,
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [redirectTo, setRedirectTo] = useState("");
  const [duplicateCheckLoading, setDuplicateCheckLoading] = useState(false);
  const [cropModalContent, setCropModalContent] = useState(false);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);

  const apiValidationTimer = useRef(null);

  // if user skips username or profilePicture
  const _onSkip = (fieldname) => {
    switch (fieldname) {
      case "username":
        setActiveTab(2);
        break;
      case "profilePicture":
        _uploadProfilePicture();
        break;

      default:
    }
  };

  const _switchActiveTab = (tab = 1) => {
    setActiveTab((prev) => {
      if (prev !== tab) {
        setActiveTab(tab);
      }
    });
  };

  // upload profile and redirect to final route
  const _uploadProfilePicture = async (file = null) => {
    try {
      setLoading(true);

      if (file?.uploadData) {
        const serverResponse = await uploadFileOnServer([file]);

        if (serverResponse) {
          await updateFanDetails({ profilePicture: serverResponse?.[0]?.url });
          showToast("Profile Picture uploaded", "success");
        }
      }

      dispatch(getAndUpdateUserData());

      setLoading(false);

      if (redirectTo) {
        history.replace(redirectTo);
      } else if (
        !userData?.user?.isSubscribed &&
        (userData?.user?.hasOwnProperty("_cameVia") ||
          userData?.user?.hasOwnProperty("influencerUsername"))
      ) {
        history.replace(
          `/influencer/${userData?.user.influencerUsername || userData.user._cameVia
          }`
        );
      } else {
        history.replace("/search");
      }
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  // function to handle change in file input
  const _fileChangeHandler = async (e) => {
    try {
      const file = e.target.files?.[0];
      if (!file) return;
      const fileType = file.type.split("/")?.[0];

      if (fileType !== "image") {
        return errorHandler({ reason: "File type must be an image!!" });
      }

      const newProfilePic = {
        previewBlob: URL.createObjectURL(e.target.files[0]),
        uploadData: e.target.files[0],
        uploadUrl: "",
      };

      setProfilePicture(newProfilePic);
      setCropModalContent(file);
      setIsCropModalOpen(true);
    } catch (error) {
      errorHandler(error);
    }
  };

  // reset previewBlob and use url if available
  const _resetPhoto = () => {
    const newProfilePic = {
      uploadData: null,
      previewBlob: null,
      uploadUrl: userData?.user?.profilePicture?.length
        ? userData?.user?.profilePicture
        : "",
    };

    setProfilePicture(newProfilePic);
  };

  const _saveCroppedPhoto = async (croppedImageUrl, croppedImageBlob) => {
    const newProfilePicture = { ...profilePicture };
    setIsCropModalOpen(false);
    newProfilePicture.previewBlob = croppedImageUrl;
    newProfilePicture.uploadData = croppedImageBlob;
    setProfilePicture(newProfilePicture);
  };

  const _toggleCropModal = (value) => {
    setIsCropModalOpen(value);
  };

  // check for availabilty of email, phone
  const _validateAndCheckAvailablity = async (key, value) => {
    const newErrors = {};
    let isFormValid = true;
    switch (key) {
      case "username": {
        if (value?.trim().length) {
          if (RegexConfig?.username?.test(String(value).toLowerCase())) {
            try {
              setDuplicateCheckLoading(true);
              const payload = {
                [key]: value,
              };
              const res = await checkDuplicateFields(payload);
              setDuplicateCheckLoading(false);

              if (res?.isDuplicateFound) {
                newErrors[key] = "This username is already in use";
                isFormValid = false;
              } else {
                newErrors[key] = null;
              }
            } catch (error) {
              errorHandler(error);
            }
          } else {
            newErrors[key] = "*Invalid username";
            isFormValid = false;
          }
        } else {
          newErrors[key] = "*Required";
          isFormValid = false;
        }
        break;
      }

      default:
        break;
    }
    setErrors((prev) => ({ ...prev, ...newErrors }));
    return isFormValid;
  };

  const _onChangeFormFields = (key, value) => {
    if (key === "username") {
      clearTimeout(apiValidationTimer.current);
    }

    const newFormFields = { ...formFields };
    newFormFields[key] = value;
    setFormFields(newFormFields);

    if (key === "username") {
      apiValidationTimer.current = setTimeout(() => {
        _validateAndCheckAvailablity(key, value);
      }, 1000);
    }
  };

  // update username and switch to next tab
  const _updateUserName = async (value = "") => {
    try {
      setLoading(true);
      await updateFanDetails({ username: value });
      setLoading(false);
      showToast("Username updated", "success");
      _switchActiveTab(2);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  // when a tab is changed/submitted with values
  const _onTabSubmit = async (event, forTab) => {
    if (event) event.preventDefault();
    switch (forTab) {
      case 1: {
        const isFormValid = await _validateAndCheckAvailablity(
          "username",
          formFields?.username
        );

        if (!isFormValid) {
          errorHandler({ reason: "Fill field correctly" });
          return;
        }

        _updateUserName(formFields?.username?.trim());

        break;
      }
      case 2: {
        // validate and update profile picture
        const newProfilePicture = { ...profilePicture };

        if (!newProfilePicture?.previewBlob) {
          errorHandler({ reason: "Please select a profile picture" });
          break;
        }

        _uploadProfilePicture(newProfilePicture);
        break;
      }

      default:
    }
  };

  const _extractInfoFromURL = () => {
    try {
      const { redirectTo } = extractQueryParams();
      if (redirectTo?.length) {
        setRedirectTo(redirectTo);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    _extractInfoFromURL();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="animated fadeIn authFormWrapper">
        <div className="loginWrapper" style={{ maxWidth: "unset" }}>
          <img
            src={APP_LOGO}
            alt="Project Logo"
            className="projectLogo"
            onClick={() => history.replace("/")}
            loading="lazy"
          />
          <TabContent activeTab={activeTab} className="signUpWrap">
            {/* tabId="1" */}
            <TabPane tabId={1} className="p-0">
              <div className="authPgFormWrap">
                <Form onSubmit={(event) => _onTabSubmit(event, 1)}>
                  <FormGroup>
                    <Label>Choose Your Username</Label>
                    <div className="position-relative">
                      <Input
                        type="text"
                        placeholder="Choose Your Username"
                        value={formFields?.username}
                        disabled={loading}
                        autoComplete="true"
                        onChange={(e) =>
                          _onChangeFormFields("username", e.target.value)
                        }
                      />
                      {duplicateCheckLoading ? (
                        <div className="spinnerLogin">
                          <i className="fa fa-spinner fa-spin mr-1" />
                        </div>
                      ) : null}
                    </div>
                    {errors?.username ? (
                      <p className="form-error">{errors?.username}</p>
                    ) : null}
                  </FormGroup>

                  <Button
                    className="themeBtn loginBtn"
                    type="submit"
                    disabled={loading}
                  >
                    <span>
                      {loading ? (
                        <i className="fa fa-spinner fa-spin mr-1" />
                      ) : null}{" "}
                      Next
                    </span>
                  </Button>
                </Form>

                {/* username validation skip */}
                <Button
                  color="link"
                  className="remainAnonymous"
                  onClick={() => _onSkip("username")}
                >
                  Remain anonymous
                </Button>
              </div>
            </TabPane>

            {/* tabId="2" */}
            <TabPane tabId={2} className="p-0">
              <div className="authPgFormWrap">
                <Form onSubmit={(event) => _onTabSubmit(event, 2)}>
                  <div className="img120by120 d-block mx-auto mt-2">
                    <div className="imagePreview">
                      {profilePicture?.previewBlob ? (
                        <img
                          src={profilePicture.previewBlob}
                          alt="profile"
                          loading="lazy"
                        />
                      ) : (
                        <i className="fa fa-user-o" />
                      )}
                    </div>

                    <Label className="uploadBtn">
                      <input
                        type="file"
                        style={{ display: "none" }}
                        value={""}
                        onChange={(e) => _fileChangeHandler(e)}
                        accept="image/*"
                        disabled={loading}
                      />
                      <i className="fa fa-pencil" />
                    </Label>
                  </div>

                  <div className="addPicTxt">
                    <h5>Add A Profile Picture</h5>
                    <p>Add a profile picture so your friends know its you</p>
                  </div>

                  <Button
                    className="themeBtn loginBtn"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <span>
                        <i className="fa fa-spinner fa-spin mr-1" /> Uploading
                        picture
                      </span>
                    ) : (
                      <span>Add Profile Picture</span>
                    )}
                  </Button>
                </Form>

                {/* profilePicture skip */}
                <Button
                  color="link"
                  className="remainAnonymous"
                  onClick={() => _onSkip("profilePicture")}
                >
                  Skip for now
                </Button>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </div>

      <ImageCropUploaderModal
        isOpen={isCropModalOpen}
        selectedPhoto={cropModalContent}
        resetPhoto={() => _resetPhoto()}
        onSuccess={_saveCroppedPhoto}
        onDismiss={() => _toggleCropModal(false)}
      />
    </>
  );
};

export default SignupProtectedPage;
