import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { autoHeightStyleChat } from "../../src/assets/styles/js";
import { useDispatch, useSelector } from "react-redux";
import EmojiPickerReact, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import {
  containsRestrictedWords,
  errorHandler, removeLastWord,
  showToast,
  sleepTime
} from "../helper-methods";
import cuid from "cuid";
import {
  addLocalMessage,
  getAndUpdateChatThreads,
  unreadMessageCountUpdate,
  updateMessageKey,
} from "../redux/actions";
import moment from "moment";
import { newSocket } from "../socket-io";
import { likeStoryAsync, ViewStoryAsync } from "../redux/actions/storiesData";
import { copyShareStoryLink } from "../helper-methods";
import TextareaAutosize from "react-textarea-autosize";

function StoryMessageArea({ creator, currentStoryIndex, id, data, isSubscribed, subscribedData }) {
  
  const [isLikedArray, setIsLikedArray] = useState([]);
  // const [isViewedArray, setIsViewedArray] = useState([]);
  const [count, setCount] = useState(0);
  const [loadingState, setLoadingState] = useState({
    messages: false,
  });
  const [textDetectModal, setTextDetectModal] = useState({
    isOpen: false,
  });
  const [isShowEmoji, setIsShowEmoji] = useState(false);
  const [sendMessage, setSendMessage] = useState({
    text: "",
    images: {},
    videos: {},
    audios: {},
    tip: 0,
  });
  const dispatch = useDispatch();
  const storiesData = useSelector((state) => state?.storiesData);
  
  // Find the object in stories where the username matches
  const storyDetails = [
    ...storiesData?.stories,
  ].find(item => item._createdBy?.username === creator?.username);
  const _toggleTextDetectModal = (isOpen = false) => {
    setTextDetectModal({ isOpen });
  };

  useEffect(() => {
    if (data?.isLiked !== undefined && currentStoryIndex >= count) {

      // Ensure isLikedArray is long enough to cover the currentStoryIndex
      setIsLikedArray((prev) => {
        const updatedArray = [...prev]; // Create a copy of the array
        if (updatedArray.length <= currentStoryIndex) {
          // Fill the array with the initial value if the array is too short
          updatedArray[currentStoryIndex] = data?.isLiked;
        } else {
          // If it exists, update the value at the current index
          updatedArray[currentStoryIndex] = data?.isLiked;
        }
        setCount(isLikedArray?.length)
        return updatedArray; // Return the new array
      });
    }
  }, [currentStoryIndex]);

  const handleLikeToggle = async () => {
    const newLikeStatus = !data.isLiked;

    try {
      const res = await dispatch(likeStoryAsync({ id, isLiked: newLikeStatus }));
      if (res) {
        setIsLikedArray((prev) => {
          const updatedArray = [...prev];
          updatedArray[currentStoryIndex] = newLikeStatus;
          return updatedArray;
        });
      }
    } catch (error) {
      console.error("Failed to update like status:", error);
    }
  };

  const handleViewed = () => {
    // const newViewedStatus = !data.isViewed;
    try {
      dispatch(ViewStoryAsync({ id, isViewed: true }));
    } catch (error) {
      console.error("Failed to update like status:", error);
    }
  };
  // console.log(isViewedArray);
  useEffect(() => {
    if (!data?.isViewed) {  // Ensure it's only triggered when isViewed is false
      const timeoutId = setTimeout(() => {
        handleViewed();
      }, 100);
  
      return () => clearTimeout(timeoutId);
      
    }
  }, [currentStoryIndex, data?.isViewed]);

  const userData = useSelector((state) => state?.userData);
  const chatData = useSelector((state) => state?.chatData || {});
  const findThread = chatData?.threads
    ?.map((each) => {
      if (each?.influencer?._id === creator?.id) {
        return each;
      }
      return null;
    })
    .filter((thread) => thread !== null);

  const _toggleIsShowEmoji = (isShow = false) => {
    setIsShowEmoji(isShow);
  };
  const _onMessageTextInputChange = (newText, isEmoji) => {
    const newSendMessage = { ...sendMessage };

    if (containsRestrictedWords(newText)) {
      _toggleTextDetectModal(true);
      newText = removeLastWord(newText);
    }
    if (isEmoji) {
      newSendMessage.text = newSendMessage.text + newText;
    } else {
      newSendMessage.text = newText;
    }
    setSendMessage(newSendMessage);
  };

  const _onEmojiClick = (event, emojiObject) => {
    _onMessageTextInputChange(emojiObject.emoji, true);
  };
  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _sendMessageError = (message) => {
    try {
      dispatch(updateMessageKey({ message, key: "isError", value: true }));
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _markMessageAsSent = (message) => {
    try {
      dispatch(updateMessageKey({ message, key: "isUploading", value: false }));
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _publishMessageOnChannel = (message) => {
    try {
      newSocket.emit("newmessage", message, (res) => {

        //TODO: get message with url in response
        //update in redux either by using getAndUpdateMessage or upate the response locally

        if (res.doSkip) return;

        if (res.error) {
          showToast(`You have not Subscribed ${creator.username} yet. Please Subscribe to chat!`, "error");

        } else {
          showToast("Message sent successfully!", "success");
        }
      });

      if (!newSocket.connected) {
        throw new Error();
      }
    } catch (error) {
      _sendMessageError(message);
      errorHandler(error);
    }
  };

  const _appendAndPublishNewMessage = (message) => {
    if (message.hasMedia) {
      const contentsObj = Object.keys(message.media).map((e) => {
        return {
          _id: message.media[e]._id,
          contentType: message.media[e].contentType,
          url: message.media[e].url,
          hlsLink: message.media[e].hls,
          thumbnail: message.media[e].thumbnail,
        };
      });

      message["content"] = contentsObj.map((e) => {
        return {
          _contentId: {
            _id: e._id,
            url: e.url,
            hlsLink: e.hls,
            thumbnail: e.thumbnail,
            contentType: e.contentType,
            aspectRatio: e.aspectRatio,
          },
          _id: e._id,
          mediaType: e.contentType,
          // thumbnail: e.thumbnail,
          isPublic: message.isPublic,
          amount: message.amount,
        };
      });
    }
    if (findThread) {
      dispatch(
        addLocalMessage({
          threadId: message._thread,
          message: {
            ...message,
            time: moment(message.timestamp).format("h:mm A"),
            isMyMessage: true,
            hasMedia: message.content?.length ? true : false,
          },
        })
      );
    } else {
      _getAndUpdateChatThreads();
    }
    // _scrollToBottomInChatWindow();
    _publishMessageOnChannel(message);
    _markMessageAsSent(message);
  };

  const _getAndUpdateChatThreads = async (isSubscribeAgain = false) => {
    try {
      _manageLoadingState("threads", true);

      await getAndUpdateChatThreads({
        limit: findThread?.threadsCount > 500 ? 5000 : 500,
        skip: 0,
      })(dispatch);

      if (isSubscribeAgain) {
        _unsubscribeToSingleThreadChannel();
        await sleepTime(100);
        _subscribeToSingleThreadChannel();
      }
      _manageLoadingState("threads", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("threads", false);
    }
  };

  const _unsubscribeToSingleThreadChannel = () => {
    try {
      const params = { room: userData.user._id };

      // remove all callback of messageNotifiation event
      newSocket.removeAllListeners("messageNotifiation");

      // unsubscribe event - pause callback
      newSocket.emit("unsubscribe", params, function (res) {
        if (res.error) {
          errorHandler(res);
        }
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _subscribeToSingleThreadChannel = () => {
    try {
      const params = { room: userData.user._id };
      newSocket.emit("subscribe", params, function (res) {
        if (res.error) {
          errorHandler(res);
        }
      });
      newSocket.on("messageNotifiation", (receiveMessage) => {
        if (receiveMessage.doSkip) {
          if (receiveMessage._shoutoutOrder) {
            _saveShoutoutStartCallTime(receiveMessage);
          }
          return;
        }
        if (receiveMessage.error) {
          errorHandler(receiveMessage);
        } else {
          if (receiveMessage._to === userData?.user?._id) {
            if (findThread) {
              dispatch(
                addLocalMessage({
                  threadId: receiveMessage._thread,
                  message: {
                    ...receiveMessage,
                    time: moment(
                      receiveMessage.when || receiveMessage.timestamp
                    ).format("h:mm A"),
                    timestamp: receiveMessage.when || receiveMessage.timestamp,
                    isMyMessage: false,
                    isPublic:
                      receiveMessage?.content?.[0]?.amount > 0 ? false : true,
                  },
                })
              );
            } else {
              _getAndUpdateChatThreads(true);
            }

            dispatch(
              unreadMessageCountUpdate({
                threadId: receiveMessage?._thread,
              })
            );
          }
        }
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _saveShoutoutStartCallTime = (message) => {
    try {
      if (!message) return;
      dispatch(
        updateMessageKey({ message, key: "status", value: message.status })
      );
      dispatch(
        updateMessageKey({
          message,
          key: "startCallTime",
          value: message.startCallTime,
        })
      );
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _sendNewMessage = async () => {
    try {
      if (loadingState.messages) return;
      const { images, videos, audios, text } = sendMessage;
      _toggleIsShowEmoji();
      let messageText = text?.trim().length ? text.trim() : null;
      const tempMessageId = cuid();
      const messagesToBeSent = {};

      // Prepare outbox queue
      // Iterate through images
      // Iterate through images videos audios
      if (
        Object.keys(images).length ||
        Object.keys(videos).length ||
        Object.keys(audios).length
      ) {
        messagesToBeSent[tempMessageId] = {
          _from: userData?.user?._id,
          _to: storyDetails?._createdBy?._id,
          _thread: storyDetails?.stories[0]?.chatThreadId,
          _story: id,
          timestamp: new Date(),
          isUploading: true,
          media: {},
          hasMedia: true,
          isPublic: true,
          tempMessageId,
          isLocalMessage: true,
        };

        messagesToBeSent[tempMessageId]["media"] = {
          ...images,
          ...videos,
          ...audios,
        };
        if (messageText) {
          messagesToBeSent[tempMessageId]["text"] = messageText;
          messageText = null;
        }
      }

      if (Object.keys(messagesToBeSent)?.length === 0 && messageText) {
        messagesToBeSent[tempMessageId] = {
          _from: userData?.user?._id,
          _to: storyDetails?._createdBy?._id,
          _thread: storyDetails?.stories[0]?.chatThreadId,
          _story: id,
          timestamp: new Date(),
          isUploading: true,
          hasMedia: false,
          tempMessageId,
          isLocalMessage: true,
        };
        if (messageText) {
          messagesToBeSent[tempMessageId]["text"] = messageText;
          messageText = null;
        }
      }

      if (Object.keys(messagesToBeSent)?.length) {
        for (let tempMessageId in messagesToBeSent) {
          messagesToBeSent[tempMessageId]["_thread"] = storyDetails?.stories[0]?.chatThreadId;
          // Append as local messages in thread
          _appendAndPublishNewMessage(messagesToBeSent[tempMessageId]);
        }
        //   Clear new message
        setSendMessage({
          text: "",
          images: {},
          videos: {},
          audios: {},
          tip: 0,
        });
      }
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _onMessageTextInputKeyUp = (e) => {
    if (
      e.key === "Enter" &&
      e.target.value.trim().length &&
      !e.ctrlKey &&
      !e.shiftKey
    ) {
      // inputRef.current.blur();
      _sendNewMessage();
    }
  };

  return (
    <>
      {isShowEmoji ? (
        <EmojiPickerReact
          onEmojiClick={_onEmojiClick}
          skinTone={SKIN_TONE_NEUTRAL}
        />
      ) : null}
      <div className="d-flex align-items-end">
        <div className="position-relative flex-grow-1">
          <TextareaAutosize
            style={autoHeightStyleChat}
            rows="1"
            placeholder="Message"
            value={sendMessage.text}
            onChange={(e) => _onMessageTextInputChange(e.target.value)}
            onKeyUp={(e) => _onMessageTextInputKeyUp(e)}
            onFocus={() => _toggleIsShowEmoji()}
          />

          <Button
            color="link"
            className="emojiBtnMsg"
            onClick={() => _toggleIsShowEmoji(isShowEmoji ? false : true)}
          >
            <i className="fa fa-smile-o" />
          </Button>

          <Button
            className="sendMsg"
            disabled={sendMessage.text?.trim().length ? false : true}
            onClick={_sendNewMessage}
          >
            {loadingState.messages ? (
              <i className="fa fa-spinner fa-spin mr-1" />
            ) : (
              <img src="/assets/img/send.png" alt="Send" loading="lazy" />
            )}
          </Button>
        </div>
        <div className="storyActionBtnWrap">
          <Button
            onClick={handleLikeToggle}
            aria-label={isLikedArray[currentStoryIndex] ? "Unlike story" : "Like story"}
            color="link"
            disabled={!data?.chatThreadId || (data?.chatThreadId && subscribedData?.isSubscriptionExpired)}
          >
            <i className={`fa ${isLikedArray[currentStoryIndex] ? "fa-heart" : "fa-heart-o"}`} />
          </Button>

          <Button
            onClick={() =>
              copyShareStoryLink({
                storyId: id,
              })
            }
            color="link"
          >
            <img src="/assets/img/quick-link-white.png" alt="share" />
          </Button>
        </div>
      </div>
    </>
  );
}

export default StoryMessageArea;
