import React, { useState } from "react";
import { Button } from "reactstrap";

const ReadMoreReadLess = ({
  text = "",
  count = 70,
  className = "",
  isHideMoreButton = false,
  btnClassName = "",
  countWithObject = "",
  inlineStyle = { paddingTop: 0, paddingLeft: 6, paddingBottom: 0 },
}) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!text) return <></>;

  // when array of string is received through props from mentionUserInputParse() method
  if (Array.isArray(text)) {
    if (text.join("")?.length <= count)
      return <p className={className}>{text}</p>;

    let isObjectPresent = text.some((value) => {
      return typeof value == "object";
    });

    return (
      <p className={`${className}`}>
        {isOpen
          ? text
          : isObjectPresent
          ? text.slice(0, countWithObject)
          : text.join("").substring(0, count)}

        {isOpen ? "" : "..."}
        {!isHideMoreButton ? (
          <Button
            color="link"
            onClick={() => setIsOpen((prev) => !prev)}
            style={inlineStyle}
            className={btnClassName}
          >
            {isOpen ? "Less" : "More"}
          </Button>
        ) : null}
      </p>
    );
  }

  //when simple text string is received from prop
  if (text.length <= count) return <p className={className}>{text}</p>;

  return (
    <p className={`${className}`}>
      {isOpen ? text : text.slice(0, count) + "..."}

      {!isHideMoreButton ? (
        <Button
          color="link"
          onClick={() => setIsOpen((prev) => !prev)}
        >
          {isOpen ? "Less" : "More"}
        </Button>
      ) : null}
    </p>
  );
};

export default ReadMoreReadLess;
