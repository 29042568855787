import React, { useMemo, useState } from "react";
import { ListGroup, ListGroupItem, Button, Collapse } from "reactstrap";
import ConfigureString from "../components/ConfigureString";
import {
  capitalize,
  getFeatureStatus,
  logout,
  getLowResolutionLink,
} from "../helper-methods";
import { useHistory } from "react-router-dom";
import {
  PRIVACY_POLICY_URL,
  TERMS_OF_SERVICE_URL,
  USC_2257_URL,
  COMMUNITY_GUIDELINES_URL,
  DEFAULT_PROFILE_PICTURE,
} from "../config";
import { useSelector } from "react-redux";
import SvgIcons from "../components/SvgIcons";
import { creatorRanksConfig, ranksConfig } from "../config/helper-config";

const SidebarMenu = ({ isVisibleSidebar, toggleSideBar }) => {
  const history = useHistory();

  const userData = useSelector((state) => state?.userData?.user || {});

  const [isOpenPoliciesCollapse, setIsOpenPoliciesCollapse] = useState(false);

  const isLiveEventActive = getFeatureStatus("event");
  const isPayPerViewActive = getFeatureStatus("ppv");
  const isVaultActive = getFeatureStatus("vault");
  // const isShoutoutActive = getFeatureStatus("shoutout");

  const filteredRanks = useMemo(() => {
    const newData = [];

    if (userData?.ranks?.length) {
      userData?.ranks
        ?.filter((each) => !each.isLifetimeRank)
        ?.forEach((each) => {
          switch (each.rankFor) {
            case "spend": {
              if (each.rank <= 10) {
                newData.push({
                  ...ranksConfig["topPatron"],
                  rank: each.rank,
                });
              } else if (each.rank <= 50) {
                newData.push({
                  ...ranksConfig["topContributor"],
                  rank: each.rank,
                });
              }
              break;
            }
            case "tip": {
              if (each.rank <= 10) {
                newData.push({
                  ...ranksConfig["tippingLegend"],
                  rank: each.rank,
                });
              }
              break;
            }
            case "comment": {
              if (each.rank <= 10) {
                newData.push({
                  ...ranksConfig["commentKing"],
                  rank: each.rank,
                });
              }
              break;
            }
            default: {
            }
          }
        });
    }

    if (userData?.influencerRank?.length) {
      userData?.influencerRank?.forEach((each) => {
        const findRank = each.topFansRank.find(
          (each) => each._fan === userData?._id
        )?.rank;

        if (findRank) {
          if (findRank === 1) {
            newData.push({
              ...creatorRanksConfig["diamondSupporter"],
              username: each.username,
              rank: findRank,
            });
          } else if (findRank <= 3) {
            newData.push({
              ...creatorRanksConfig["goldSupporter"],
              username: each.username,
              rank: findRank,
            });
          } else if (findRank <= 5) {
            newData.push({
              ...creatorRanksConfig["silverSupporter"],
              username: each.username,
              rank: findRank,
            });
          }
        }
      });
    }

    if (newData?.length) {
      newData.sort((a, b) => a.rank - b.rank);
    }

    return newData;
  }, [userData?.ranks, userData?.influencerRank, userData?._id]);

  const _logout = () => {
    logout(history, true);
  };

  const _navigateTo = (route) => {
    setIsOpenPoliciesCollapse(false);
    if (isVisibleSidebar) {
      toggleSideBar();
    }
    history.push(route);
  };

  const _isActiveTab = (route) => {
    return history?.location?.pathname === route;
  };

  return (
    <div className="customSidebar">
      <div className="userInfo-Sidebar">
        <div
          className="img120by120"
          style={{ width: 90, height: 90, marginBottom: 12 }}
        >
          <div className="imagePreview">
            <img
              src={
                getLowResolutionLink(userData?.profilePicture) ||
                DEFAULT_PROFILE_PICTURE
              }
              onError={(e) =>
                (e.target.src =
                  getLowResolutionLink(userData?.profilePicture) ||
                  DEFAULT_PROFILE_PICTURE)
              }
              alt="Avatar"
              loading="lazy"
            />
          </div>
          {filteredRanks?.[0]?.value ? (
            <div className="rankInMenu">
              <SvgIcons type={filteredRanks?.[0]?.value} />
            </div>
          ) : null}
        </div>
        <h5>
          {userData?.name?.first ? capitalize(userData?.name?.first) : null}
        </h5>
        <p>{userData?.username ? `@${userData?.username}` : null}</p>
      </div>
      <ListGroup>
        <ListGroupItem
          tag="a"
          active={_isActiveTab("/manage-subscriptions")}
          onClick={() => _navigateTo("/manage-subscriptions")}
          className="customTopBorder"
        >
          <span>
            <img
              src={"/assets/img/sidemenu/subscription.png"}
              alt="Subscription"
              loading="lazy"
            />
          </span>
          My Subscriptions
        </ListGroupItem>
        {isLiveEventActive && (
          <ListGroupItem
            tag="a"
            active={_isActiveTab("/live-events")}
            onClick={() => _navigateTo("/live-events")}
          >
            <span>
              <img
                src={"/assets/img/sidemenu/live-event.png"}
                alt="Calendar"
                style={{ marginTop: -3 }}
                loading="lazy"
              />
            </span>
            <ConfigureString keyString={"event"} />
          </ListGroupItem>
        )}
        {isPayPerViewActive && (
          <ListGroupItem
            tag="a"
            active={_isActiveTab("/ppv")}
            onClick={() => _navigateTo("/ppv")}
          >
            <span>
              <img
                src={"/assets/img/sidemenu/ppv.png"}
                alt="Eye"
                style={{ height: 15, marginTop: -1 }}
                loading="lazy"
              />
            </span>
            <ConfigureString keyString={"ppv"} />
          </ListGroupItem>
        )}
        {isVaultActive && (
          <ListGroupItem
            tag="a"
            active={_isActiveTab("/vault")}
            onClick={() => _navigateTo("/vault")}
          >
            <span>
              <img
                src={"/assets/img/sidemenu/vault.png"}
                alt="Vault"
                style={{ height: 22, marginTop: -5, marginLeft: 1 }}
                loading="lazy"
              />
            </span>
            <ConfigureString keyString={"vault"} />
          </ListGroupItem>
        )}
        {/* {isShoutoutActive && (
          <ListGroupItem
            tag="a"
            active={_isActiveTab("/shoutout")}
            onClick={() => _navigateTo("/shoutout")}
          >
            <span>
              <i className="fa fa-bullhorn" />
            </span>
            <ConfigureString keyString={"shoutout"}/>
          </ListGroupItem>
        )} */}
        <ListGroupItem
          tag="a"
          active={_isActiveTab("/my-profile")}
          onClick={() => _navigateTo("/my-profile")}
        >
          <span>
            <img
              src={"/assets/img/sidemenu/my-profile.png"}
              alt="my-profile"
              style={{ marginTop: -2 }}
              loading="lazy"
            />
          </span>
          Profile
        </ListGroupItem>
        <ListGroupItem
          tag="a"
          active={_isActiveTab("/settings")}
          onClick={() => _navigateTo("/settings")}
        >
          <span>
            <img
              src={"/assets/img/sidemenu/settings.png"}
              alt="Dollar"
              style={{ marginTop: -2 }}
              loading="lazy"
            />
          </span>
          Settings
        </ListGroupItem>
        <ListGroupItem
          tag="a"
          active={_isActiveTab("/billing/payment-history")}
          onClick={() => _navigateTo("/billing/payment-history")}
          className="customTopBorder"
          style={{ marginTop: 31 }}
        >
          <span>
            <img
              src={"/assets/img/dollar.png"}
              alt="Dollar"
              style={{ marginTop: -1 }}
              loading="lazy"
            />
          </span>
          Payment History
        </ListGroupItem>
        <ListGroupItem
          tag="a"
          active={_isActiveTab("/billing/manage-cards")}
          onClick={() => _navigateTo("/billing/manage-cards")}
        >
          <span>
            <i
              className="fa fa-credit-card"
              style={{ fontSize: 19, marginTop: 1 }}
            />
          </span>
          Manage Cards
        </ListGroupItem>
        <ListGroupItem
          tag="a"
          active={_isActiveTab("/faq")}
          onClick={() => _navigateTo("/faq")}
          className="customTopBorder"
          style={{ marginTop: 31 }}
        >
          <span>
            <i className="fa fa-question-circle-o" style={{ fontSize: 21 }} />
          </span>
          FAQ
        </ListGroupItem>
        <ListGroupItem
          className="d-flex flex-column"
          active={isOpenPoliciesCollapse}
          onClick={() => setIsOpenPoliciesCollapse(!isOpenPoliciesCollapse)}
        >
          <Button className="policyBtn" color="link">
            <div>
              <span>
                <img
                  src={"/assets/img/sidemenu/terms.png"}
                  alt="Privacy"
                  style={{ marginLeft: 1, height: 22 }}
                  loading="lazy"
                />
              </span>
              Policies
            </div>

            <i
              className={`fa fa-chevron-right ${
                isOpenPoliciesCollapse ? "rotateIcon" : ""
              }`}
            />
          </Button>
          <Collapse isOpen={isOpenPoliciesCollapse} style={{ paddingTop: 9 }}>
            <ListGroupItem
              tag="a"
              href={PRIVACY_POLICY_URL}
              target="_blank"
              action
            >
              <span>
                <img
                  src={"/assets/img/sidemenu/privacy.png"}
                  alt="Privacy"
                  style={{ marginTop: -2 }}
                  loading="lazy"
                />
              </span>
              Privacy
            </ListGroupItem>
            <ListGroupItem
              tag="a"
              href={TERMS_OF_SERVICE_URL}
              target="_blank"
              action
            >
              <span>
                <img
                  src={"/assets/img/sidemenu/terms.png"}
                  alt="Terms"
                  style={{ marginLeft: 1, height: 22 }}
                  loading="lazy"
                />
              </span>
              Terms
            </ListGroupItem>
            <ListGroupItem tag="a" href={USC_2257_URL} target="_blank" action>
              <span>
                <img
                  src={"/assets/img/sidemenu/privacy.png"}
                  alt="Compliance"
                  style={{ marginTop: -2 }}
                  loading="lazy"
                />
              </span>
              18 USC 2257
            </ListGroupItem>
            <ListGroupItem
              tag="a"
              href={COMMUNITY_GUIDELINES_URL}
              target="_blank"
              action
            >
              <span>
                <img
                  src={"/assets/img/sidemenu/terms.png"}
                  alt="Guidelines"
                  style={{ marginLeft: 1, height: 22 }}
                  loading="lazy"
                />
              </span>
              Community Guidelines
            </ListGroupItem>
          </Collapse>
        </ListGroupItem>

        <ListGroupItem
          tag="a"
          onClick={() => _logout()}
          className="customTopBorder"
          style={{ marginTop: 31 }}
        >
          <span>
            <img
              src={"/assets/img/sidemenu/logout.png"}
              alt="Logout"
              style={{ height: 20, marginTop: -3 }}
              loading="lazy"
            />
          </span>
          Logout
        </ListGroupItem>
      </ListGroup>
    </div>
  );
};

export default SidebarMenu;
