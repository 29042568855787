import React, { useEffect, useRef, useState } from "react";
import { Col, Card, Button, CardBody } from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../config";
import {
  capitalize,
  errorHandler,
  formatCurrencyValue,
  getLowResolutionLink,
  showToast,
} from "../helper-methods";
import ProcessPayment from "../payment/index";
import VisibilitySensor from "react-visibility-sensor";
import FolderTipModal from "./modals/FolderTipModal";
import PaymentHoc from "./PaymentHoc";
import { useHistory } from "react-router-dom";
import { hideLoader, showLoader, updateOnlyUserData } from "../redux/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import PromptModal from "./modals/PromptModal";
import { createPaymentIntent } from "../http-calls";
import CustomVideoPlayer from "./custom/CustomVideoPlayer";
import { getConfiguredString } from "../helper-methods/configureString";

const VaultCardComponent = ({
  lastElementRef,
  folder,
  influencer,
  updateVaultKey = () => {},
  customClassname,
}) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const userData = useSelector((state) => state?.userData);

  const previewVideoTimerRef = useRef();

  const [paymentApiData, setPaymentApiData] = useState({
    url3DSecure: "/payment/intent/folder",
    url: "/payment/folder",
    payload: {
      folderId: folder?._id,
      postId: null,
    },
  });
  const [paymentModalShown, setPaymentModalShown] = useState(false);
  const [isPlayVideoPreview, setIsPlayVideoPreview] = useState(false);
  const [isPromptModalVisible, setIsPromptModalVisible] = useState(false);
  const [folderTipModal, setFolderTipModal] = useState({
    isOpen: false,
    content: null,
  });

  const _toggleFolderTipModal = (isOpen = false, content = null) => {
    setFolderTipModal({
      isOpen,
      content,
    });
  };

  const _hidePaymentModal = () => {
    setPaymentModalShown(false);
    setIsPromptModalVisible(false);
  };

  const _openPaymentModal = () => {
    setIsPromptModalVisible(false);
    setPaymentModalShown(true);
  };

  // Payment Done via Apple pay
  const _paymentSuccess = () => {
    try {
      _hidePaymentModal();

      dispatch(hideLoader());

      updateVaultKey({
        folderId: folder?._id,
        influencerId: influencer?._id,
        key: "folderPaid",
        value: 1,
      });

      showToast(getConfiguredString("vault has been unlocked"), "success");

      history.push(`/vault/folder/${folder?._id}`);

      // dispatch(
      //   updateVaultKey({
      //     folderId: folder?._id,
      //     influencerId: influencer?._id,
      //     key: "folderPaid",
      //     value: 1,
      //   })
      // );

      // dispatch(getAndUpdateVaults());
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onChangeVisibility = (isVisible, folder) => {
    if (previewVideoTimerRef?.current)
      clearTimeout(previewVideoTimerRef.current);

    if (folder?.previewVideoUrl || folder?.previewHls) {
      if (isVisible) {
        previewVideoTimerRef.current = setTimeout(() => {
          setIsPlayVideoPreview(true);
        }, 4000);
      } else {
        setIsPlayVideoPreview(false);
      }
    }
  };

  const _unlockFolder = async (card = null) => {
    dispatch(showLoader("Payment Processing"));

    const newPaymentApiData = { ...paymentApiData };

    if (card) {
      newPaymentApiData.payload.card = card;
    }

    newPaymentApiData.influencer = influencer;

    try {
      await ProcessPayment.startPaymentProcess(newPaymentApiData, folder.price);
      _paymentSuccess();
    } catch (error) {
      if (error && error.isRiskAssessment) {
        dispatch(
          updateOnlyUserData({
            ...userData.user,
            isRiskAssessment: error.isRiskAssessment,
          })
        );
      }
      errorHandler(error);
      _hidePaymentModal();
      dispatch(hideLoader());
    }
  };

  const _redirectToFolderDetails = (folder) => {
    if (folder?.folderPaid) {
      history.push(`/vault/folder/${folder._id}`);
    } else {
      setIsPromptModalVisible(true);
    }
  };

  // Payment Intent For Apple Pay
  const _createPaymentIntent = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await createPaymentIntent(
          paymentApiData?.url3DSecure,
          paymentApiData?.payload
        );

        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    if (folder?._id) {
      const newPaymentApiData = { ...paymentApiData };
      paymentApiData.payload.folderId = folder._id;
      setPaymentApiData(newPaymentApiData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folder?._id]);

  return (
    <>
      <Col
        xs="6"
        sm="6"
        lg="4"
        className={`vaultCustomCol ${customClassname || ""}`}
      >
        <Card className="cardFeed vaultFolderWrap">
          <CardBody>
            <div
              {...(lastElementRef ? { ref: lastElementRef } : {})}
              className="vaultFolderOverlay"
              onClick={() => _redirectToFolderDetails(folder)}
            />

            <VisibilitySensor
              onChange={(isVisible) => _onChangeVisibility(isVisible, folder)}
            >
              {(folder?.previewVideoUrl || folder?.previewHls) &&
              isPlayVideoPreview ? (
                <div className="vaultFolderPreview">
                  {/* custom video player support hls */}
                  <CustomVideoPlayer
                    // className={`${getAspectRatioClass(
                    //   folder?.previewVideoUrlAspectRatio
                    // )}`}
                    onEnded={() => setIsPlayVideoPreview(false)}
                    src={folder?.previewVideoUrl}
                    hlsLink={folder?.previewHls}
                    duration={folder?.previewVideoUrlDuration}
                    autoPlay
                    muted
                  />
                  <div className="videoPreviewTag">Video Preview</div>
                </div>
              ) : (
                <img
                  src={
                    getLowResolutionLink(folder?.preview) ||
                    DEFAULT_COVER_PICTURE
                  }
                  onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
                  alt="Cover Img"
                  className="folderCoverPic"
                  loading="lazy"
                />
              )}
            </VisibilitySensor>

            <div className="shadowOverlayImg" />

            {!isPlayVideoPreview ? (
              <>
                <div className="vaultFolderInfo">
                  {folder.folderPaid ? (
                    <div
                      className="tipVaultFile"
                      style={{ position: "unset" }}
                      onClick={() => _toggleFolderTipModal(true)}
                    >
                      <img src="/assets/img/tip.png" alt="Tip" loading="lazy" />
                    </div>
                  ) : folder?.price ? (
                    <div className="folderPrice">
                      {formatCurrencyValue(folder.price)}
                    </div>
                  ) : null}

                  {folder.folderPaid ? (
                    <div className="vaultUnlockTxt">Unlocked</div>
                  ) : (
                    <Button
                      className="unlockVaultFolder"
                      onClick={() => setIsPromptModalVisible(true)}
                    >
                      <i className="fa fa-lock" />
                      Unlock
                    </Button>
                  )}
                </div>

                <div className="vaultInfo">
                  <h4>{folder?.name ? capitalize(folder.name) : "N/A"}</h4>

                  <p>{folder.description}</p>

                  <div className="folderItemsCount">
                    <span>
                      {folder?._contents?.length ? folder._contents.length : 0}
                    </span>{" "}
                    {folder?._contents?.length > 1 ? "Items" : "Item"}
                  </div>
                </div>
              </>
            ) : (
              <div className="vaultFolderInfo">
                {folder.folderPaid ? (
                  <div
                    className="tipVaultFile"
                    style={{ position: "unset" }}
                    onClick={() => _toggleFolderTipModal(true)}
                  >
                    <img src="/assets/img/tip.png" alt="Tip" loading="lazy" />
                  </div>
                ) : folder?.price ? (
                  <div className="folderPrice">
                    {formatCurrencyValue(folder.price)}
                  </div>
                ) : null}
              </div>
            )}
          </CardBody>
        </Card>
      </Col>

      <PromptModal
        isVisible={isPromptModalVisible}
        message={"Confirm Payment & Unlock"}
        onSuccess={() => _openPaymentModal()}
        onDismiss={() => setIsPromptModalVisible(false)}
      />

      <PaymentHoc
        content={{
          name: "Vault",
          price: folder.price,
        }}
        influencer={influencer}
        apiData={paymentApiData}
        isVisible={paymentModalShown}
        createPaymentIntent={() => _createPaymentIntent()}
        onPaymentSuccess={() => _paymentSuccess()}
        amount={Number(folder.price).toFixed(2)}
        onPaymentConfirmation={(card) => _unlockFolder(card)}
        onDismiss={() => _hidePaymentModal()}
      />

      <FolderTipModal
        isOpen={folderTipModal.isOpen}
        content={folderTipModal.content}
        toggle={() => _toggleFolderTipModal()}
        folder={folder}
        influencer={influencer}
      />
    </>
  );
};

export default VaultCardComponent;
