import { BASE_URL, CLOUDINARY_CONFIG, IP_FETCH_URL } from "../config/index";
import {
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  uploadFile,
} from "../http-connectors";

export const login = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      makePostRequest(BASE_URL + "/login", false, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log("login call error: ", e);
          reject(e);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const otplessLogin = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      makePostRequest(BASE_URL + "/otp-less-login", false, {
        ...payload,
        userType: "Fan",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log("login call error: ", e);
          reject(e);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const socialLogin = (payload) => {
  return new Promise(async (resolve, reject) => {
    try {
      makePostRequest(
        BASE_URL +
          `/fan/${
            payload.socialAccount === "fb" ? "social" : payload.socialAccount
          }/signup`,
        false,
        payload
      )
        .then((res) => {
          resolve(res);
        })
        .catch((e) => {
          console.log("login call error: ", e);
          reject(e);
        });
    } catch (error) {
      reject(error);
    }
  });
};

export const forgotPassword = (handle) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/forgotpassword", false, { handle })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("forgotPassword call error: ", e);
        reject(e);
      });
  });
};

export const signUp = (payload) => {
  return new Promise(async (resolve, reject) => {
    makePostRequest(BASE_URL + "/signup", false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("signUp call error: ", e);
        reject(e);
      });
  });
};

export const addCard = (token) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/card", true, { token })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("signUp call error: ", e);
        reject(e);
      });
  });
};

export const updateFanDetails = (details) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/fan", true, details)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("signUp call error: ", e);
        reject(e);
      });
  });
};

export const accountDelete = () => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/fan/profile/deactivate", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("accountDelete call error: ", e);
        reject(e);
      });
  });
};

export const getFanDetails = (details) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("signUp call error: ", e);
        reject(e);
      });
  });
};

export const fetchInfluencerDataByUsername = (userName) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan/influencer/summary/" + userName, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("fetchInfluencerDataByUsername call error: ", e);
        reject(e);
      });
  });
};

export const subscribeToInfluencer = (influencerId, data = null) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/subscription/" + influencerId, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("subscribeToInfluencer call error: ", e);
        reject(e);
      });
  });
};

export const subscribeToInfluencerIntent = (influencerId, data = null) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + "/payment/intent/subscription/" + influencerId,
      true,
      data
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("subscribeToInfluencer call error: ", e);
        reject(e);
      });
  });
};

export const freeSubscribeToInfluencer = (influencerId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/subscription/free/" + influencerId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("subscribeToInfluencer call error: ", e);
        reject(e);
      });
  });
};

export const buySubscriptionWithCoupon = (couponToken, influencerId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/fan/coupon/subscription/" + couponToken, true, {
      influencerId,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("subscribeToInfluencer call error: ", e);
        reject(e);
      });
  });
};

export const cloudinaryImageUpload = (imageData, type = "image") => {
  return new Promise((resolve, reject) => {
    const url =
      "https://api.cloudinary.com/v1_1/" +
      CLOUDINARY_CONFIG.API_KEY +
      "/" +
      type +
      "/upload?upload_preset=" +
      CLOUDINARY_CONFIG.UPLOAD_PRESET;
    uploadFile(url, false, imageData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const fetchAllThreads = (pagination) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/chat/threads", true, pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("fetchAllThreads call error: ", e);
        reject(e);
      });
  });
};

export const getAThreadForList = (threadId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/chat/thread/list/" + threadId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAThreadForList call error", e);
        reject(e);
      });
  });
};

export const getMessagesOfAThread = ({
  threadId,
  payload = { limit: 20, skip: 0 },
}) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/chat/thread/${threadId}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getMessagesOfAThread call error: ", e);
        reject(e);
      });
  });
};

export const payForMessage = (influencerId, messageId, contentId) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/payment/message", true, {
      influencerId,
      messageId,
      contentId,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("payForMessage call error: ", e);
        reject(e);
      });
  });
};

export const payForMessageIntent = (influencerId, messageId, contentId) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/payment/intent/message", true, {
      influencerId,
      messageId,
      contentId,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("payForMessage call error: ", e);
        reject(e);
      });
  });
};

export const getPrivateContent = (contentId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/follower/content/" + contentId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getPrivateContent call error: ", e);
        reject(e);
      });
  });
};

export const saveContent = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/content", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getPosts = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/follower/posts", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("postContent call error: ", e);
        reject(e);
      });
  });
};

export const markThreadAsRead = (threadId) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/chat/read/" + threadId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getComments = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/post/comments/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getComments call error: ", e);
        reject(e);
      });
  });
};

// type = post / event / ppv
export const getAllSubComments = (id, type = "post", payload = {}) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/${type}/subcomments/` + id, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getSubComments call error: ", e);
        reject(e);
      });
  });
};

export const postCommentOnFeed = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/post/interaction/" + data.id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("postCommentOnFeed call error: ", e);
        reject(e);
      });
  });
};

export const updateComment = (data, id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/post/update/comment/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateComment call error: ", e);
        reject(e);
      });
  });
};

export const replyOnComment = (data, id, isEvent = false) => {
  return new Promise((resolve, reject) => {
    const apiUrl =
      BASE_URL + (isEvent ? "/event/interaction/" : "/post/interaction/") + id;
    makePostRequest(apiUrl, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateComment call error: ", e);
        reject(e);
      });
  });
};

export const replyOnPPVComment = (data, id, isEvent = false) => {
  return new Promise((resolve, reject) => {
    // const apiUrl =
    //   BASE_URL + (isEvent ? "/event/interaction/" : "/post/interaction/") + id;
    makePostRequest(BASE_URL + "/ppv/interaction/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateComment call error: ", e);
        reject(e);
      });
  });
};

export const deleteComment = (id, interactionType = "post") => {
  return new Promise((resolve, reject) => {
    const apiUrl = BASE_URL + `/${interactionType}/delete/comment/` + id;
    makeDeleteRequest(apiUrl, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("deleteComment call error: ", e);
        reject(e);
      });
  });
};

export const likePost = ({ id, isLiked }) => {
  const data = { category: "like", isLiked };
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/post/interaction/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("likePost call error: ", e);
        reject(e);
      });
  });
};

export const dislikePost = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + "/post/unlike/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("likePost call error: ", e);
        reject(e);
      });
  });
};

export const payTip = (tipData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/payment/tip", true, tipData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const payTipIntent = (tipData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/payment/intent/tip", true, tipData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const payForAPost = (influencerId, postId) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/payment/post", true, {
      influencerId,
      postId,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const payForAPostIntent = (influencerId, postId) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/payment/intent/post", true, {
      influencerId,
      postId,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const givenPollOption = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/follower/post/poll/opinion", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const fetchAllCards = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan/cards", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const makeCardDefault = (cardId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/fan/card/default/" + cardId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const makeCardDefaultForHumboldt = (cardId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/fan/humboldt/card/default/" + cardId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const removeCard = (cardId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/fan/card/delete/" + cardId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const addCardAndMakeDefault = (token) => {
  console.log(token);
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/card/default", true, { token })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("signUp call error: ", e);
        reject(e);
      });
  });
};

export const getAllSubscriptionDetails = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan/subscriptions", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllSubscriptionDetails call error: ", e);
        reject(e);
      });
  });
};

export const pauseSubscription = (influencerId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/fan/subscriptions/pause/" + influencerId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("pauseSubscription call error: ", e);
        reject(e);
      });
  });
};

export const renewSubscription = (influencerId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/fan/subscriptions/renew/" + influencerId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("renewSubscription call error: ", e);
        reject(e);
      });
  });
};

export const renewSubscriptionIntent = (influencerId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + "/payment/intent/subscriptions/renew/" + influencerId,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("renewSubscription call error: ", e);
        reject(e);
      });
  });
};

export const searchInfluencers = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/search/influencers", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("searchInfluencers call error: ", e);
        reject(e);
      });
  });
};

export const flagPost = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/report", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("flagPost call error: ", e);
        reject(e);
      });
  });
};

export const getIpData = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(IP_FETCH_URL, false, {}, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getIpData call error: ", e);
        reject(e);
      });
  });
};

export const getInfluencerDetails = (id, pagination) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/influencer/" + id, true, pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getInfluencerDetails -> e", e);
        reject(e);
      });
  });
};

export const getPublicInfluencerDetails = (id, pagination) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/public/influencer/" + id, false, pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getInfluencerDetails -> e", e);
        reject(e);
      });
  });
};

export const removeDeviceOnLogout = () => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/device/logout", true, {
      logoutType: "current",
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getPaymentHistories = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/payments", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getPaymentHistories -> e", e);
        reject(e);
      });
  });
};

export const getPostDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/follower/post/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getPostDetails -> e", e);
        reject(e);
      });
  });
};

export const getAllNotifications = (pagination) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/follower/notifications", true, pagination)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const readAllNotifications = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/follower/notifications/read", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const readSingleNotification = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/follower/read/notification/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const createThread = (creatorId) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/chat/thread", true, {
      _to: creatorId,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createThread call error: ", e);
        reject(e);
      });
  });
};

export const getVaults = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan/folders", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getInfluencerDetails -> e", e);
        reject(e);
      });
  });
};

export const getFolderDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan/folder/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getInfluencerDetails -> e", e);
        reject(e);
      });
  });
};

export const getFolderContents = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/fan/folder/contents/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getInfluencerDetails -> e", e);
        reject(e);
      });
  });
};

export const payForAFolder = (folderId, postId = null) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/payment/folder", true, {
      folderId,
      postId,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const payForAFolderIntent = (folderId, postId = null) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/payment/intent/folder", true, {
      folderId,
      postId,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getAllFolders = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/fan/influencer/folders/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getInfluencerDetails -> e", e);
        reject(e);
      });
  });
};

export const getInfluencerShoutOutPricing = (username) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan/shoutOutPricing/" + username, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getInfluencerDetails -> e", e);
        reject(e);
      });
  });
};

export const getIndividualInfluencerLiveEvents = (id, data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/events/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getIndividualInfluencerLiveEvents -> e", e);
        reject(e);
      });
  });
};

export const getIndividualInfluencerPPV = (id, data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/payperviews/all/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getIndividualInfluencerPPV -> e", e);
        reject(e);
      });
  });
};

export const likeComment = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/comment/like/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: likeComment -> e", e);
        reject(e);
      });
  });
};

export const likeStory = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/story/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: likeStory -> e", e);
        reject(e);
      });
  });
};

export const searchMessageByText = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/chat/threads/search", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllLiveEvents = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/events", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("searchInfluencers call error: ", e);
        reject(e);
      });
  });
};

export const likeDislikeEvent = (id, isLiked) => {
  return new Promise((resolve, reject) => {
    const payload = {
      like: isLiked ? false : true,
      unlike: isLiked ? true : false,
    };
    makePutRequest(BASE_URL + `/event/like/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("likeDislike call error: ", e);
        reject(e);
      });
  });
};

export const getEventComments = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/event/comments/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllOffers call error: ", e);
        reject(e);
      });
  });
};

export const postCommentOnEvent = (data, id = null) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/event/interaction/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("postCommentOnFeed call error: ", e);
        reject(e);
      });
  });
};

export const fetchEvent = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan/event/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("fetchEvent call error: ", e);
        reject(e);
      });
  });
};

/**
 *
 * @param {string} id - event id
 * @returns
 */
export const getLiveEventStreamingData = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/event/token/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fetchPPVDetails = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan/payperviews/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("payperviews call error: ", e);
        reject(e);
      });
  });
};

export const updateRsvp = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/fan/event/rsvp/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("updateRsvp call error: ", e);
        reject(e);
      });
  });
};

export const eventPayment = (id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/payment/event", true, {
      eventId: id,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("eventPayment call error: ", e);
        reject(e);
      });
  });
};

export const eventPaymentIntent = (id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/payment/intent/event", true, {
      eventId: id,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("eventPaymentIntent call error: ", e);
        reject(e);
      });
  });
};

export const fetchAllPayPerViews = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/payperviews/all", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("searchInfluencers call error: ", e);
        reject(e);
      });
  });
};

export const likeDislikePPV = (id, isLiked) => {
  return new Promise((resolve, reject) => {
    const payload = {
      like: isLiked ? false : true,
      unlike: isLiked ? true : false,
    };
    makePutRequest(BASE_URL + `/ppv/like/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("likeDislike call error: ", e);
        reject(e);
      });
  });
};

export const updateFavoritePPV = (id, isFavourite) => {
  return new Promise((resolve, reject) => {
    const payload = {
      doFavourite: isFavourite,
    };
    makePutRequest(BASE_URL + `/fan/payperview/favourite/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("likeDislike call error: ", e);
        reject(e);
      });
  });
};

export const getPPVComments = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/ppv/comments/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllOffers call error: ", e);
        reject(e);
      });
  });
};

export const postCommentOnPPV = (data, id = null) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/ppv/interaction/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("postCommentOnFeed call error: ", e);
        reject(e);
      });
  });
};

export const payForPPV = (id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/payment/ppv", true, {
      ppvId: id,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("payForPPV call error: ", e);
        reject(e);
      });
  });
};

export const payForPPVIntent = (id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/payment/intent/ppv", true, {
      ppvId: id,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("payForPPVIntent call error: ", e);
        reject(e);
      });
  });
};

export const fetchInfluencerList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan/influencers/list", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("fetchInfluencerList call error: ", e);
        reject(e);
      });
  });
};

export const increaseViewCount = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/fan/payperview/view/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("fetchInfluencerList call error: ", e);
        reject(e);
      });
  });
};

export const upgradeTier = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/subscription/upgrade/tier/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("upgradeTier call error: ", e);
        reject(e);
      });
  });
};

export const upgradeTierIntent = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + "/payment/intent/subscription/upgrade/tier/" + id,
      true,
      data
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("upgradeTier call error: ", e);
        reject(e);
      });
  });
};

export const upgradeBundle = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/subscription/upgrade/bundle/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("upgradeTier call error: ", e);
        reject(e);
      });
  });
};

export const upgradeBundleIntent = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + "/payment/intent/subscription/upgrade/bundle/" + id,
      true,
      data
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("upgradeTier call error: ", e);
        reject(e);
      });
  });
};

export const downgradeTier = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/subscription/downgrade/tier/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("upgradeTier call error: ", e);
        reject(e);
      });
  });
};

export const fetchAllTypesOfInfluencer = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan/influencers", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("fetchAllTypesOfInfluencer call error: ", e);
        reject(e);
      });
  });
};

export const getAllSubscribedInfluencerId = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan/subscriptions/online", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("getAllSubscribedInfluencerId call error: ", e);
        reject(e);
      });
  });
};

export const createShoutOutRequest = (apiData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/shoutOutOrder", true, apiData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createShoutOutRequest call error: ", e);
        reject(e);
      });
  });
};

export const myShoutOutOrders = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/shoutOutOrders", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createShoutOutRequest call error: ", e);
        reject(e);
      });
  });
};

export const getGlobalShoutOut = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/shoutout/influencer", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("createShoutOutRequest call error: ", e);
        reject(e);
      });
  });
};

export const postCommentInShoutOut = (id, text) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/shoutOutOrder/comment/" + id, true, text)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("postCommentInShoutOut call error: ", e);
        reject(e);
      });
  });
};

export const fetchAllShoutoutComment = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/shoutOutOrder/comments/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("postCommentInShoutOut call error: ", e);
        reject(e);
      });
  });
};

export const readShoutOut = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/fan/shoutout/read/" + id, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("readShoutOut call error: ", e);
        reject(e);
      });
  });
};

export const shoutOutOrderFaceTimeId = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/facetime/shoutOutOrder/" + id, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("shoutOutOrderFaceTimeId call error: ", e);
        reject(e);
      });
  });
};

export const createPaymentIntent = (url, data = {}, resquestType) => {
  return new Promise((resolve, reject) => {
    resquestType === "Put"
      ? makePutRequest(BASE_URL + url, true, data)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            console.log("createPaymentIntent call error: ", e);
            reject(e);
          })
      : makePostRequest(BASE_URL + url, true, data)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            console.log("createPaymentIntent call error: ", e);
            reject(e);
          });
  });
};

export const directPayment = (url, data = {}, resquestType) => {
  return new Promise((resolve, reject) => {
    resquestType === "Put"
      ? makePutRequest(BASE_URL + url, true, data)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            console.log("directPayment call error: ", e);
            reject(e);
          })
      : makePostRequest(BASE_URL + url, true, data)
          .then((res) => {
            resolve(res);
          })
          .catch((e) => {
            console.log("directPayment call error: ", e);
            reject(e);
          });
  });
};

export const getPublicShoutoutDetails = (orderId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/shoutout/public/" + orderId, false)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getPublicShoutoutDetails -> e", e);
        reject(e);
      });
  });
};

export const screenshot = (apiData) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/screenshot", true, apiData)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: screenshot -> e", e);
        reject(e);
      });
  });
};

export const getFanDefaultCardCountry = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/default/country", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("TCL: getFanDefaultCardCountry -> e", e);
        reject(e);
      });
  });
};

/**
 * Check for duplicate username, email, phone
 * @returns
 */
export const checkDuplicateFields = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/user/uniques", false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTemplateName = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/settings/templatename`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Get all Bundles Fans
 */
export const getAllBundles = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/bundles", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getMessageMediaContents = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/media/contents", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// update fan discord user id
export const updateFanDiscordUserId = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/update-discord-details", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getMediaContents = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/fan/library/contents", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getUnreadMessageCount = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/chat-thread/unread-count", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getAllCreatorBundles = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/fan/get/influencers/bundles", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// help module api - start

export const getAllFaqTopics = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/faq-topics`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllFaqMiscellaneous = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/faq-miscellaneous`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllFaqCategories = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/faq-categories`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const findFaqCategoryById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/faq-category/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const findFaqTopicById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/faq-topic/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// help module api - end

export const getContentUrlById = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/contents/signed-url`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * api for amount check
 * @param {object} params
 * @param {string} params.id // influencer id
 * @param {object} params.payload // { tier, bundleId }
 * @returns
 */
export const changeSubscriptionAmount = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/fan/bundle/amount/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * normal payment
 * @param {object} params
 * @param {string} params.id // influencer id
 * @param {object} params.payload // { tier, bundleId }
 * @returns
 */
export const changeSubscription = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/fan/subscriptions/change/bundle/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * 3d-payment
 * @param {object} params
 * @param {string} params.id // influencer id
 * @param {object} params.payload // { tier, bundleId }
 * @returns
 */
export const changeSubscriptionIntent = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/payment/intent/subscription/change/bundle/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getPostDetailsPublic = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/follower/public/post/${id}`)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getSettings = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/settings", false)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getAllStories = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/influencewithstory", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getStoryOfInfuencerById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/stories/${id}`, true)
      .then((res) => {
        resolve(res);
        // console.log(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const getSuggestedCreatorsAndEvents = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/sidebar", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
