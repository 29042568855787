import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Container,
  ListGroup,
  Row,
  FormGroup,
  Input,
  Nav,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotificationsAsync,
  unreadNotificationCountUpdate,
} from "../redux/actions/notificationData";
import { readAllNotifications } from "../http-calls";
import { useHistory } from "react-router-dom";
import { notificationTabs } from "../config/helper-config";
import { errorHandler, generateCalenderDate } from "../helper-methods";
import NotificationItem from "../components/NotificationItem";
import PageTab from "../components/PageTab";
import CustomLoader from "../components/custom/CustomLoader";
import SkeletonLoading from "../components/SkeletonLoading";
import ErrorBoundary from "../components/ErrorBoundary";

const NotificationsPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { isLoading, notifications, hasMore, unreadNotificationCount } =
    useSelector((state) => state?.notificationData || {});

  const [activeTab, setActiveTab] = useState("all"); // "interactions", "post", "alert"

  const [isReadAll, setIsReadAll] = useState(false);

  const [dataPayload, setDataPayload] = useState({
    filter: {
      type: [], //"post", "comment", "alert"
    },
    skip: 0,
    limit: 20,
  });

  const _unreadNotificationCountUpdate = (count = 0) => {
    dispatch(unreadNotificationCountUpdate(count));
  };

  const _readAllNotifications = () => {
    try {
      if (!unreadNotificationCount) return;

      // if (activeTab === "all") {
      //   readAllNotifications({ category: undefined });
      // } else {
      //   readAllNotifications({
      //     category: [activeTab],
      //   });
      // }

      readAllNotifications();

      _unreadNotificationCountUpdate(0);

      setIsReadAll(true);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _fetchNotifications = (payload) => {
    setIsReadAll(false);
    dispatch(fetchNotificationsAsync(payload));
  };

  //sets filtertype based on TabField then fetch respective tab's notification
  const _prepareNotificationPayload = (tabName) => {
    const newDataPayload = {
      filter: {
        type: [],
      },
      skip: 0,
      limit: 20,
    };

    switch (tabName) {
      case "all":
        delete newDataPayload.filter;
        break;
      case "interactions":
        newDataPayload.filter.type.push("like", "comment");
        break;
      case "post":
        newDataPayload.filter.type.push("post", "event", "vault", "ppv");
        break;
      case "alerts":
        newDataPayload.filter.type.push("tips", "subscription");
        break;
      default:
        delete newDataPayload.filter;
        break;
    }

    setDataPayload(newDataPayload);
    _fetchNotifications(newDataPayload);
  };

  const _onTabChange = (tabName) => {
    setActiveTab(tabName);
    _prepareNotificationPayload(tabName);
  };

  const observer = useRef(null);

  const lastElementRef = useCallback(
    (node) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          const newDataPayload = { ...dataPayload };
          newDataPayload.skip = notifications?.length;
          setDataPayload(newDataPayload);
          _fetchNotifications(newDataPayload);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  useEffect(() => {
    // fetch notification
    _fetchNotifications(dataPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col sm={12} lg={10} className="pgPadding">
            <div className="pgTitle notificationPgTitle">
              <div className="d-flex align-items-center">
                <h2>Notifications</h2>
                {isLoading ? <CustomLoader className="ml-2" /> : null}

                {/* mobile dropdown view */}
                <FormGroup className="notificationSelect">
                  <Input
                    type="select"
                    name="notificationSelect"
                    value={activeTab}
                    onChange={(event) => _onTabChange(event.target?.value)}
                  >
                    {notificationTabs.map((tab) => (
                      <option key={tab?.value} value={tab?.value}>
                        {tab?.label}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </div>

              <div>
                <Button
                  className="customPopoverBtn"
                  style={{ padding: "0 15px" }}
                  onClick={() => history.push(`/settings?tabId=notifications`)}
                >
                  <i className="fa fa-gear" style={{ verticalAlign: -1 }} />
                </Button>

                {unreadNotificationCount ? (
                  <Button
                    className="themeBtn addBtn ml-2"
                    onClick={() => _readAllNotifications()}
                  >
                    Mark All Read
                  </Button>
                ) : null}
              </div>
            </div>

            {/* tab view (for web)  */}
            <div className="notificationContentWrap">
              <Nav tabs className="customTabs tab-Notification">
                {notificationTabs.map((tab) => (
                  <PageTab
                    key={tab?.value}
                    tabValue={tab?.value}
                    tabLabel={tab?.label}
                    onTabChange={_onTabChange}
                    selectedTab={activeTab}
                  />
                ))}
              </Nav>

              {notifications?.length ? (
                <ListGroup style={{ overflow: "hidden" }}>
                  {notifications.map((notification, index) => (
                    <Fragment key={`${notification?._id}_${index}`}>
                      <ErrorBoundary>
                        <NotificationItem
                          notification={notification}
                          prevDateHeading={generateCalenderDate(
                            notifications?.[index - 1]?.createdAt
                          )}
                          currDateHeading={generateCalenderDate(
                            notification?.createdAt
                          )}
                          isReadAll={isReadAll}
                          {...(index === notifications.length - 1
                            ? { lastElementRef }
                            : {})}
                        />
                      </ErrorBoundary>
                    </Fragment>
                  ))}
                </ListGroup>
              ) : isLoading ? (
                <SkeletonLoading type={"notificationItem"} count={10} />
              ) : (
                <div className="noContentFound">No Notifications Currently</div>
              )}

              {isLoading && hasMore ? (
                <SkeletonLoading type={"notificationItem"} count={10} />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NotificationsPage;
