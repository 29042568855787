import React from "react";
import { Alert, Button } from "reactstrap";

const AlertCard = ({ text, handleOnClick }) => {
  return (
    <Alert color="warning" className="addCategoryAlert">
			<div className="d-flex">
				<i className="fa fa-exclamation-triangle alertIconDanger" />
				<p>
				  {`${text}`}
				</p>
			</div>
      <Button className="themeBtn addBtn" onClick={() => handleOnClick()}>
				Proceed
			</Button>
    </Alert>
  );
};

export default AlertCard;
