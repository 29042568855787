import React from "react";
import { APP_NAME, DEFAULT_PROFILE_PICTURE } from "../config";
import { Button, ListGroupItem } from "reactstrap";
import {
  errorHandler,
  formatTimeFromNow,
  getLowResolutionLink,
  reactHtmlParser,
} from "../helper-methods";
import { useHistory } from "react-router-dom";
import { readSingleNotification } from "../http-calls";
import { useDispatch, useSelector } from "react-redux";
import { markReadSingleNotification } from "../redux/actions";
import ReadMoreReadLess from "./custom/ReadMoreReadLess";

const NotificationItem = ({
  isReadAll = false,
  notification,
  lastElementRef,
  prevDateHeading,
  currDateHeading,
}) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { unreadNotificationCount } = useSelector(
    (state) => state?.notificationData || {}
  );

  const _readSingleNotification = (notification) => {
    try {
      if (!notification?._id || notification?.isRead) return;

      readSingleNotification(notification?._id);

      if (unreadNotificationCount) {
        dispatch(markReadSingleNotification(notification?._id));
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onNotificationClick = (notification) => {
    _readSingleNotification(notification);

    switch (notification?.category) {
      case "post": {
        history.push(
          `/post/${notification?._post?._id || notification?._post}`
        );
        break;
      }
      case "event": {
        history.push(
          `/live-events/${notification?._event?._id || notification?._event}`
        );
        break;
      }
      case "ppv": {
        history.push(
          `/ppv/${notification?._payperview?._id || notification?._payperview}`
        );
        break;
      }
      case "vault": {
        history.push(
          `/influencer/${notification?._from?.username}/${notification?._folder}?tab=2`
        );
        break;
      }
      default: {
        if (notification?._message?._thread) {
          history.push(`/messages?threadId=${notification?._message?._thread}`);
        }
        break;
      }
    }
  };

  const _onUserNameClick = (e, userType) => {
    try {
      if (e) e.stopPropagation();

      if (userType === "Influencer") {
        history.push(`/influencer/${notification?._from?.username}`);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const _formatNotificationDescription = (notification) => {
    try {
      if (!notification?.title) return "";

      let title = reactHtmlParser(notification?.title);

      if (notification?._from?.username) {
        if (
          notification?.title?.includes(`@${notification?._from?.username}`)
        ) {
          title = title.replace(`@${notification?._from?.username}`, "");
        } else if (
          notification?.title?.includes(`${notification?._from?.username}`)
        ) {
          title = title.replace(`${notification?._from?.username}`, "");
        }
      }

      return title?.trim();
    } catch (error) {
      return "";
    }
  };

  return (
    <>
      {currDateHeading !== prevDateHeading ? (
        <p className="notificationDay">{currDateHeading}</p>
      ) : null}

      <ListGroupItem
        className={
          isReadAll || notification?.isRead
            ? "notificationsWrap"
            : "notificationsWrap highlightRow"
        }
      >
        <div
          className="notifications"
          onClick={() => _onNotificationClick(notification)}
          ref={lastElementRef}
        >
          <img
            src={
              getLowResolutionLink(notification?._from?.profilePicture) ||
              DEFAULT_PROFILE_PICTURE
            }
            onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
            alt="Profile Img"
            className="userImg cursorPointer"
            loading="lazy"
          />
          <div>
            {/* on clicking the below anchor link, it should take it to the user's profile page  */}
            {notification?.byAdmin ? (
              <span className="d-inline-block">
                {APP_NAME}-Admin{" "}
                <Button color="link" className="profileID" style={{verticalAlign: 0}}>
                  @Admin
                </Button>
              </span>
            ) : (
              
              <Button
                color="link"
                  style={{verticalAlign: 0}}
                onClick={(e) =>
                  _onUserNameClick(e, notification?._from?.userType)
                }
                className="profileID"
              >
                {notification?._from?.username?.length
                  ? `@` + notification?._from?.username
                  : null}
              </Button>
            )}
            <span className="d-inline-block"
              style={{marginLeft: 2}}>
              <ReadMoreReadLess
                className="readMore"
                text={_formatNotificationDescription(notification)}
              />
            </span>
            <span className="commentTime" style={{ color: "#999" }}>
              {notification?.createdAt
                ? formatTimeFromNow(notification?.createdAt)
                : "N/A"}
            </span>
          </div>
        </div>
      </ListGroupItem>
    </>
  );
};

export default NotificationItem;
