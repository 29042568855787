// chat Text area Auto size style
export const autoHeightStyleChat = {
  height: "47px !important",
  minHeight: 47,
  maxHeight: 110,
  color: "#333",
  fontSize: 14,
  padding: "12px 72px 12px 42px",
  width: "100%",
  borderRadius: 24,
  backgroundColor: "#f2f2f2",
  borderColor: "#f2f2f2",
  outline: 0,
};

// also for post comment section
export const autoHeightStyleComment = {
  height: "47px !important",
  minHeight: 47,
  maxHeight: 110,
  color: "#333",
  fontSize: 14,
  padding: "12px 36px 12px 15px",
  width: "100%",
  borderRadius: 24,
  backgroundColor: "#f2f2f2",
  borderColor: "#f2f2f2",
  outline: 0,
};

// for comment edit
export const autoHeightStyleCommentEdit = {
  height: 46,
  minHeight: 46,
  maxHeight: 98,
  color: "#333",
  fontSize: 14,
  padding: "0 32px 0 0",
  width: "100%",
  borderRadius: 0,
  backgroundColor: "#f2f2f2",
  borderColor: "#f2f2f2",
  outline: 0,
};
