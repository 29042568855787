import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Container,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from "reactstrap";
import { APP_LOGO, DEFAULT_PROFILE_PICTURE } from "../config/index";
import {
  getSocialLink,
  reactHtmlParser,
  extractQueryParams,
  errorHandler,
  openSocialLink,
  capitalize,
  getLowResolutionLink,
  setupDescription,
  getWindowDimensions,
} from "../helper-methods";
import {
  getPostDetailsPublic,
  getPublicInfluencerDetails,
} from "../http-calls/index";
import { AppNavbarBrand } from "@coreui/react";
import ProfileCarousel from "../components/ProfileCarousel";
import { APP_NAME } from "../config/index";
import PublicFeedViewer from "../components/PublicFeedViewer";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ErrorBoundary from "../components/ErrorBoundary";
import ReadMoreReadLess from "../components/custom/ReadMoreReadLess";
import SkeletonLoading from "../components/SkeletonLoading";
import StorySection from "../components/StorySection";

const PublicInfluencerProfile = () => {
  const history = useHistory();

  const params = useParams();

  const userData = useSelector((state) => state?.userData);

  const observer = useRef();

  const [influencer, setInfluencer] = useState(null);
  const [sharedPost, setSharedPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [images, setImages] = useState(0);
  const [videos, setVideos] = useState(0);
  const [total, setTotal] = useState();
  const [stories, setStories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    skip: 0,
    limit: 20,
  });
  const [screenWidth, setScreenWidth] = useState(0);

  const _subscribe = () => {
    history.push(`/register?redirectTo=/influencer/${params.id}`);
  };

  const _getInfluencerDetails = async (pagination = {}) => {
    try {
      setLoading(true);
      const res = await getPublicInfluencerDetails(params?.id, pagination);

      let postDetailRes = {};

      if (params?.postId && !sharedPost) {
        postDetailRes = await getPostDetailsPublic(params?.postId);
        setSharedPost(postDetailRes?.post);
      }

      if (
        res?.hasOwnProperty("posts") &&
        Array.isArray(res?.posts) &&
        postDetailRes?.post?._id
      ) {
        const postIndex = res?.posts.findIndex(
          (each) => each?._id === postDetailRes?.post?._id
        );

        if (postIndex > -1) {
          res.posts.splice(postIndex, 1);
        }

        res?.posts?.unshift(postDetailRes?.post);
      }

      setInfluencer(res.influencer);
      setPosts(pagination?.skip ? posts.concat(res.posts) : res.posts);
      setImages(res.images);
      setVideos(res.videos);
      setTotal(res.total);
      setStories(res?.stories);
      setLoading(false);

      setupDescription(res?.influencer?.introduction);
    } catch (error) {
      setLoading(false);

      history.replace("/login");
      errorHandler(error);
    }
  };
  console.log(stories);
  const lastElementRef = useCallback(
    (node) => {
      if (loading) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && posts?.length < total) {
          const newPagination = { ...pagination };
          newPagination["skip"] = posts?.length || 0;
          setPagination(newPagination);
          _getInfluencerDetails(newPagination);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, posts]
  );

  const _changeWidth = (event) => {
    const { screenWidth } = getWindowDimensions();
    if (screenWidth <= 1000) {
      setScreenWidth(screenWidth);
    }
  };

  useEffect(() => {
    const { coupon, tab } = extractQueryParams();
    // if promotional link from creator is shared then url will have coupon in query
    if (coupon) {
      localStorage.couponToken = coupon;
    }

    // if a fan is logged in then redirect to influencer details page
    if (userData?.token) {
      if (params?.postId) {
        history.replace(
          `/influencer/${params?.id}/${params?.postId}${
            tab ? `?tab=${tab}` : ""
          }`
        );
      } else {
        history.replace(`/influencer-selector/${params?.id}`);
      }
    } else {
      _getInfluencerDetails(pagination);
    }

    _changeWidth();

    window.addEventListener("resize", _changeWidth, true);

    return () => {
      window.removeEventListener("resize", _changeWidth, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const profileCarouselData = useMemo(() => {
    if (!influencer?.carousel?.length) return [];

    const carouselCrop = influencer?.carouselCrop?.length
      ? influencer?.carouselCrop
      : [];

    const newCarousel = [];

    influencer?.carousel?.forEach((each, index) => {
      const carouselObj = {
        ...each,
        id: index,
      };

      if (each.contentType === "image") {
        const findCropObj = carouselCrop?.find(
          (each) => each.carouselIndex === index
        );

        if (findCropObj) {
          carouselObj.crop = findCropObj;
        }
      }

      newCarousel.push(carouselObj);
    });

    return newCarousel;
  }, [influencer?.carousel, influencer?.carouselCrop]);

  return (
    <>
      <React.Fragment>
        <div className="headerNonSubscriber">
          <AppNavbarBrand
            onClick={() => history.push("/login")}
            full={{
              src: APP_LOGO,
              alt: "Project Logo",
            }}
          />
        </div>
      </React.Fragment>

      <div className="customPgHeight animated fadeIn">
        {/* implement infinite scroll */}
        <Container className="noPadding">
          <Row className="justify-content-center mb-2 noMargin">
            <Col sm={12} lg={10} xl={8} className="noPadding bg-white">
              <div className="alreadyHaveAccountTxt">
                Already have an account
                <Button color="link" onClick={() => history.push("/login")}>
                  Login
                </Button>
              </div>

              {/* user profile card  */}
              <Card className="cardFeed profileCard profileCardPublic">
                <CardHeader>
                  {influencer?.name?.full?.length ? (
                    <h4 className="usernameTopCoverImg">
                      {influencer.name.first &&
                      influencer.name.first === influencer.name.last
                        ? capitalize(influencer.name.first)
                        : capitalize(influencer.name.full)}
                    </h4>
                  ) : null}

                  <ProfileCarousel
                    items={profileCarouselData}
                    screenWidth={screenWidth}
                    fullScreenMode={false}
                  />

                  <div className="influencerDetailsWrap">
                    <div>
                      <img
                        src={
                          getLowResolutionLink(influencer?.profilePicture) ||
                          DEFAULT_PROFILE_PICTURE
                        }
                        onError={(e) =>
                          (e.target.src = DEFAULT_PROFILE_PICTURE)
                        }
                        alt="Profile Img"
                        className="img100by100"
                        loading="lazy"
                      />

                      <div>
                        {influencer?.name?.full?.length ? (
                          <h4 className="mb-0" style={{ marginTop: 10 }}>
                            {influencer.name.first &&
                            influencer.name.first === influencer.name.last
                              ? capitalize(influencer.name.first)
                              : capitalize(influencer.name.full)}
                          </h4>
                        ) : (
                          <SkeletonLoading
                            type={"box"}
                            height={15}
                            width={Math.floor(Math.random() * 50 + 50)}
                            count={1}
                          />
                        )}

                        {influencer?.username?.length ? (
                          <Button
                            color="link"
                            className="profileID px-0"
                            style={{ cursor: "text" }}
                          >
                            @{influencer.username}
                          </Button>
                        ) : (
                          <SkeletonLoading
                            type={"box"}
                            height={15}
                            width={Math.floor(Math.random() * 70 + 70)}
                            count={1}
                          />
                        )}
                      </div>
                    </div>
                    <div className="profileInfoWrap">
                      <div>
                        {loading ? (
                          <SkeletonLoading
                            type={"box"}
                            height={15}
                            width={30}
                            count={1}
                          />
                        ) : (
                          <h5>{influencer?.address?.country || "N/A"}</h5>
                        )}{" "}
                        <p>Location</p>
                      </div>

                      {influencer?.settings?.showFanCountOnProfile &&
                      influencer?._subCount > 0 ? (
                        <div>
                          {loading ? (
                            <SkeletonLoading
                              type={"box"}
                              height={15}
                              width={30}
                              count={1}
                            />
                          ) : (
                            <h5>{influencer?._subCount}</h5>
                          )}{" "}
                          <p>Subscribers</p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* influencerDetailsWrap */}
                </CardHeader>

                <CardBody>
                  {influencer?.social?.length ? (
                    <div className="d-flex mt-1 mb-3">
                      {getSocialLink(influencer?.social, "facebook") && (
                        <Button
                          size="sm"
                          className="btn-facebook btn-brand mr-2"
                          title={getSocialLink(influencer?.social, "facebook")}
                          onClick={() =>
                            openSocialLink(
                              getSocialLink(influencer?.social, "facebook")
                            )
                          }
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                      )}

                      {getSocialLink(influencer?.social, "twitter") && (
                        <Button
                          size="sm"
                          className="btn-twitter btn-brand mr-2"
                          title={getSocialLink(influencer?.social, "twitter")}
                          onClick={() =>
                            openSocialLink(
                              getSocialLink(influencer?.social, "twitter")
                            )
                          }
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                      )}

                      {getSocialLink(influencer?.social, "instagram") && (
                        <Button
                          size="sm"
                          className="btn-instagram btn-brand mr-2"
                          title={getSocialLink(influencer?.social, "instagram")}
                          onClick={() =>
                            openSocialLink(
                              getSocialLink(influencer?.social, "instagram")
                            )
                          }
                        >
                          <i className="fa fa-instagram" />
                        </Button>
                      )}

                      {getSocialLink(influencer?.social, "youtube") && (
                        <Button
                          size="sm"
                          className="btn-youtube btn-brand mr-2"
                          title={getSocialLink(influencer?.social, "youtube")}
                          onClick={() =>
                            openSocialLink(
                              getSocialLink(influencer?.social, "youtube")
                            )
                          }
                        >
                          <i className="fa fa-youtube" />
                        </Button>
                      )}

                      {getSocialLink(influencer?.social, "tiktok") && (
                        <Button
                          size="sm"
                          className="btn-tiktok btn-brand"
                          title={getSocialLink(influencer?.social, "tiktok")}
                          onClick={() =>
                            openSocialLink(
                              getSocialLink(influencer?.social, "tiktok")
                            )
                          }
                        >
                          <img
                            src={"/assets/img/tiktok.png"}
                            alt="TikTok"
                            className="tiktok"
                            loading="lazy"
                          />
                        </Button>
                      )}
                    </div>
                  ) : null}

                  <div className="profileDesc">
                    {influencer?.introduction?.length ? (
                      <ReadMoreReadLess
                        className="readMore"
                        text={reactHtmlParser(influencer.introduction)}
                      />
                    ) : loading ? (
                      <SkeletonLoading
                        type={"box"}
                        height={15}
                        width={Math.floor(Math.random() * 200 + 200)}
                        count={1}
                      />
                    ) : (
                      `Welcome to ${APP_NAME} Subscribe to unlock all of my exclusive content!`
                    )}
                  </div>

                  <div className="profileSummaryWrap">
                    <div className="profileSummary">
                      {loading ? (
                        <SkeletonLoading
                          type={"box"}
                          height={15}
                          width={30}
                          count={1}
                        />
                      ) : (
                        <h5>{images}</h5>
                      )}{" "}
                      <p>Photos</p>
                    </div>

                    <div
                      className="profileSummary"
                      style={{ borderLeft: "1px solid #e5e5e5" }}
                    >
                      {loading ? (
                        <SkeletonLoading
                          type={"box"}
                          height={15}
                          width={30}
                          count={1}
                        />
                      ) : (
                        <h5>{videos}</h5>
                      )}{" "}
                      <p>Videos</p>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <StorySection stories={stories} influencerId={influencer?._id} />

              <div className="tabsWrap-InfluencerDetails nonSubscriberView">
                <h4>Post</h4>

                <Button
                  className="subscribeInfBtn"
                  // disabled={!influencer.paymentEnabled}
                  onClick={() => _subscribe()}
                >
                  Subscribe
                </Button>
              </div>
              {/* tabsWrap-InfluencerDetails */}

              {/* multiple posts/feeds */}
              {posts?.length ? (
                posts?.map((feed, index) => (
                  <Fragment key={`${feed?._id}_${index}`}>
                    <ErrorBoundary>
                      <PublicFeedViewer
                        {...(index === posts.length - 1
                          ? { lastElementRef }
                          : {})}
                        feed={feed}
                        subscribe={() => _subscribe()}
                        {...(sharedPost?._id && index === 0
                          ? { customClassname: "sharedPostHighLighted" }
                          : {})}
                      />
                    </ErrorBoundary>
                  </Fragment>
                ))
              ) : loading ? (
                <SkeletonLoading type={"post"} count={2} />
              ) : (
                <p className="noContentFound">No posts yet</p>
              )}
            </Col>

            {loading && posts.length < total ? (
              <Col sm={12} lg={10} xl={8} className="noPadding bg-white">
                <SkeletonLoading type={"post"} count={2} />
              </Col>
            ) : null}
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PublicInfluencerProfile;
