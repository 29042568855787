import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";
import ErrorBoundary from "../components/ErrorBoundary";
import ProfileSettings from "../components/ProfileSettings";
import { getAndUpdateUserData } from "../redux/actions/userData";
import { useDispatch, useSelector } from "react-redux";
import CustomLoader from "../components/custom/CustomLoader";

const HallOfFamePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user: fanDetails, isLoading } = useSelector(
    (state) => state?.userData || {}
  );

  const _getFanDetails = () => {
    dispatch(getAndUpdateUserData());
  };

  useEffect(() => {
    _getFanDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin pb-2">
          <Col sm={12} md={10} lg={8} xl={7} className="pgPadding">
            <div className="pgTitle justify-content-start">
              <Button
                className="customBackBtn"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-chevron-left" />
              </Button>

              <h2>Profile</h2>

              {isLoading ? <CustomLoader className="mx-2" /> : null}
            </div>

            <div className="bg-white p-0 p-sm-3" style={{ borderRadius: 10 }}>
              <ErrorBoundary>
                <ProfileSettings fanDetails={fanDetails} />
              </ErrorBoundary>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HallOfFamePage;
