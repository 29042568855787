import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
  Table,
} from "reactstrap";
import {
  deepClone,
  errorHandler,
  formatCurrencyValue,
  formatDate,
  formatPaymentTitle,
  formatTimeIn12hFormat,
  generateCalenderDate,
  getLowResolutionLink,
  getPaymentTypeInFormat,
  getWindowDimensions,
} from "../helper-methods";
import { useSelector } from "react-redux";
import { DEFAULT_PROFILE_PICTURE } from "../config";
import PaymentDetailModal from "../components/modals/PaymentDetailModal";
import PaymentHistoryPageFilters from "../components/PaymentHistoryPageFilters";
import { getPaymentHistories } from "../http-calls";
import CustomLoader from "../components/custom/CustomLoader";
import { saveLocalFilters } from "../redux/actions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SkeletonLoading from "../components/SkeletonLoading";

const PaymentHistoryPage = () => {
  const dispatch = useDispatch();
  const browserHistory = useHistory();

  const localFilters = useSelector((state) => state?.localFilters);

  const [filters, setFilters] = useState({
    _to: "",
    paymentType: "",
    startDate: null,
    endDate: null,
  });
  const [dataPayload, setDataPayload] = useState({
    search: "",
    skip: 0,
    limit: 20,
  });
  const [paymentDetailModal, setPaymentDetailModal] = useState({
    isOpen: false,
    data: null,
  });
  const [isOpenFilterCollapse, setIsOpenFilterCollapse] = useState(false);

  const [loadingState, setLoadingState] = useState({
    data: false,
    filter: false,
    search: false,
  });
  const [paymentHistoryList, setPaymentHistoryList] = useState([]);
  const [totalCount, setTotalCount] = useState();

  const _togglePaymentDetailModal = (isOpen = false, data = null) => {
    setPaymentDetailModal({ isOpen, data });
  };

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  //-------------------------- infinite scroll start----------------------
  const observer = useRef();
  const observerMobileView = useRef();

  const lastElementRef = useCallback(
    (node) => {
      if (loadingState.data) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          paymentHistoryList?.length < totalCount
        ) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = paymentHistoryList?.length || 0;
          setDataPayload(newDataPayload);
          _fetchPaymentHistory(newDataPayload);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState.data, paymentHistoryList]
  );

  const lastElementMobileRef = useCallback(
    (node) => {
      if (loadingState.data) {
        return;
      }

      if (observerMobileView.current) {
        observerMobileView.current.disconnect();
      }

      observerMobileView.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          paymentHistoryList?.length < totalCount
        ) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = paymentHistoryList?.length || 0;
          setDataPayload(newDataPayload);
          _fetchPaymentHistory(newDataPayload);
        }
      });

      if (node) {
        observerMobileView.current.observe(node);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState.data, paymentHistoryList]
  );
  //---------------------- infinite scroll end----------------------------------

  const _getRelatedContentLink = (history) => {
    let route = "";

    if (history.hasOwnProperty("_post")) {
      if (history.paymentType === "vault") {
        route = `/vault/folder/${history?._folder}`;
      } else {
        route = `/post/${history?._post}`;
      }
    } else if (history.hasOwnProperty("_event")) {
      route = `/live-events/${history?._event}`;
    } else if (history.hasOwnProperty("_payperview")) {
      route = `/ppv/${history?._payperview}`;
    } else if (history.paymentType === "subscription") {
      route = `/influencer/${history?._to?.username}`;
    }

    return route ? (
      <Button
        color="link"
        className="px-0 themeColor"
        onClick={() => browserHistory.push(route)}
      >
        Link
      </Button>
    ) : (
      "N/A"
    );
  };

  /**
   * updates redux store with updated payment history list
   * @param {filters options} payload
   */
  const _fetchPaymentHistory = async (payload) => {
    try {
      _manageLoadingState("data", true);

      const res = await getPaymentHistories(payload);

      setPaymentHistoryList((prev) =>
        payload?.skip ? prev.concat(res.payments) : res.payments
      );
      setTotalCount(res?.transCount);

      setLoadingState({});
    } catch (error) {
      setLoadingState({});
      errorHandler(error);
    }
  };

  const _onFilterChange = (newFilters = {}, dataPayload = {}) => {
    _manageLoadingState("filter", true);

    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = 0;
    newDataPayload["filters"] = {};

    dispatch(
      saveLocalFilters({ key: "paymentHistory", value: deepClone(newFilters) })
    );

    Object.keys(newFilters).forEach((eachFilterKey) => {
      if (
        (eachFilterKey === "startDate" || eachFilterKey === "endDate") &&
        eachFilterKey?._d
      ) {
        newDataPayload.filters["startDate"] = newFilters?.startDate;
        newDataPayload.filters["endDate"] = newFilters?.endDate;
      } else if (newFilters[eachFilterKey]?.length) {
        newDataPayload.filters[eachFilterKey] = newFilters[eachFilterKey];
      }
    });

    setDataPayload(newDataPayload);

    _fetchPaymentHistory(newDataPayload);
  };

  useEffect(() => {
    if (localFilters?.filters?.paymentHistory) {
      const newFilters = { ...filters };
      Object.keys(newFilters).forEach((key) => {
        if (localFilters.filters.paymentHistory?.[key]) {
          newFilters[key] = localFilters.filters.paymentHistory[key];
        }
      });
      setFilters(newFilters);
      _onFilterChange(newFilters, dataPayload);
    } else {
      _fetchPaymentHistory(dataPayload);
    }

    const { screenWidth } = getWindowDimensions();

    if (screenWidth >= 768) {
      setIsOpenFilterCollapse(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin mb-3">
          <Col xs="12" className="pgPadding">
            <div className="pgTitle">
              <div className="d-flex align-items-center">
                <h2>Payment History</h2>

                {loadingState.filter ? <CustomLoader className="mx-2" /> : null}
              </div>

              <Button
                onClick={() => setIsOpenFilterCollapse((prev) => !prev)}
                className="customPopoverBtn showFilter"
              >
                <img
                  src="/assets/img/filter-icon.png"
                  alt="Filter Icon"
                  height="24"
                  loading="lazy"
                />
              </Button>
            </div>

            {/* Filter Section for Mobile and Desktop View*/}
            <PaymentHistoryPageFilters
              isOpenFilterCollapse={isOpenFilterCollapse}
              filters={filters}
              setFilters={setFilters}
              dataPayload={dataPayload}
              setDataPayload={setDataPayload}
              onFilterChange={_onFilterChange}
              fetchPaymentHistory={_fetchPaymentHistory}
              loadingState={loadingState}
              manageLoadingState={_manageLoadingState}
            />

            <div className="customTableWrap">
              <Table responsive className="customTable">
                <thead>
                  <tr>
                    <th>Transaction Type</th>
                    <th>Transaction ID</th>
                    <th>Payment Method</th>
                    <th>Fee</th>
                    <th>Date</th>
                    <th>Creator</th>
                    <th>Related Content</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentHistoryList.length ? (
                    paymentHistoryList.map((history, index) => (
                      <tr
                        key={index}
                        ref={
                          index === paymentHistoryList?.length - 1
                            ? lastElementRef
                            : null
                        }
                      >
                        <td>
                          {getPaymentTypeInFormat(
                            history.paymentType,
                            true,
                            history
                          )}
                        </td>

                        <td>{history.transactionId || "N/A"}</td>

                        <td>
                          {history?.method === "card" &&
                          history?.cardBrand?.length &&
                          history?.cardLastFourDigit?.length ? (
                            <span>
                              {history?.cardBrand} {history?.cardLastFourDigit}
                            </span>
                          ) : history.method === "stripe" ? (
                            "Apple Pay"
                          ) : (
                            "N/A"
                          )}
                        </td>

                        <td>{formatCurrencyValue(history?.amount)}</td>

                        <td>
                          {formatDate(history?.time)} <br />
                          {formatTimeIn12hFormat(history?.time)}
                        </td>

                        <td>
                          {history?._to?.username ? (
                            <Button
                              color="link"
                              onClick={() => {
                                browserHistory.push(
                                  `/influencer/${history?._to?.username}`
                                );
                              }}
                              className="profileID px-0 themeColor"
                            >
                              @{history?._to?.username}
                            </Button>
                          ) : (
                            "-"
                          )}
                        </td>

                        <td>{_getRelatedContentLink(history) || "N/A"}</td>
                      </tr>
                    ))
                  ) : loadingState.data ? (
                    <SkeletonLoading type={"table"} rows={1} col={7} />
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className="text-center">
                          {dataPayload?.search?.trim()
                            ? "No result found"
                            : "No histories yet"}
                        </div>
                      </td>
                    </tr>
                  )}

                  {loadingState.data &&
                  paymentHistoryList?.length < totalCount ? (
                    <SkeletonLoading type={"table"} rows={1} col={7} />
                  ) : null}
                </tbody>
              </Table>
            </div>

            {/* for mobile list view - start */}
            <div className="paymentHistoryWrap">
              <ListGroup className="paymentHistoryList">
                {paymentHistoryList?.length ? (
                  paymentHistoryList.map((history, index) => (
                    <Fragment key={`${history?._id}_${index}`}>
                      {index === 0 ||
                      generateCalenderDate(
                        paymentHistoryList?.[index - 1]?.createdAt
                      ) !== generateCalenderDate(history?.createdAt) ? (
                        <p className="paymentDay">
                          {generateCalenderDate(history?.createdAt)}
                        </p>
                      ) : null}

                      <ListGroupItem
                        onClick={() => _togglePaymentDetailModal(true, history)}
                      >
                        <div
                          className="d-flex"
                          ref={
                            index === paymentHistoryList?.length - 1
                              ? lastElementMobileRef
                              : null
                          }
                        >
                          <img
                            src={
                              getLowResolutionLink(
                                history?._to?.profilePicture
                              ) || DEFAULT_PROFILE_PICTURE
                            }
                            onError={(e) =>
                              (e.target.src = DEFAULT_PROFILE_PICTURE)
                            }
                            alt="Profile"
                            loading="lazy"
                          />

                          <div className="ml-2">
                            <p>
                              <span>{formatPaymentTitle(history)}</span>{" "}
                              {history?._to?.username ? (
                                <Button
                                  color="link"
                                  onClick={() => {
                                    browserHistory.push(
                                      `/influencer/${history?._to?.username}`
                                    );
                                  }}
                                  className="profileID"
                                >
                                  @{history?._to?.username}
                                </Button>
                              ) : (
                                "-"
                              )}
                            </p>
                            <div className="paymentDate">
                              {formatDate(history.time)}.&nbsp;
                              {formatTimeIn12hFormat(history.time)}
                            </div>
                          </div>
                        </div>
                        <div className="amtPaid">
                          {formatCurrencyValue(history.amount)}
                        </div>
                      </ListGroupItem>
                    </Fragment>
                  ))
                ) : loadingState.data ? (
                  <SkeletonLoading type={"paymentHistory"} count={4} />
                ) : (
                  <ListGroupItem className="noContentFound">
                    {dataPayload?.search?.trim()
                      ? "No result found"
                      : "No histories yet"}
                  </ListGroupItem>
                )}
              </ListGroup>

              {loadingState.data && paymentHistoryList?.length < totalCount ? (
                <SkeletonLoading type={"paymentHistory"} count={2} />
              ) : null}
            </div>
            {/* for mobile list view - end */}
          </Col>
        </Row>
      </Container>

      <PaymentDetailModal
        isOpen={paymentDetailModal.isOpen}
        historyData={paymentDetailModal.data}
        toggle={() => _togglePaymentDetailModal()}
        getRelatedContentLink={(history) => _getRelatedContentLink(history)}
      />
    </div>
  );
};

export default PaymentHistoryPage;
