import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactInstaStories from "react-insta-stories";
import Modal from "react-modal";
import {
  Button,
  // Dropdown,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
} from "reactstrap";
import StoryMessageArea from "../StoryMessageArea";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const STORY_TIMEOUT = 15000; // Default timeout for images

const customStyles = {
  content: {
    inset: "50% 0 0 50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: 450,
    height: "100dvh",
    overflow: "hidden",
  },
};

const ViewStoryModal = ({ isOpen, data = [], toggle, isSubscribed, subscribedData }) => {
  // console.log("data",data)
  // const [isPaused, setIsPaused] = useState(false);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    // setIsPaused(false);
    setCurrentStoryIndex(0);

  }, [isOpen,]);

  const _closeModal = () => {
    toggle();
  };

  const profile = {
    id: data[0]?._createdBy?._id,
    profilePicture: data[0]?._createdBy?.profilePicture,
    username: data[0]?._createdBy?.username,
  };

  function getUrl(item) {
   
    if (
      item.storyFor !== "all" && item.bluredImage  &&
      (item.type === "video" || item.type === "image")
    ) {
      return item?.bluredImage;
    } else {
      return item?.previewImageUrl || item?.storyVideoUrl;
    }
  }

  const stories = data.map((item, ind) => {
    return {
      id: item?._id,
      type: item.storyFor !== "all" && item.bluredImage ? "image" : item?.type,
      // url: (item.storyFor !== "all")&& (item.type==="video" || item.type==="image") ? (item?.bluredImage || item?.url) : (item?.previewImageUrl || item?.storyVideoUrl) ,
      url: getUrl(item),
      duration: item.type === "video" ? item.duration : STORY_TIMEOUT,
      createdAt: moment(item?.createdAt).calendar(),
      text: item?.text || "",
      StoryDatas: data[ind],
    };
  });

  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

      console.log("stories>>>>>",stories);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={_closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="storyContainer">
        <ReactInstaStories
          stories={stories}
          width="100%"
          height="100dvh"
          loop
          // isPaused={isPaused}
          preload="auto"
          preloadResource={
            stories[currentStoryIndex].type === "image" ? true : false
          }
          // onStoryStart={()=>console.log("stories", stories[currentStoryIndex])}
          preloadCount={stories.length}
          onAllStoriesEnd={() => _closeModal()}
          onStoryStart={(index) => setCurrentStoryIndex(index)}
          keyboardNavigation={true}
          storyContainerStyles={{
            background: "rgba(0, 0, 0, 0.9)",
          }}
          progressContainerStyles={{
            padding: "11px 0 0 0",
          }}
          progressWrapperStyles={{ background: "#666" }}
          progressStyles={{ background: "#bbb" }}
          storyStyles={{ width: "100vw" }}
        />

        <div className="storyProfile">
          <img
            src={profile.profilePicture || DEFAULT_PROFILE_PICTURE}
            alt={profile.username}
            className="storyProfileUser"
          />
          <div className="flex-grow-1 mr-1">
            <h5
              onClick={() => history.push(`/influencer/${profile.username}`)}
              style={{ cursor: 'pointer' }}
            >
              {profile.username}
            </h5>
            <p>{stories[currentStoryIndex]?.createdAt || ""}</p>
          </div>

          {/* <Dropdown
            nav
            isOpen={dropdownOpen}
            toggle={toggleDropdown}
            className="customDropdown commentOptions"
          >
            <DropdownToggle nav>
              <i className="fa fa-ellipsis-h" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem className="textWarning">Report</DropdownItem>
              <DropdownItem>Turn on story notifications</DropdownItem>
              <DropdownItem>Mute</DropdownItem>
              <DropdownItem>Unfollow</DropdownItem>
            </DropdownMenu>
          </Dropdown> */}
          <Button onClick={() => _closeModal()} className="closeModalFeed">
            <i className="fa fa-close modalFeed_closeIcon" />
          </Button>
        </div>

        <div className="storyFooterArea">
          {stories[currentStoryIndex]?.text ? (
            <h5>{stories[currentStoryIndex].text}</h5>
          ) : null}

          <StoryMessageArea
            creator={profile}
            currentStoryIndex={currentStoryIndex}
            id={stories[currentStoryIndex]?.id}
            data={stories[currentStoryIndex].StoryDatas}
            isSubscribed={isSubscribed}
            subscribedData={subscribedData}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ViewStoryModal;
