import React from "react";

const StoryPreviewComponent = ({
  story,
  influencerId,
  toggleViewStoryModal = () => {},
  isLiveEventStory,
  handleLiveEventRedirect = () => {},
  isSubscribed
}) => {
  // console.log("story",story)
  //for Influencer details page displays content preview and text/caption
  if (influencerId) {
    return (
      <div onClick={() => toggleViewStoryModal([story] || [])}>
        <div className="storyImgWrap">
          <img
            alt="Story Img"
            src={
              story?.previewImageUrl ||
              story?.thumbnail ||
              "/assets/img/default_user_icon.svg"
            }
          />
        </div>
        <p>{story?.text || ""}</p>
      </div>
    );
  }

  // console.log("story", story);
   let isAllStoriesViewed = story?.stories?.every((data) => data?.isViewed);
  

  // console.log(isAllStoriesViewed);
  // For Feed Page displays profile picture and username
  return (
    <div
      onClick={() =>
        isLiveEventStory
          ? handleLiveEventRedirect()
          : toggleViewStoryModal(story?.stories || [])
      }
    >
    {
      !isAllStoriesViewed?
      <div className="storyImgWrap">
        <img
          alt="Story Img"
          src={
            story?._createdBy?.profilePicture ||
            "/assets/img/default_user_icon.svg"
          }
        />
      </div>:
      <div className="storyImgWrap" style={{border:"2px solid oklch(0.928 0.006 264.531)"}}>
        <img
          alt="Story Img"
          src={
            story?._createdBy?.profilePicture ||
            "/assets/img/default_user_icon.svg"
          }
        />
      </div>
    }
      
      <p>{story?._createdBy?.username || "username"}</p>
    </div>
  );
};

export default StoryPreviewComponent;
