import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  ListGroupItem,
  Col,
  Container,
  Input,
  Row,
  ListGroup,
} from "reactstrap";
import { APP_LOGO, DEFAULT_PROFILE_PICTURE } from "../config";
import WelcomeFanModal from "../components/modals/WelcomeFanModal";
import {
  capitalize,
  errorHandler,
  formatNumberInShort,
  getLowResolutionLink,
  getWindowDimensions,
} from "../helper-methods";
import { CURRENCY_SYMBOL } from "../config";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { searchInluencersAsync } from "../redux/actions/searchInfluencers";
import SkeletonLoading from "../components/SkeletonLoading";
import { getSettings } from "../http-calls";
import SvgIcons from "../components/SvgIcons";

const SearchInfluencers = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    isLoading,
    error,
    hasMore,
    searchResult: influencers,
  } = useSelector((state) => state?.searchInfluencer || {});

  const [screenWidth, setScreenWidth] = useState(0);
  const [categories, setCategories] = useState([]);
  const [isLoadingCategories, setIsLoadingCategories] = useState(false);

  const [payload, setPayload] = useState({
    search: "",
    skip: 0,
    limit: 20,
    categories: [],
  });

  const debouncingTimer = useRef();

  const _getCarouselMediaForCover = useCallback((influencer) => {
    const newCarousel = {};

    if (!influencer?.carouselMediaForCover?.url) {
      return newCarousel;
    }

    newCarousel.url = influencer?.carouselMediaForCover?.url;

    const carouselCrop = influencer?.carouselCrop;

    if (carouselCrop?.length) {
      const findCropObj = carouselCrop?.find(
        (each) => each.carouselIndex === 0 || each.carouselIndex === 1
      );

      if (findCropObj) {
        newCarousel.crop = findCropObj;
      }
    }

    return newCarousel;
  }, []);

  const _searchInfluencers = (payload) => {
    dispatch(searchInluencersAsync(payload));
  };

  const observer = useRef();

  const _lastElementRef = useCallback(
    (node) => {
      if (isLoading) {
        return;
      }

      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          const newPayload = { ...payload };
          newPayload.skip = influencers.length;
          setPayload(newPayload);
          _searchInfluencers(newPayload);
        }
      });

      if (node) {
        observer.current.observe(node);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, hasMore]
  );

  const _clearSearchText = () => {
    const newPayload = { ...payload };
    newPayload.skip = 0;
    newPayload.limit = 20;
    newPayload.search = "";
    setPayload(newPayload);
    _searchInfluencers(newPayload);
  };

  const _getSettings = async () => {
    try {
      setIsLoadingCategories(true);
      const res = await getSettings();

      setCategories(
        res.setting?.categories?.length ? res.setting.categories : []
      );
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoadingCategories(false);
    }
  };

  const _handleSearch = (newPayload) => {
    if (debouncingTimer.current) {
      clearTimeout(debouncingTimer.current);
    }

    debouncingTimer.current = setTimeout(() => {
      _searchInfluencers(newPayload);
    }, 750);
  };

  const _handleOnChange = (key, value) => {
    const newPayload = { ...payload };
    newPayload.skip = 0;

    switch (key) {
      case "search": {
        newPayload[key] = value;
        break;
      }

      case "categories": {
        const categoryIndex = newPayload?.categories?.findIndex(
          (each) => each === value
        );

        if (categoryIndex > -1) {
          newPayload?.categories?.splice(categoryIndex, 1);
        } else {
          newPayload.categories.push(value);
        }

        break;
      }

      case "clearCategories": {
        newPayload.categories = [];
        break;
      }

      default: {
        newPayload["search"] = "";
        newPayload["categories"] = [];
        newPayload["skip"] = 0;
        break;
      }
    }

    setPayload(newPayload);

    const finalPayload = { ...newPayload };
    finalPayload["search"] = newPayload.search.trim();

    _handleSearch(finalPayload);
  };

  const _redirectToInfluencerProfilePage = (influencer) => {
    history.push(`/influencer/${influencer.username}`);
  };

  const _changeWidth = () => {
    const { screenWidth } = getWindowDimensions();
    if (screenWidth <= 1000) {
      setScreenWidth(screenWidth);
    }
  };

  useEffect(() => {
    _searchInfluencers(payload);
    _getSettings();

    _changeWidth();

    window.addEventListener("resize", _changeWidth, true);

    return () => {
      window.removeEventListener("resize", _changeWidth, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      errorHandler(error);
    }
  }, [error]);

  return (
    <div className="customPgHeight animated fadeIn">
      <Container className="noPadding">
        <Row className="justify-content-center noMargin">
          <Col
            xs="12"
            md="10"
            lg="8"
            xl="7"
            className="noPadding bg-white pb-2"
          >
            <div className="pgTitle customMarginMob justify-content-start">
              <Button
                className="customBackBtn"
                onClick={() => history.goBack()}
              >
                <i className="fa fa-chevron-left" />
              </Button>
              <h2>Search</h2>
            </div>

            <div className="searchChatListWrap searchCreators">
              {/* <i className="fa fa-search searchIcon" /> */}
              <Input
                type="text"
                value={payload.search}
                placeholder="Try Creator's Name/Profession/URL or Keywords"
                onChange={(e) => _handleOnChange("search", e.target.value)}
                style={{ paddingLeft: 10 }}
              />
              {payload?.search?.length ? (
                isLoading ? (
                  <div className="spinnerLogin">
                    <i className="fa fa-spinner fa-spin mr-1" />
                  </div>
                ) : (
                  <i
                    className="fa fa-times clearSearch cursorPointer"
                    onClick={() => _clearSearchText()}
                  />
                )
              ) : null}
            </div>

            {/* creator category */}
            <div className="creatorCategory">
              <Button
                active={payload.categories.includes("Male")}
                onClick={() => _handleOnChange("categories", "Male")}
              >
                <SvgIcons type="Male" />
                Male
              </Button>
              <Button
                active={payload.categories.includes("Female")}
                onClick={() => _handleOnChange("categories", "Female")}
              >
                <SvgIcons type="Female" />
                Female
              </Button>
              {categories?.length ? (
                categories?.map((each) => (
                  <Button
                    active={payload.categories.includes(each)}
                    onClick={() => _handleOnChange("categories", each)}
                  >
                    <SvgIcons type={each} className="gamingIcon" />
                    {each}
                  </Button>
                ))
              ) : isLoadingCategories ? (
                <div className="d-flex align-item-start">
                  <SkeletonLoading
                    type={"box"}
                    height={33}
                    width={80}
                    count={5}
                    style={{ marginRight: 5 }}
                  />
                </div>
              ) : null}
              {payload?.categories?.length ? (
                <Button
                  onClick={() => _handleOnChange("clearCategories")}
                  className="clearCategory"
                >
                  Clear
                  <i className="fa fa-times ml-1" />
                </Button>
              ) : null}
            </div>

            {influencers?.length ? (
              <>
                <ListGroup>
                  {influencers.map((influencer, influencerIndex) => (
                    <Fragment key={`${influencer?._id}_${influencerIndex}`}>
                      {influencerIndex === 0 ? (
                        <p className="searchResultsTitle">Suggested</p>
                      ) : null}
                      {influencerIndex === 2 ? (
                        <p className="searchResultsTitle">Top Creators</p>
                      ) : null}
                      <div
                        {...(screenWidth <= 575.98
                          ? {
                              style: {
                                width: screenWidth,
                                height: screenWidth / 3,
                              },
                            }
                          : {})}
                        className="searchResultsNewWrap"
                      >
                        <div
                          className="searchResultsNew"
                          ref={
                            influencerIndex === influencers.length - 1
                              ? _lastElementRef
                              : null
                          }
                        >
                          {_getCarouselMediaForCover(influencer)?.url ? (
                            <div className="imgCropWrap">
                              <img
                                src={_getCarouselMediaForCover(influencer)?.url}
                                alt="cover"
                                onError={(e) => (e.target.src = APP_LOGO)}
                                className={
                                  "infCoverImg " +
                                    _getCarouselMediaForCover(influencer)?.crop
                                      ?.height ===
                                  100
                                    ? "widthAuto"
                                    : "heightAuto"
                                }
                                style={{
                                  transform: `translate(-${
                                    (_getCarouselMediaForCover(influencer)?.crop
                                      ?.x ||
                                    _getCarouselMediaForCover(influencer)?.crop
                                      ?.x === 0
                                      ? _getCarouselMediaForCover(influencer)
                                          ?.crop?.x
                                      : 0) + "%"
                                  }, -${
                                    (_getCarouselMediaForCover(influencer)?.crop
                                      ?.y ||
                                    _getCarouselMediaForCover(influencer)?.crop
                                      ?.y === 0
                                      ? _getCarouselMediaForCover(influencer)
                                          ?.crop?.y
                                      : 0) + "%"
                                  })`,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="defaultCoverImg">
                              <img src={APP_LOGO} alt="cover" />
                            </div>
                          )}

                          <div className="searchResultsContentNew">
                            <div className="d-flex justify-content-between">
                              <p>
                                {/* Check if the influencer is not subscribed and has free subscriptions allowed */}
                                {!influencer?.isSubscribed &&
                                influencer?.isFreeSubscription &&
                                influencer?.isFreeSubscriptionsAllow
                                  ? "Free" // Display "Free" if conditions are met
                                  : influencer?.subscriptionFees?.amount // Otherwise, check if there's a subscription fee amount
                                  ? `${CURRENCY_SYMBOL}${influencer.subscriptionFees.amount}` // Display the subscription fee amount with currency symbol
                                  : ""}{" "}
                                {/* Display nothing if none of the conditions are met */}
                              </p>
                              <Button
                                color="link"
                                onClick={() =>
                                  _redirectToInfluencerProfilePage(influencer)
                                }
                              >
                                {influencer.isSubscribed &&
                                !influencer?.hasToReSubscribe
                                  ? influencer.isExpired
                                    ? "Renew Subscription"
                                    : "View Profile"
                                  : "Subscribe"}
                              </Button>
                            </div>

                            <div className="d-flex align-items-center">
                              <img
                                src={
                                  getLowResolutionLink(
                                    influencer?.profilePicture
                                  ) || DEFAULT_PROFILE_PICTURE
                                }
                                onError={(e) =>
                                  (e.target.src = DEFAULT_PROFILE_PICTURE)
                                }
                                alt="Profile"
                              />

                              <div className="ml-2 ml-sm-3">
                                <h4>{capitalize(influencer?.name?.first)}</h4>
                                <Button
                                  color="link"
                                  onClick={() =>
                                    _redirectToInfluencerProfilePage(influencer)
                                  }
                                >
                                  @{influencer.username}
                                </Button>
                              </div>
                            </div>

                            <ListGroup horizontal>
                              {influencer?.address?.country && (
                                <ListGroupItem>
                                  {influencer?.address?.country}
                                </ListGroupItem>
                              )}
                              <ListGroupItem>
                                {formatNumberInShort(
                                  influencer?.count?.photos || 0
                                )}{" "}
                                {influencer?.count?.photos > 1
                                  ? "Photos"
                                  : "Photo"}
                              </ListGroupItem>
                              {!influencer?.address?.country && (
                                <ListGroupItem>
                                  {formatNumberInShort(
                                    influencer?.count?.videos || 0
                                  )}{" "}
                                  {influencer?.count?.videos > 1
                                    ? "Videos"
                                    : "Video"}
                                </ListGroupItem>
                              )}
                              <ListGroupItem>
                                {formatNumberInShort(
                                  influencer?.count?.likes || 0
                                )}{" "}
                                {influencer?.count?.likes > 1
                                  ? "Likes"
                                  : "Like"}
                              </ListGroupItem>
                              <ListGroupItem>
                                {formatNumberInShort(
                                  influencer?.count?.subscriptions || 0
                                )}{" "}
                                Fanz
                              </ListGroupItem>
                            </ListGroup>
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  ))}
                </ListGroup>
              </>
            ) : isLoading ? (
              <SkeletonLoading type="searchInfluencers" />
            ) : payload?.search?.length || payload?.categories?.length ? (
              <h4 className="noContentFound mb-3">No creator found!!</h4>
            ) : (
              <h4 className="noContentFound mb-3">
                Search for creators by keyword, name or category
              </h4>
            )}

            {isLoading && hasMore ? (
              <SkeletonLoading type="searchInfluencers" loadMore={true} />
            ) : null}
          </Col>
        </Row>
      </Container>

      {/* welcome fan modal */}
      <WelcomeFanModal />
    </div>
  );
};

export default SearchInfluencers;
