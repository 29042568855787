import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Label } from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../../config";
import {
  addLocalMessage,
  getAndUpdateChatThreads,
  getAndUpdateMessages,
  hideLoader,
  showLoader,
  unreadMessageCountUpdate,
  updateMessageKey,
  updateOnlyUserData,
  updateSelectedThread,
} from "../../redux/actions";
import MediaLibraryModal from "../modals/MediaLibraryModal";
import TipSetterModal from "../modals/TipSetterModal";
import ViewChatMediaModal from "../modals/ViewChatMediaModal";
import TextareaAutosize from "react-textarea-autosize";
import { autoHeightStyleChat } from "../../assets/styles/js";
import ChatShoutoutModal from "../modals/ChatShoutoutModal";
import EmojiPickerReact, { SKIN_TONE_NEUTRAL } from "emoji-picker-react";
import {
  containsRestrictedWords,
  errorHandler,
  formatCurrencyValue,
  generateCalenderDateForChat,
  getFeatureStatus,
  getLowResolutionLink,
  removeLastWord,
  showToast,
  sleepTime,
} from "../../helper-methods";
import {
  createPaymentIntent,
  createShoutOutRequest,
  markThreadAsRead,
} from "../../http-calls";
import PaymentHoc from "../PaymentHoc";
import cuid from "cuid";
import ProcessPayment from "../../payment";
import { newSocket } from "../../socket-io";
import ChatVideoRoomComponent from "./ChatVideoRoomComponent";
import moment from "moment";
import MessageItem from "./MessageItem";
import SkeletonLoading from "../../components/SkeletonLoading";
import ErrorBoundary from "../ErrorBoundary";
import SvgIcons from "../SvgIcons";
import ImageQualitySetting from "../ImageQualitySetting";
import TextDetectModal from "../modals/TextDetectModal";

const ConversationCard = ({ screenWidth }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const observer = useRef();
  const chatWindowRef = useRef();
  const prevScrollHeight = useRef();

  const userData = useSelector((state) => state?.userData);

  const { selectedThread, messages, messagesCount, threads, threadsCount } =
    useSelector((state) => state?.chatData || {});

  const [messagesPayload, setMessagesPayload] = useState({
    skip: 0,
    limit: 100,
  });

  const [loadingState, setLoadingState] = useState({
    messages: false,
  });

  const [isShowEmoji, setIsShowEmoji] = useState(false);
  const [sendMessage, setSendMessage] = useState({
    text: "",
    images: {},
    videos: {},
    audios: {},
    tip: 0,
  });
  const [paymentHoc, setPaymentHoc] = useState({
    isOpen: false,
    apiData: {
      url: "/payment/shoutout",
      url3DSecure: "/payment/intent/shoutout",
      payload: {
        shoutOutId: null,
      },
    },
    selectedPrice: null,
    payload: null,
  });

  const [mediaLibraryModal, setMediaLibraryModal] = useState({
    isOpen: false,
  });
  const [viewChatMediaModal, setViewChatMediaModal] = useState({
    isOpen: false,
  });
  const [tipSetterModal, setTipSetterModal] = useState({
    isOpen: false,
  });
  const [textDetectModal, setTextDetectModal] = useState({
    isOpen: false,
  });
  const [chatShoutoutModal, setChatShoutoutModal] = useState({
    isOpen: false,
    shoutoutType: "",
  });
  const [chatVideoRoomComponent, setChatVideoRoomComponent] = useState({
    startCall: false,
    message: null,
  });
  const [imageQuality, setImageQuality] = useState("oneZeroEightZero");

  const isShoutoutActive = useMemo(() => {
    return getFeatureStatus("shoutout");
  }, []);

  const mediaPreviews = useMemo(() => {
    const { images, videos, audios, tip } = sendMessage;
    const preview = [];
    // Iterate through images
    for (let imageId in images) {
      const image = images[imageId];
      preview.push({
        ...image,
        fileId: imageId,
        fileType: "image",
        category: "images",
      });
    }
    // Iterate through videos
    for (let videoId in videos) {
      const video = videos[videoId];
      preview.push({
        ...video,
        fileId: videoId,
        fileType: "video",
        category: "videos",
      });
    }
    // Iterate through audio
    for (let audioId in audios) {
      const audio = audios[audioId];
      preview.push({
        ...audio,
        fileId: audioId,
        fileType: "audio",
        category: "audios",
      });
    }
    if (tip > 0) {
      preview.push({
        tip,
        fileType: "priceTag",
      });
    }
    return preview;
  }, [sendMessage]);

  const fileCount = useMemo(
    () => (sendMessage.tip ? mediaPreviews?.length - 1 : mediaPreviews?.length),
    [sendMessage, mediaPreviews]
  );

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _scrollToBottomInChatWindow = (isHalf = false) => {
    if (!chatWindowRef?.current?.scrollHeight) return;

    if (isHalf) {
      setTimeout(() => {
        chatWindowRef.current.scrollTop =
          chatWindowRef.current.scrollHeight - prevScrollHeight.current;

        prevScrollHeight.current = chatWindowRef.current.scrollHeight;
      }, 0);
      return;
    }

    setTimeout(() => {
      if (chatWindowRef.current?.scrollHeight) {
        prevScrollHeight.current = chatWindowRef.current.scrollHeight;
        chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
      }
    }, 0);
  };

  const _closeMessagesCard = () => {
    dispatch(updateSelectedThread(null));
  };

  const _toggleMediaLibraryModal = (isOpen = false) => {
    setMediaLibraryModal({ isOpen });
  };

  const _toggleTextDetectModal = (isOpen = false) => {
    setTextDetectModal({ isOpen });
  };

  const _toggleViewChatMediaModal = (isOpen = false) => {
    setViewChatMediaModal({ isOpen });
  };

  const _toggleTipSetterModal = (isOpen = false) => {
    setTipSetterModal({ isOpen });
  };

  const _toggleChatShoutoutModal = (isOpen = false, shoutoutType = "") => {
    setChatShoutoutModal({ isOpen, shoutoutType });
  };

  const _removeMedia = (mediaFile) => {
    const newSendMessage = { ...sendMessage };
    if (mediaFile?.fileType === "priceTag") {
      newSendMessage.tip = 0;
    } else {
      delete newSendMessage[mediaFile.category][mediaFile.fileId];
    }
    setSendMessage(newSendMessage);
  };

  const _renderMediaPreview = useCallback((mediaFile) => {
    switch (mediaFile?.fileType) {
      case "image": {
        return (
          <>
            <img
              src={mediaFile?.previewBlob || mediaFile?.url}
              alt="Uploaded Img"
              loading="lazy"
            />
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </>
        );
      }
      case "video": {
        return (
          <>
            <video controlsList="nodownload" disablePictureInPicture>
              <source src={mediaFile?.previewBlob || mediaFile?.url} />
              Your browser does not support the video tag.
            </video>
            {/* delete btn */}
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </>
        );
      }
      case "audio": {
        return (
          <>
            <img
              src="/assets/img/microphone.png"
              alt="Microphone"
              style={{ height: 28, width: "unset" }}
              loading="lazy"
            />
            {/* delete btn */}
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </>
        );
      }
      case "priceTag": {
        return (
          <div>
            {mediaFile?.tip ? formatCurrencyValue(mediaFile.tip) : "-"}
            {/* delete btn */}
            <Button
              className="deletePreview"
              onClick={() => _removeMedia(mediaFile)}
            >
              <i className="fa fa-times" />
            </Button>
          </div>
        );
      }
      default: {
        return <div />;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _getLastInfluencerMessage = () => {
    if (messages[selectedThread?._id]?.length) {
      for (
        let messageIndex = messages[selectedThread?._id].length - 1;
        messageIndex >= 0;
        messageIndex--
      ) {
        const message = messages[selectedThread?._id][messageIndex];
        if (!message.isMyMessage) {
          // Influencer's message
          return message;
        }
      }
    }
    return null;
  };

  const _sendMessageError = (message) => {
    try {
      dispatch(updateMessageKey({ message, key: "isError", value: true }));
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _markMessageAsSent = (message) => {
    try {
      dispatch(updateMessageKey({ message, key: "isUploading", value: false }));
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _saveShoutoutStartCallTime = (message) => {
    try {
      if (!message) return;

      dispatch(
        updateMessageKey({ message, key: "status", value: message.status })
      );
      dispatch(
        updateMessageKey({
          message,
          key: "startCallTime",
          value: message.startCallTime,
        })
      );
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _publishMessageOnChannel = (message) => {
    try {
      newSocket.emit("newmessage", message, (res) => {
        console.log("send>>", res);

        //TODO: get message with url in response
        //update in redux either by using getAndUpdateMessage or upate the response locally

        if (res.doSkip) return;

        if (res.error) {
          errorHandler(res.error);
          _sendMessageError(message);
        }
      });

      if (!newSocket.connected) {
        throw new Error();
      }
    } catch (error) {
      _sendMessageError(message);
      errorHandler(error);
    }
  };

  const _appendAndPublishNewMessage = (message) => {
    if (message.hasMedia) {
      const contentsObj = Object.keys(message.media).map((e) => {
        return {
          _id: message.media[e]._id,
          contentType: message.media[e].contentType,
          url: message.media[e].url,
          hlsLink: message.media[e].hls,
          thumbnail: message.media[e].thumbnail,
        };
      });

      message["content"] = contentsObj.map((e) => {
        return {
          _contentId: {
            _id: e._id,
            url: e.url,
            hlsLink: e.hls,
            thumbnail: e.thumbnail,
            contentType: e.contentType,
            aspectRatio: e.aspectRatio,
          },
          _id: e._id,
          mediaType: e.contentType,
          // thumbnail: e.thumbnail,
          isPublic: message.isPublic,
          amount: message.amount,
        };
      });
    }

    const findThread = threads.find((each) => each._id === message._thread);

    if (findThread) {
      dispatch(
        addLocalMessage({
          threadId: message._thread,
          message: {
            ...message,
            time: moment(message.timestamp).format("h:mm A"),
            isMyMessage: true,
            hasMedia: message.content?.length ? true : false,
          },
        })
      );
    } else {
      _getAndUpdateChatThreads();
    }

    _scrollToBottomInChatWindow();

    _publishMessageOnChannel(message);

    _markMessageAsSent(message);
  };

  const _onTipPaymentSuccess = async (tip) => {
    try {
      if (!tip) return;

      const tipMessage = {
        _from: selectedThread?.fan?._id,
        _to: selectedThread?.influencer?._id,
        _thread: selectedThread?._id,
        timestamp: new Date(),
        isUploading: false,
        hasMedia: false,
        tempMessageId: cuid(),
        isLocalMessage: true,
        hasPurchased: false,
        isTip: true,
        text: tip,
      };

      // Only tip
      // Get messageId of last influencer's message
      const lastInfluencerMessage = _getLastInfluencerMessage();

      if (lastInfluencerMessage?.messageId) {
        tipMessage._tipMessage = lastInfluencerMessage.messageId;
      }

      _appendAndPublishNewMessage(tipMessage);
    } catch (error) {
      errorHandler(error);
    }

    _scrollToBottomInChatWindow();
  };

  const _sendNewMessage = async () => {
    try {
      if (loadingState.messages) return;

      const { images, videos, audios, text } = sendMessage;

      _toggleIsShowEmoji();

      let messageText = text?.trim().length ? text.trim() : null;

      const tempMessageId = cuid();

      const messagesToBeSent = {};

      // Prepare outbox queue
      // Iterate through images
      // Iterate through images videos audios
      if (
        Object.keys(images).length ||
        Object.keys(videos).length ||
        Object.keys(audios).length
      ) {
        messagesToBeSent[tempMessageId] = {
          _from: selectedThread?.fan?._id,
          _to: selectedThread?.influencer?._id,
          timestamp: new Date(),
          isUploading: true,
          media: {},
          hasMedia: true,
          isPublic: true,
          tempMessageId,
          isLocalMessage: true,
        };

        messagesToBeSent[tempMessageId]["media"] = {
          ...images,
          ...videos,
          ...audios,
        };
        if (messageText) {
          messagesToBeSent[tempMessageId]["text"] = messageText;
          messageText = null;
        }
      }

      if (Object.keys(messagesToBeSent)?.length === 0 && messageText) {
        messagesToBeSent[tempMessageId] = {
          _from: selectedThread?.fan?._id,
          _to: selectedThread?.influencer?._id,
          timestamp: new Date(),
          isUploading: true,
          hasMedia: false,
          tempMessageId,
          isLocalMessage: true,
        };
        if (messageText) {
          messagesToBeSent[tempMessageId]["text"] = messageText;
          messageText = null;
        }
      }

      if (Object.keys(messagesToBeSent)?.length) {
        for (let tempMessageId in messagesToBeSent) {
          messagesToBeSent[tempMessageId]["_thread"] = selectedThread?._id;
          // Append as local messages in thread
          _appendAndPublishNewMessage(messagesToBeSent[tempMessageId]);
        }
        //   Clear new message
        setSendMessage({
          text: "",
          images: {},
          videos: {},
          audios: {},
          tip: 0,
        });
      }
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _onMessageTextInputKeyUp = (e) => {
    if (
      e.key === "Enter" &&
      e.target.value.trim().length &&
      !e.ctrlKey &&
      !e.shiftKey
    ) {
      // inputRef.current.blur();
      _sendNewMessage();
    }
  };

  const _onMessageTextInputChange = (newText, isEmoji) => {
    const newSendMessage = { ...sendMessage };

    if (containsRestrictedWords(newText)) {
      _toggleTextDetectModal(true);
      newText = removeLastWord(newText);
    }

    if (isEmoji) {
      newSendMessage.text = newSendMessage.text + newText;
    } else {
      newSendMessage.text = newText;
    }

    setSendMessage(newSendMessage);
  };

  const _toggleIsShowEmoji = (isShow = false) => {
    setIsShowEmoji(isShow);
  };

  const _onEmojiClick = (event, emojiObject) => {
    _onMessageTextInputChange(emojiObject.emoji, true);
  };

  const _toggleChatVideoRoomComponent = (startCall = false, message = null) => {
    // video call
    setChatVideoRoomComponent({
      startCall,
      message,
    });
  };

  const _startCall = (shoutoutMessage) => {
    if (shoutoutMessage) {
      _toggleChatVideoRoomComponent(true, shoutoutMessage);
    }
  };

  const _createShoutOutRequest = (payload) => {
    dispatch(showLoader("Creating Request"));

    // delete payload.price
    createShoutOutRequest(payload)
      .then((res) => {
        dispatch(hideLoader());
        _toggleChatShoutoutModal();
        _togglePaymentModal(
          true,
          res.shoutOutOrderDoc.id,
          res.shoutOutOrderDoc.price,
          payload
        );
      })
      .catch((error) => {
        dispatch(hideLoader());
        _toggleChatShoutoutModal();
        errorHandler(error);
      });
  };

  const _formatMessageContent = async (contents) => {
    if (!contents?.length) {
      return;
    }

    const newSendMessage = { ...sendMessage };
    const currentTimeStamp = +new Date();

    contents.forEach((each, index) => {
      if (each.contentType.indexOf("image") > -1) {
        const imageId = currentTimeStamp + "" + index;
        newSendMessage.images[imageId] = each;
      } else if (each.contentType.indexOf("video") > -1) {
        const videoId = currentTimeStamp + "" + index;
        newSendMessage.videos[videoId] = each;
      } else if (each.contentType.indexOf("audio") > -1) {
        const audioId = currentTimeStamp + "" + index;
        newSendMessage.audios[audioId] = each;
      } else {
        alert("Only Image, Video and Audio are allowed");
      }
    });

    setSendMessage(newSendMessage);

    _scrollToBottomInChatWindow();
  };

  const _togglePaymentModal = (
    isOpen = false,
    shoutOutId = null,
    selectedPrice = null,
    payload = null
  ) => {
    setPaymentHoc({
      isOpen,
      apiData: {
        url: "/payment/shoutout",
        url3DSecure: "/payment/intent/shoutout",
        payload: {
          shoutOutId,
        },
      },
      selectedPrice,
      payload,
    });
  };

  const _createPayment = async (card = null) => {
    dispatch(showLoader("processing your payment"));

    const newPaymentHoc = { ...paymentHoc };

    if (card) newPaymentHoc.apiData.payload.card = card;

    newPaymentHoc.apiData.influencer = selectedThread?.influencer;

    try {
      await ProcessPayment.startPaymentProcess(
        newPaymentHoc.apiData,
        newPaymentHoc.selectedPrice
      );
      _paymentSuccess();
    } catch (error) {
      if (error && error.isRiskAssessment) {
        dispatch(
          updateOnlyUserData({
            ...userData.user,
            isRiskAssessment: error.isRiskAssessment,
          })
        );
      }
      errorHandler(error);
      _togglePaymentModal();
      dispatch(hideLoader());
    }
  };

  // Payment Done
  const _paymentSuccess = async () => {
    const message = {
      _thread: selectedThread?._id,
      _from: selectedThread?.fan?._id,
      _to: selectedThread?.influencer?._id,
      timestamp: new Date(),
      isUploading: false,
      isMyMessage: true,
      hasMedia: false,
      tempMessageId: cuid(),
      isLocalMessage: true,
      hasPurchased: false,
      isTip: false,
      text: paymentHoc.payload.message,
      _shoutoutOrder: paymentHoc.payload.pricingId,
      shoutoutType: paymentHoc.payload.shoutoutType,
      shoutoutDuration: paymentHoc.payload.duration,
    };

    _appendAndPublishNewMessage(message);

    showToast("Request created successfully", "success");
    _togglePaymentModal();

    dispatch(hideLoader());
  };

  const _getAndUpdateMessages = async (payload, isResetScroll = false) => {
    try {
      _manageLoadingState("messages", true);

      if (isResetScroll) {
        _scrollToBottomInChatWindow();
      }

      await getAndUpdateMessages({ threadId: selectedThread?._id, payload })(
        dispatch
      );

      _scrollToBottomInChatWindow(!isResetScroll);

      setTimeout(() => {
        _manageLoadingState("messages", false);
      }, 0);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("messages", false);
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loadingState?.messages) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          messages?.[selectedThread?._id]?.length <
            messagesCount?.[selectedThread?._id]
        ) {
          const newMessagesPayload = { ...messagesPayload };
          newMessagesPayload.skip =
            messages?.[selectedThread?._id]?.length || 0;
          setMessagesPayload(newMessagesPayload);
          _getAndUpdateMessages(newMessagesPayload);
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.messages, messages, messagesCount]
  );

  const _getAndUpdateChatThreads = async (isSubscribeAgain = false) => {
    try {
      _manageLoadingState("threads", true);

      await getAndUpdateChatThreads({
        limit: threadsCount > 500 ? 5000 : 500,
        skip: 0,
      })(dispatch);

      if (isSubscribeAgain) {
        _unsubscribeToSingleThreadChannel();

        await sleepTime(100);

        _subscribeToSingleThreadChannel();
      }

      _manageLoadingState("threads", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("threads", false);
    }
  };

  // subscribe to single thread with multiple receive messages
  const _subscribeToSingleThreadChannel = () => {
    try {
      const params = { room: userData.user._id };

      newSocket.emit("subscribe", params, function (res) {
        console.log("subscribed>>", res);
        if (res.error) {
          errorHandler(res);
        }
      });

      newSocket.on("messageNotifiation", (receiveMessage) => {
        console.log("receiveMessageNotifiation>>", receiveMessage);

        if (receiveMessage.doSkip) {
          if (receiveMessage._shoutoutOrder) {
            _saveShoutoutStartCallTime(receiveMessage);
          }
          return;
        }

        if (receiveMessage.error) {
          errorHandler(receiveMessage);
        } else {
          if (receiveMessage._to === userData?.user?._id) {
            const findThread = threads.find(
              (each) => each._id === receiveMessage._thread
            );
            if (findThread) {
              dispatch(
                addLocalMessage({
                  threadId: receiveMessage._thread,
                  message: {
                    ...receiveMessage,
                    time: moment(
                      receiveMessage.when || receiveMessage.timestamp
                    ).format("h:mm A"),
                    timestamp: receiveMessage.when || receiveMessage.timestamp,
                    isMyMessage: false,
                    isPublic:
                      receiveMessage?.content?.[0]?.amount > 0 ? false : true,
                  },
                })
              );
            } else {
              _getAndUpdateChatThreads(true);
            }

            dispatch(
              unreadMessageCountUpdate({
                threadId: receiveMessage?._thread,
              })
            );
          }
        }
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  // unsubscribe to single thread with multiple receive messages
  const _unsubscribeToSingleThreadChannel = () => {
    try {
      const params = { room: userData.user._id };
      // remove all callback of messageNotifiation event
      newSocket.removeAllListeners("messageNotifiation");
      // unsubscribe event - pause callback
      newSocket.emit("unsubscribe", params, function (res) {
        console.log("unsubscribed>>", res);
        if (res.error) {
          errorHandler(res);
        }
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  const _subscribeUnreadMessageCount = () => {
    try {
      const params = { room: userData.user._id };

      newSocket.emit("subscribe", params, function (res) {
        console.log("subscribed>>", res);
        if (res.error) {
          errorHandler(res);
        }
      });
      newSocket.on("messageNotifiation", (receiveMessage) => {
        if (receiveMessage?.doSkip) return;

        if (receiveMessage?.error) {
          errorHandler(receiveMessage);
        } else {
          if (receiveMessage._to === userData?.user?._id) {
            dispatch(
              unreadMessageCountUpdate({
                threadId: receiveMessage?._thread,
              })
            );
          }
        }
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  // Payment Intent For Apple Pay
  const _createPaymentIntent = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await createPaymentIntent(
          paymentHoc?.apiData?.url3DSecure,
          paymentHoc?.apiData?.payload
        );

        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  const _initialiseNewChat = () => {
    _toggleIsShowEmoji();

    setSendMessage({
      text: "",
      images: {},
      videos: {},
      audios: {},
      tip: 0,
    });

    const newMessagesPayload = { ...messagesPayload };
    newMessagesPayload.skip = 0;
    setMessagesPayload(newMessagesPayload);
    _getAndUpdateMessages(newMessagesPayload, true);
  };

  useEffect(() => {
    if (selectedThread?._id) {
      _initialiseNewChat();
    }

    return () => {
      try {
        if (selectedThread?._id) {
          const isLastMessageFromUser =
            (messages?.[selectedThread?._id]?.slice(-1)?.[0]?._from?.id ||
              messages?.[selectedThread?._id]?.slice(-1)?.[0]?._from) ===
            userData?.user?.id;

          if (!isLastMessageFromUser) {
            markThreadAsRead(selectedThread?._id);
          }
        }
      } catch (error) {
        console.log({ error });
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedThread?._id]);

  useEffect(() => {
    // unsubscribe for message unread count
    _unsubscribeToSingleThreadChannel();

    _subscribeToSingleThreadChannel();

    return () => {
      _unsubscribeToSingleThreadChannel();

      // subscribe message count show
      _subscribeUnreadMessageCount();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div
        className={`conversationWeb ${
          screenWidth < 768 && !selectedThread ? "d-none" : ""
        }`}
      >
        {selectedThread ? (
          <React.Fragment>
            <div className="addMessageWrap">
              <div className="receiverInfo-Chat">
                <div className="d-flex align-items-center">
                  <Button
                    className="customBackBtn mr-1"
                    onClick={() => _closeMessagesCard()}
                  >
                    <i className="fa fa-chevron-left" />
                  </Button>

                  <img
                    src={
                      getLowResolutionLink(
                        selectedThread?.influencer?.profilePicture
                      ) || DEFAULT_PROFILE_PICTURE
                    }
                    onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                    alt="Profile Img"
                    className="profileMsgPg"
                    loading="lazy"
                  />

                  <div
                    className="receiverName-Chat"
                    onClick={() =>
                      selectedThread?.influencer?.username &&
                      history.push(
                        `/influencer/${selectedThread?.influencer?.username}`
                      )
                    }
                  >
                    <span>@{selectedThread?.influencer?.username}</span>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <ImageQualitySetting
                    imageQuality={imageQuality}
                    setImageQuality={setImageQuality}
                    id={selectedThread?._id}
                    isFeed
                  />

                  <Button
                    color="link"
                    className="seeMedia"
                    onClick={() => _toggleViewChatMediaModal(true)}
                  >
                    Media
                  </Button>
                </div>
              </div>
              <div className="conversationDetails" ref={chatWindowRef}>
                {/* chat timeline */}

                {messages?.[selectedThread?._id]?.length ? (
                  messages[selectedThread?._id].map((message, messageIndex) => (
                    <Fragment key={`conversation_message_item_${messageIndex}`}>
                      <ErrorBoundary>
                        <MessageItem
                          {...(messageIndex === 0 ? { lastElementRef } : {})}
                          messageData={message}
                          loading={loadingState.messages}
                          prevDateHeading={generateCalenderDateForChat(
                            messages?.[selectedThread?._id]?.[messageIndex - 1]
                              ?.timestamp
                          )}
                          currDateHeading={generateCalenderDateForChat(
                            message?.timestamp
                          )}
                          startCall={_startCall}
                          publishMessageOnChannel={_publishMessageOnChannel}
                          imageQuality={imageQuality}
                          setImageQuality={setImageQuality}
                        />
                      </ErrorBoundary>
                    </Fragment>
                  ))
                ) : loadingState?.messages ? (
                  <SkeletonLoading type="conversation" count={2} />
                ) : (
                  <div />
                )}
              </div>

              {/* conversationDetails */}

              <div className="typeMsgWrap">
                {selectedThread?.isBlocked ||
                selectedThread?.isThreadBlocked ||
                selectedThread?.isExpired ||
                selectedThread?.isCountryBlocked ||
                !selectedThread?.influencer?.isActive ||
                selectedThread?.influencer?.isScheduleForAccountDelete ? (
                  <div className="text-center w-100">
                    {loadingState?.messages ? (
                      <p>Loading...</p>
                    ) : (
                      <p>
                        You can not send messages{" "}
                        {selectedThread?.isExpired ? (
                          <span
                            className="themeColor cursorPointer"
                            onClick={() =>
                              history.push(
                                `/manage-subscriptions?username=${selectedThread?.influencer?.username}`
                              )
                            }
                          >
                            until you renew subscription
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                    )}
                  </div>
                ) : (
                  <>
                    {mediaPreviews?.length ? (
                      <div className="msgPreviewWrap mb-2">
                        {mediaPreviews.map((media, mediaIndex) => (
                          <div className="msgPreview" key={mediaIndex}>
                            {_renderMediaPreview(media)}
                          </div>
                        ))}
                      </div>
                    ) : null}

                    {isShowEmoji ? (
                      <EmojiPickerReact
                        onEmojiClick={_onEmojiClick}
                        skinTone={SKIN_TONE_NEUTRAL}
                      />
                    ) : null}

                    <div className="position-relative">
                      <Button
                        className="sendMoney"
                        onClick={() => _toggleTipSetterModal(true)}
                        disabled={!selectedThread?.influencer?.paymentEnabled}
                      >
                        <SvgIcons type="tipActive" />
                      </Button>

                      <TextareaAutosize
                        style={autoHeightStyleChat}
                        rows="1"
                        placeholder="Message"
                        value={sendMessage.text}
                        onChange={(e) =>
                          _onMessageTextInputChange(e.target.value)
                        }
                        onKeyUp={(e) => _onMessageTextInputKeyUp(e)}
                        onFocus={() => _toggleIsShowEmoji()}
                      />

                      <Button
                        color="link"
                        className="emojiBtnMsg"
                        onClick={() =>
                          _toggleIsShowEmoji(isShowEmoji ? false : true)
                        }
                      >
                        <i className="fa fa-smile-o" />
                      </Button>

                      <Button
                        disabled={
                          fileCount || sendMessage.text?.trim().length
                            ? false
                            : true
                        }
                        className="sendMsg"
                        onClick={_sendNewMessage}
                      >
                        {loadingState.messages ? (
                          <i className="fa fa-spinner fa-spin mr-1" />
                        ) : (
                          <img
                            src="/assets/img/send.png"
                            alt="Send"
                            loading="lazy"
                          />
                        )}
                      </Button>
                    </div>

                    <div className="createPostBtnsWrapper my-1">
                      <div className="createPostBtnsWrap">
                        <div className="d-flex">
                          {userData?.user?.isMediaSendByFan ? (
                            <Label className="btn mediaBtn">
                              <img
                                src="/assets/img/img-gallery-active.png"
                                alt="Img"
                                onClick={() => _toggleMediaLibraryModal(true)}
                                loading="lazy"
                              />
                            </Label>
                          ) : null}

                          {isShoutoutActive && selectedThread?.influencer ? (
                            <>
                              <Button
                                className="mediaBtn"
                                onClick={() =>
                                  _toggleChatShoutoutModal(true, "audio")
                                }
                              >
                                <img
                                  src="/assets/img/microphone-feed.png"
                                  alt="Microphone"
                                  className="audioIcon"
                                  loading="lazy"
                                />
                              </Button>

                              <Button
                                className="mediaBtn"
                                onClick={() =>
                                  _toggleChatShoutoutModal(true, "video")
                                }
                              >
                                <img
                                  src="/assets/img/video-gallery-active.png"
                                  alt="Video"
                                  className="videoIcon"
                                  loading="lazy"
                                />
                              </Button>
                            </>
                          ) : null}
                        </div>

                        {fileCount > 0 ? (
                          <div className="filesSelected-CreatePost my-0">
                            {fileCount} {fileCount > 1 ? "files" : "file"}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <TipSetterModal
              isOpen={tipSetterModal.isOpen}
              influencer={selectedThread?.influencer}
              toggle={() => _toggleTipSetterModal()}
              onPaymentSuccess={_onTipPaymentSuccess}
            />

            <MediaLibraryModal
              isOpen={mediaLibraryModal.isOpen}
              toggle={() => _toggleMediaLibraryModal()}
              uploadContents={(contents) => _formatMessageContent(contents)}
              isMultipleImages={true}
              isMultipleVideos={true}
              isMultipleAudios={true}
              isMultipleUpload={true}
              isAudioUpload={true}
            />
          </React.Fragment>
        ) : (
          // {/* show the below div when no chat has been selected  */}
          <div className="selectChatUserWeb">
            <i className="fa fa-user-o" />

            <p>Select a user</p>
          </div>
        )}
      </div>

      <ChatShoutoutModal
        isOpen={chatShoutoutModal.isOpen}
        shoutoutType={chatShoutoutModal.shoutoutType}
        toggleChatShoutoutModal={() => _toggleChatShoutoutModal()}
        influencer={selectedThread?.influencer}
        createShoutOutRequest={(payload) => _createShoutOutRequest(payload)}
      />

      <ViewChatMediaModal
        isOpen={viewChatMediaModal.isOpen}
        toggle={() => _toggleViewChatMediaModal()}
        threadId={selectedThread?._id}
        imageQuality={imageQuality}
        setImageQuality={setImageQuality}
      />

      <PaymentHoc
        content={{
          name: "Shout Out",
          price: Number(paymentHoc.selectedPrice).toFixed(2),
        }}
        isVisible={paymentHoc.isOpen}
        amount={Number(paymentHoc.selectedPrice).toFixed(2)}
        apiData={paymentHoc.apiData}
        createPaymentIntent={() => _createPaymentIntent()}
        onPaymentConfirmation={(card) => _createPayment(card)}
        onPaymentSuccess={() => _paymentSuccess()}
        onDismiss={() => _togglePaymentModal()}
      />

      {chatVideoRoomComponent.startCall && chatVideoRoomComponent.message && (
        <ChatVideoRoomComponent
          startCall={chatVideoRoomComponent.startCall}
          message={chatVideoRoomComponent.message}
          removeVideoRoom={() => _toggleChatVideoRoomComponent()}
        />
      )}

      <TextDetectModal
        isOpen={textDetectModal.isOpen}
        toggle={() => _toggleTextDetectModal()}
      />
    </>
  );
};

export default ConversationCard;
