import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CARD_REQUIRED_ON_FREE, DEFAULT_PROFILE_PICTURE } from "../../config";
import { getLowResolutionLink } from "../../helper-methods";
import PaymentSubscriptionTier from "../PaymentSubscriptionTier";

const SubscriptionModal = ({
  isOpen,
  toggle,
  influencer,
  offers,
  bundles,
  subscriptionData,
  couponToken,
  getInfluencerDetails,
  toggleFreeSubscription,
  onPaymentConfirmation,
  postDataPayload,
  toggleDiscordConfigurationModal,
  loadingState,
  showErrorToast,
  toggleCancelSubscriptionModal,
}) => {
  const history = useHistory();

  const _closeModal = () => {
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} centered>
      <ModalHeader>Subscription Plans</ModalHeader>
      <ModalBody>
        <div>
          <div id="influencer-info" className="d-flex align-items-center">
            <img
              src={
                getLowResolutionLink(influencer?.profilePicture) ||
                DEFAULT_PROFILE_PICTURE
              }
              onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
              alt="Profile Pic"
              className="img60by60"
            />
            <div className="influencerName mt-0">
              <Button
                color="link"
                className="otherPgsProfileID text-decoration-none"
              >
                @{influencer?.username}
              </Button>
            </div>
          </div>
          <div id="base-plan"></div>
          <div id="offers">
            <div id="special-offer"></div>
            <div id="promotional-offer"></div>
          </div>

          {!subscriptionData.isSubscribed ? (
            couponToken ||
            (influencer &&
              influencer.isFreeSubscription &&
              influencer.isFreeSubscriptionsAllow) ? (
              <Button
                className="themeBtn btnSubscribe mx-1 mt-3"
                style={{ padding: " 0 12px", fontSize: 12 }}
                onClick={
                  () =>
                    couponToken || CARD_REQUIRED_ON_FREE === true
                      ? toggleFreeSubscription(true, true) // card required
                      : onPaymentConfirmation() // direct subscription without card
                }
              >
                Subscribe For Free
              </Button>
            ) : (
              <PaymentSubscriptionTier
                influencer={influencer}
                offers={offers}
                bundles={bundles}
                getInfluencerDetails={() =>
                  getInfluencerDetails({
                    ...postDataPayload,
                    skip: 0,
                  })
                }
                toggleDiscordConfigurationModal={(isOpen, influencerId) =>
                  toggleDiscordConfigurationModal(isOpen, influencerId)
                }
              />
            )
          ) : influencer &&
            subscriptionData &&
            subscriptionData.isSubscriptionExpired ? (
            <div className="d-flex">
              <Button
                className="themeBtn btnSubscribe mx-1 mt-3"
                style={{ padding: " 0 12px", fontSize: 12 }}
                disabled={loadingState?.posts}
                onClick={() =>
                  influencer?.isActive === false
                    ? showErrorToast(influencer?.username)
                    : toggleCancelSubscriptionModal({
                        isOpen: true,
                        influencer,
                        isRenewal: true,
                        isResume: false,
                      })
                }
              >
                Renew Subscription
              </Button>
              <Button
                className="themeBtn btnSubscribe mx-1 mt-3"
                style={{ padding: " 0 12px", fontSize: 12 }}
                disabled={loadingState?.posts}
                onClick={() =>
                  history.push(
                    `/manage-subscriptions?username=${influencer?.username}`
                  )
                }
              >
                Manage Subscription
              </Button>
            </div>
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button outline onClick={() => _closeModal()}>
          Cancel
        </Button>

        {/* <Button color="primary" onClick={() => _onSuccess()}>
          Susbcribe
        </Button> */}
      </ModalFooter>
    </Modal>
  );
};

export default SubscriptionModal;
