import React, { useEffect, useRef, useState } from "react";
import { Collapse, Button } from "reactstrap";
import Comments from "../containers/Comments";
import TextareaAutosize from "react-textarea-autosize";
import { autoHeightStyleComment } from "../assets/styles/js";
import SkeletonLoading from "./SkeletonLoading";
import { useSelector } from "react-redux";
import { DEFAULT_PROFILE_PICTURE } from "../config";
import { EventEmitter, getLowResolutionLink } from "../helper-methods";

const PostCommentSection = ({
  isOpen,
  feed,
  feedIndex,
  postComment,
  comments,
  getComments,
  onLikeComment,
  isCommentFetching,
  onComment,
  type,
  commentIdLikeLoading,
}) => {
  const user = useSelector((state) => state?.userData?.user);
  const textAreaRef = useRef(null);

  const [comment, setComment] = useState("");

  const _updateComment = (value, isEmoji = false) => {
    if (isEmoji) {
      setComment((prev) => prev + value);
    } else {
      setComment(value);
    }
  };

  const _clearComment = () => {
    setComment("");
  };

  const _onEnterPressed = (event) => {
    const code = event.keyCode || event.which;

    if (code === 13 && !event.shiftKey && !event.ctrlKey) {
      if (event) event.preventDefault();

      if (comment?.trim()?.length) {
        const newComment = comment.replace(/\n/g, "<br/>");
        postComment(newComment.trim());
        _clearComment();
      }
    }
  };

  const _postComment = () => {
    if (comment?.trim()?.length) {
      // const newComment = comment.replace(/\n/g, "<br/>");
      postComment(comment.trim());
      _clearComment();
    }
  };

  // const _onEmojiClick = (event, emojiObject) => {
  //   _updateComment(emojiObject.emoji, true);
  // };

  // _emojiSectionToggle = () => {
  //   setIsEmojiSectionShown(prev => !prev);
  // };

  const _postCommentTrigger = ({ postId, interaction }) => {
    console.log({ postId, interaction });
    getComments();
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        textAreaRef.current.focus();
      }, 0);

      EventEmitter.subscribe("post_comment_trigger", _postCommentTrigger);
    }

    return () => {
      EventEmitter.unsubscribe("post_comment_trigger");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      <Collapse isOpen={isOpen} className="commentSection">
        {feed?._influencer?.settings?.enableComments &&
          !feed?.subscription?.isCountryBlocked &&
          !feed?.isDeleted && (
            <div className="d-flex mb-3 mt-2 position-relative">
              <img
                src={
                  getLowResolutionLink(user?.profilePicture) ||
                  DEFAULT_PROFILE_PICTURE
                }
                onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
                alt="avatar"
                className="userImg-Comment"
                loading="lazy"
              />

              <TextareaAutosize
                inputRef={(tag) => (textAreaRef.current = tag)}
                minRows={1}
                style={autoHeightStyleComment}
                placeholder={"Add comment..."}
                onChange={(e) => _updateComment(e.target.value)}
                onKeyPress={(e) => _onEnterPressed(e)}
                value={comment}
              />

              {/* <Button
                color="link"
                className="emojiBtn"
                onClick={_emojiSectionToggle}
              >
                <i className="fa fa-smile-o" />
              </Button> */}

              <Button
                className="sendMsg"
                style={{ bottom: 6 }}
                disabled={!comment.trim().length}
                onClick={() => _postComment()}
              >
                <img src="/assets/img/send.png" alt="Send" loading="lazy" />
              </Button>
            </div>
          )}
        {/* {isEmojiSectionShown ? (
            <Picker
              onEmojiClick={onEmojiClick}
              skinTone={SKIN_TONE_NEUTRAL}
            />
          ) : null} */}

        {/* comments list */}
        {comments?.length ? (
          comments.map((each) => (
            <Comments
              key={each._id || each.tempId}
              comment={each}
              isSubComment={false}
              getComments={() => getComments()}
              likeComment={(id, isLike) => onLikeComment(id, isLike)}
              commentIdLikeLoading={commentIdLikeLoading}
              feed={feed}
              feedIndex={feedIndex}
              onComment={(index, isAdded) => onComment(index, isAdded)}
              type={type || "post"}
            />
          ))
        ) : isCommentFetching ? (
          <SkeletonLoading type={"comments"} count={2} />
        ) : (
          <div className="text-center">
            <span>No Comments Yet</span>
          </div>
        )}
      </Collapse>
    </>
  );
};

export default PostCommentSection;
