import React, { useMemo } from "react";
import { DEFAULT_PROFILE_PICTURE } from "../config";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { capitalize, formatDate, formatTime } from "../helper-methods";

const SuggestedLiveEventsCard = () => {
  const history = useHistory();

  const feedData = useSelector((state) => state?.feedData);

  // const ongoingEvents = useMemo(() => {
  //   if (!feedData?.suggestedEvents?.length) return [];

  //   return feedData?.suggestedEvents;
  // }, [feedData?.suggestedEvents]);

  const upcomingEvents = useMemo(() => {
    if (!feedData?.suggestedEvents?.length) return [];

    return feedData?.suggestedEvents;
  }, [feedData?.suggestedEvents]);

  const _redirectToInfluencerProfilePage = (influencer) => {
    history.push(`/influencer/${influencer?.username}`);
  };

  const _redirectToEventStreamingPage = (event) => {
    history.push(`/live-event-streaming/${event?._id}`);
  };

  if (!feedData?.suggestedEvents?.length) return <></>;

  return (
    <>
      <Card className="liveEventsListHome">
        <CardHeader>Live Events</CardHeader>
        <CardBody>
          {/* {ongoingEvents?.length ? (
            <ListGroup className="ongoingEvent">
              <ListGroupItemHeading>Ongoing</ListGroupItemHeading>
              {ongoingEvents?.slice(0, 2)?.map((each, index) => (
                <ListGroupItem key={`ongoingEvents_${each?._id}_${index}`}>
                  <img
                    src={
                      each?._influencer?.profilePicture ||
                      DEFAULT_PROFILE_PICTURE
                    }
                    alt="Profile Img"
                    loading="lazy"
                  />
                  <div>
                    <Button
                      color="link"
                      onClick={() =>
                        _redirectToInfluencerProfilePage(each?._influencer)
                      }
                      className="infProfile"
                    >
                      <span>@{each?._influencer?.username}</span> is hosting
                    </Button>
                    <h5>{capitalize(each?.description)}</h5>
                  </div>
                  <Button onClick={() => _redirectToEventStreamingPage(each)}>
                    Join
                  </Button>
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : null} */}

          {upcomingEvents?.length ? (
            <ListGroup className="upcomingEvent">
              <ListGroupItemHeading>Upcoming</ListGroupItemHeading>
              {upcomingEvents?.slice(0, 2)?.map((each, index) => (
                <ListGroupItem key={`upcomingEvents_${each?._id}_${index}`}>
                  <img
                    src={
                      each?._influencer?.profilePicture ||
                      DEFAULT_PROFILE_PICTURE
                    }
                    alt="Profile Img"
                    loading="lazy"
                  />
                  <div>
                    <Button
                      color="link"
                      onClick={() =>
                        _redirectToInfluencerProfilePage(each?._influencer)
                      }
                      className="infProfile"
                    >
                      <span>@{each?._influencer?.username}</span> is hosting
                    </Button>
                    <h5>{capitalize(each?.description)}</h5>
                    <h6>
                      {formatDate(each?.scheduledAt, true)},{" "}
                      {formatTime(each?.scheduledAt)} -{" "}
                      {formatTime(each?.scheduledAt)}
                    </h6>
                  </div>
                  <Button onClick={() => _redirectToEventStreamingPage(each)}>
                    Join
                  </Button>
                </ListGroupItem>
              ))}
            </ListGroup>
          ) : null}
        </CardBody>
      </Card>
    </>
  );
};

export default SuggestedLiveEventsCard;
