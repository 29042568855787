import React, { useState, useEffect } from "react";
import { Button, Container, Row, Col, Label } from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../config";
import {
  formatDate,
  getPostedTimeValue,
  showToast,
  checkPastEvent,
  canSubscriberJoin,
  errorHandler,
} from "../helper-methods";
import { fetchEvent, updateRsvp, createPaymentIntent } from "../http-calls";
import ProcessPayment from "../payment/index";
import ConfigureString from "../components/ConfigureString";
import PaymentTierComponent from "../components/PaymentTierComponent";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { hideLoader, showLoader, updateOnlyUserData } from "../redux/actions";
import CustomLoader from "../components/custom/CustomLoader";
import ReadMoreReadLess from "../components/custom/ReadMoreReadLess";
import ErrorBoundary from "../components/ErrorBoundary";
import CustomVideoPlayer from "../components/custom/CustomVideoPlayer";

const LiveEventDetailsPage = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const params = useParams();

  const userData = useSelector((state) => state?.userData);
  const feedData = useSelector((state) => state?.feedData);

  const [eventData, setEventData] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [isPastEvent, setIsPastEvent] = useState(false);
  const [isPlayVideoPreview, setIsPlayVideoPreview] = useState(false);
  const [loading, setLoading] = useState(false);

  const [paymentApiData, setPaymentApiData] = useState({
    url3DSecure: "/payment/intent/event",
    url: "/payment/event",
    payload: {
      eventId: params?.id,
    },
  });

  const _fetchEvent = () => {
    setLoading(true);

    fetchEvent(params?.id)
      .then((res) => {
        setEventData(res.event);
        setIsPastEvent(checkPastEvent(res.event.scheduledAt));
        setSubscription(res.subscription);

        setLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
      });
  };

  const _updateRsvpFeedback = (replyString) => {
    if (eventData?.rsvpStatus === replyString) return;

    dispatch(showLoader("Updating event Status"));

    updateRsvp({ id: params?.id, payload: { replyString } })
      .then((res) => {
        setEventData(res.event);

        _fetchEvent();

        dispatch(hideLoader());

        showToast("Updated Successfully", "success");
      })
      .catch((error) => {
        dispatch(hideLoader());
        errorHandler(error);
      });
  };

  // Payment Success For Apple Pay
  const _paymentSuccess = () => {
    const newEventData = { ...eventData };
    newEventData._isPaid = 1;
    setEventData(newEventData);

    _fetchEvent();

    setTimeout(() => {
      dispatch(hideLoader());
    }, 500);
  };

  const _onPaymentConfirmation = async (amountToPay, card) => {
    dispatch(showLoader("Payment Processing"));

    const newPaymentApiData = { ...paymentApiData };

    if (card) {
      newPaymentApiData.payload.card = card;
    }

    newPaymentApiData.influencer = eventData?._influencer;

    try {
      await ProcessPayment.startPaymentProcess(newPaymentApiData, amountToPay);
      _paymentSuccess();
    } catch (error) {
      if (error && error.isRiskAssessment) {
        dispatch(
          updateOnlyUserData({
            ...userData.user,
            isRiskAssessment: error.isRiskAssessment,
          })
        );
      }
      dispatch(hideLoader());
      errorHandler(error);
    }
  };

  // Payment Intent For Apple Pay
  const _createPaymentIntent = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await createPaymentIntent(
          paymentApiData?.url3DSecure,
          paymentApiData?.payload
        );

        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  };

  const _startStopEventPreviewVideo = () => {
    if (eventData?.previewVideoUrl || eventData?.previewHls) {
      setIsPlayVideoPreview((prev) => !prev);
    }
  };

  useEffect(() => {
    const newPaymentApiData = { ...paymentApiData };
    newPaymentApiData.payload.eventId = params?.id;
    setPaymentApiData(newPaymentApiData);

    _fetchEvent();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id]);

  return (
    <>
      <div className="customPgHeight animated fadeIn">
        <Container className="noPadding">
          <Row className="justify-content-center noMargin mb-2">
            <Col sm={12} md={11} lg={9} className="noPadding">
              <div className="pgTitle justify-content-start customMarginMob">
                <Button
                  className="customBackBtn"
                  onClick={() => history.goBack()}
                >
                  <i className="fa fa-chevron-left" />
                </Button>
                <h2>RSVP Status</h2>
                {loading ? <CustomLoader className="ml-2" /> : null}
              </div>

              {eventData && (
                <div className="liveEventDetailsWrap">
                  <div className="mediaVideoWrap-Feed">
                    {!isPlayVideoPreview ? (
                      <div className="liveStreamEventDate">
                        <div className="eventDate">
                          {formatDate(eventData?.scheduledAt)}
                        </div>

                        <div className="eventTime">
                          {getPostedTimeValue(eventData?.scheduledAt)}{" "}
                          {eventData?.hasOwnProperty("scheduledEnd")
                            ? "- " + getPostedTimeValue(eventData?.scheduledEnd)
                            : null}
                        </div>
                      </div>
                    ) : null}

                    {/* Cancelled Event  */}
                    {eventData?.hasOwnProperty("status") &&
                      eventData?.status === "cancelled" && (
                        <div className="cancelledEventWrap">
                          <div>
                            <ConfigureString keyString={"event Cancelled"} />
                          </div>
                        </div>
                      )}

                    {(eventData?.previewVideoUrl ||
                      eventData?.previewHls) &&
                    isPlayVideoPreview ? (
                      <>
                        {/* event preview video */}
                        {/* custom video player support hls */}
                        <CustomVideoPlayer
                          // className={`${getAspectRatioClass(
                          //   eventData?.previewVideoUrlAspectRatio
                          // )}`}
                          onEnded={() => _startStopEventPreviewVideo()}
                          src={eventData?.previewVideoUrl}
                          hlsLink={eventData?.previewHls}
                          duration={eventData?.previewVideoUrlDuration}
                          autoPlay
                          controls
                        />
                        <div className="videoPreviewTag videoPreviewLiveEvent">
                          Video Preview
                        </div>
                      </>
                    ) : (
                      // event cover image
                      <>
                        <div className="shadowOverlayImg" />
                        <img
                          alt="Event Cover Img"
                          className="mediaPhoto-Feed"
                          src={eventData?.coverImage || DEFAULT_COVER_PICTURE}
                          loading="lazy"
                        />
                      </>
                    )}

                    {(eventData?.previewVideoUrl ||
                      eventData?.previewHls) &&
                    !isPlayVideoPreview ? (
                      <Button
                        title="Trailer"
                        className="videoPlayBtn"
                        onClick={() => {
                          _startStopEventPreviewVideo();
                        }}
                      >
                        <i className="fa fa-play" />
                      </Button>
                    ) : null}
                  </div>

                  <div className="ppvDetailsWrap">
                    <h5 className="liveStreamEventName">{eventData?.name}</h5>

                    {eventData?.description?.length ? (
                      <ReadMoreReadLess
                        className="readMore"
                        text={eventData.description}
                      />
                    ) : null}
                  </div>

                  {!isPastEvent && eventData?.status !== "cancelled" ? (
                    <Row className="noMargin mb-2">
                      <Col sm={7} className="order-2 order-sm-1 noPadding">
                        <div className="rsvpResponse">
                          <h4>Add your RSVP to get updates</h4>

                          <div className="d-flex justify-content-start">
                            <Button
                              className={`rsvpResponseBtn ${
                                eventData?.rsvpStatus === "yes"
                                  ? "rsvpResponseBtn-active"
                                  : ""
                              }`}
                              onClick={() => _updateRsvpFeedback("yes")}
                            >
                              Yes
                            </Button>
                            <Button
                              className={`rsvpResponseBtn ${
                                eventData?.rsvpStatus === "no"
                                  ? "rsvpResponseBtn-active"
                                  : ""
                              }`}
                              onClick={() => _updateRsvpFeedback("no")}
                              disabled={eventData?._isPaid ? true : false}
                            >
                              No
                            </Button>
                            <Button
                              className={`rsvpResponseBtn ${
                                eventData?.rsvpStatus === "maybe"
                                  ? "rsvpResponseBtn-active"
                                  : ""
                              }`}
                              onClick={() => _updateRsvpFeedback("maybe")}
                              disabled={eventData?._isPaid ? true : false}
                            >
                              Maybe
                            </Button>
                          </div>
                        </div>
                      </Col>

                      <Col sm={5} className="order-1 order-sm-2 noPadding">
                        <div className="attendingStatus">
                          <h4>Who are attending</h4>
                          <div className="rsvpStatusWrap">
                            <div>
                              <Label>Yes</Label>
                              <p>{eventData?.rsvpYes?.length || 0}</p>
                            </div>
                            <div>
                              <Label>Maybe</Label>
                              <p className="text-right">
                                {eventData?.rsvpMaybe?.length || 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  ) : null}

                  {/* The Below Payment part will be shown only when the user selects the "Yes" option as RSVP for the Event */}
                  {!eventData?._influencer?.isScheduleForAccountDelete &&
                  eventData?.hasOwnProperty("_isPaid") &&
                  eventData?.hasOwnProperty("price") &&
                  !eventData?._isPaid &&
                  eventData?.hasOwnProperty("rsvpStatus") &&
                  eventData?.rsvpStatus === "yes" &&
                  eventData?.status !== "cancelled" ? (
                    <ErrorBoundary>
                      <PaymentTierComponent
                        apiData={paymentApiData}
                        paymentFor={eventData}
                        subscription={subscription}
                        bundles={feedData?.bundles}
                        influencer={eventData?._influencer}
                        onPaymentSuccess={_fetchEvent}
                        onBuy={(amountToPay) =>
                          _onPaymentConfirmation(amountToPay)
                        }
                        // For Apple Pay
                        content={{
                          name: "Live Event",
                          price:
                            subscription?.tier === "plus"
                              ? eventData?.plusPrice
                              : subscription?.tier === "premium"
                              ? eventData?.premiumPrice
                              : eventData?.price,
                        }}
                        createPaymentIntent={() => _createPaymentIntent()}
                        onApplePaymentSuccess={() => _paymentSuccess()}
                      />
                    </ErrorBoundary>
                  ) : null}

                  {!eventData?._influencer?.isScheduleForAccountDelete &&
                    canSubscriberJoin(eventData) && (
                      <div className="joinLiveEventWrap">
                        <Button
                          type="button"
                          className="themeBtn joinLiveEvent"
                          onClick={() =>
                            history.push(
                              `/live-event-streaming/${eventData.id}`
                            )
                          }
                        >
                          Join
                        </Button>
                      </div>
                    )}
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default LiveEventDetailsPage;
