import React, {
  Fragment,
  Suspense,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, ListGroup } from "reactstrap";
import { errorHandler, getMessageParticipants } from "../../helper-methods";
import { searchMessageByText } from "../../http-calls";
import { getAndUpdateChatThreads } from "../../redux/actions";
import ThreadNotFound from "./ThreadNotFound";
import CustomTooltip from "../custom/CustomTooltip";
import { useHistory } from "react-router-dom";
import ErrorBoundary from "../ErrorBoundary";
import SkeletonLoading from "../SkeletonLoading";

// code splitting
const ThreadGroupItem = React.lazy(() => import("./ThreadGroupItem"));

const ThreadListGroup = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const observer = useRef();
  const searchTimerRef = useRef({ current: null });

  const userData = useSelector((state) => state?.userData);
  const chatData = useSelector((state) => state?.chatData);
  const influencersOnlineData = useSelector(
    (state) => state?.influencersOnlineData
  );

  const [isSearchMessages, setIsSearchMessages] = useState(false);
  const [searchMessagesResult, setSearchMessagesResult] = useState([]);
  const [searchMessagesCount, setSearchMessagesCount] = useState([]);
  const [searchPayload, setSearchPayload] = useState({
    messagelimit: 20,
    messageskip: 0,
    search: "",
    sendThread: false,
  });
  const [threadsPayload, setThreadsPayload] = useState({
    limit: 100,
    skip: 0,
  });
  const [loadingState, setLoadingState] = useState({
    messages: false,
    threads: false,
    search: false,
  });

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _toggleIsSearchMessages = (isShow = false) => {
    setIsSearchMessages(isShow);
  };

  const _searchMessageByText = async (payload) => {
    try {
      if (!payload?.search?.trim()?.length) {
        setSearchMessagesResult([]);
        _manageLoadingState("search", false);
        return;
      }

      _manageLoadingState("search", true);

      const res = await searchMessageByText(payload);

      const messages =
        res.messages?.map((each) => {
          const { fan, influencer } = getMessageParticipants(each);

          return {
            ...each,
            lastMessage: each._messages || each?.lastMessage,
            fan,
            influencer,
          };
        }) || [];

      setSearchMessagesResult((prev) => {
        if (payload.messageskip) {
          return prev.concat(messages);
        }

        return messages;
      });
      setSearchMessagesCount(res.messageCount);

      _manageLoadingState("search", false);

      _manageLoadingState("search", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("search", false);

      _manageLoadingState("search", false);
    }
  };

  const _onChangeSearchText = (value) => {
    if (searchTimerRef?.current) clearTimeout(searchTimerRef.current);

    _manageLoadingState("search", true);

    const newSearchPayload = { ...searchPayload };
    newSearchPayload.search = value;
    setSearchPayload(newSearchPayload);

    searchTimerRef.current = setTimeout(() => {
      newSearchPayload.search = newSearchPayload.search?.trim() || "";
      newSearchPayload.messageskip = 0;
      setSearchPayload(newSearchPayload);

      _searchMessageByText(newSearchPayload);
    }, 1000);
  };

  const _getAndUpdateChatThreads = async (payload) => {
    try {
      _manageLoadingState("threads", true);

      await getAndUpdateChatThreads(payload)(dispatch);

      _manageLoadingState("threads", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("threads", false);
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loadingState?.threads || loadingState?.search) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (isSearchMessages) {
          if (
            entries[0].isIntersecting &&
            searchMessagesResult?.length < searchMessagesCount
          ) {
            const newSearchPayload = { ...searchPayload };
            newSearchPayload.messageskip = searchMessagesResult?.length || 0;
            setSearchPayload(newSearchPayload);
            _searchMessageByText(newSearchPayload);
          }
        } else {
          if (
            entries[0].isIntersecting &&
            chatData?.threads?.length < chatData?.threadsCount
          ) {
            const newThreadsPayload = { ...threadsPayload };
            newThreadsPayload.skip = chatData?.threads?.length || 0;
            setThreadsPayload(newThreadsPayload);
            _getAndUpdateChatThreads(newThreadsPayload);
          }
        }
      });

      if (node) observer.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      loadingState?.threads,
      loadingState?.search,
      isSearchMessages,
      chatData,
      searchMessagesResult,
    ]
  );

  useEffect(() => {
    _getAndUpdateChatThreads(threadsPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={isSearchMessages ? "pgTitle d-none" : "pgTitle px-0"}>
        <div className="d-flex">
          <h2>Messages</h2>
        </div>

        <div>
          <Button
            className="initiateMsg"
            onClick={() => _toggleIsSearchMessages(true)}
            id="messagesPageSearchIcon"
          >
            <i className="fa fa-search" />
            <CustomTooltip
              target="messagesPageSearchIcon"
              className="hideTooltipMobile"
              text="Search"
            />
          </Button>

          <Button
            className="initiateMsg"
            onClick={() => history.push("/initate-message")}
            id="messagesPageNewMessageIcon"
          >
            <img src="/assets/img/pencil.png" alt="Pencil" loading="lazy" />
            <CustomTooltip
              target="messagesPageNewMessageIcon"
              className="hideTooltipMobile"
              text="New Message"
            />
          </Button>
        </div>
      </div>

      <div
        className={
          isSearchMessages ? "searchChatListWrap" : "searchChatListWrap d-none"
        }
        style={{ marginTop: 13 }}
      >
        <i className="fa fa-search searchIcon" />
        <Input
          type="text"
          placeholder="Search"
          value={searchPayload.search}
          onChange={(e) => _onChangeSearchText(e.target.value)}
        />

        {loadingState.search ? (
          <i className="fa fa-spinner fa-spin clearSearch" />
        ) : (
          <i
            className="fa fa-times clearSearch"
            onClick={() => {
              _toggleIsSearchMessages(false);
            }}
          />
        )}
      </div>

      <div className="recentWrap">
        <p>Recent</p>
      </div>

      <ListGroup className="chatList">
        {searchPayload.search && isSearchMessages ? (
          searchMessagesResult?.length ? (
            searchMessagesResult.map((thread, threadIndex) => (
              <Fragment key={thread._id + threadIndex}>
                <ErrorBoundary>
                  <Suspense
                    fallback={<SkeletonLoading type={"threadList"} count={1} />}
                  >
                    <ThreadGroupItem
                      {...(threadIndex === searchMessagesResult.length - 1
                        ? { lastElementRef }
                        : {})}
                      thread={thread}
                      threadIndex={threadIndex}
                      ownId={userData?.user?._id}
                      influencersOnlineData={influencersOnlineData}
                      loading={loadingState.search}
                      isSearchList={true}
                      setIsSearchMessages={setIsSearchMessages}
                    />
                  </Suspense>
                </ErrorBoundary>
              </Fragment>
            ))
          ) : (
            <ThreadNotFound
              loading={loadingState.search}
              text={`No messages found with "${searchPayload.search}"`}
            />
          )
        ) : chatData?.threads?.length ? (
          chatData.threads.map((thread, threadIndex) => (
            <Fragment key={thread._id + threadIndex}>
              <ErrorBoundary>
                <Suspense
                  fallback={<SkeletonLoading type={"threadList"} count={1} />}
                >
                  <ThreadGroupItem
                    {...(threadIndex === chatData.threads.length - 1
                      ? { lastElementRef }
                      : {})}
                    thread={thread}
                    threadIndex={threadIndex}
                    ownId={userData?.user?._id}
                    influencersOnlineData={influencersOnlineData}
                    loading={loadingState.threads}
                  />
                </Suspense>
              </ErrorBoundary>
            </Fragment>
          ))
        ) : (
          <ThreadNotFound
            loading={loadingState.threads}
            text={`No conversation started yet`}
          />
        )}
      </ListGroup>
    </>
  );
};

export default ThreadListGroup;
