import React, { Fragment } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonBox = ({
  count,
  height = 250,
  borderRadius = 15,
  width = undefined,
  style = {},
}) => {
  return (
    <>
      {[...Array(count)].map((_, index) => (
        <Fragment key={`SkeletonBox_${index}`}>
          <Skeleton
            height={height}
            {...(width ? { width } : {})}
            borderRadius={borderRadius}
            style={style}
          />
        </Fragment>
      ))}
    </>
  );
};

export default SkeletonBox;
