import React, {
  Fragment,
  Suspense,
  useCallback,
  useRef,
  useState,
} from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Row,
  Button,
  UncontrolledPopover,
  PopoverBody,
  CustomInput,
} from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../config";
import { vaultSortOptionsConfig } from "../config/helper-config";
import { errorHandler, getLowResolutionLink } from "../helper-methods";
import { getAllFolders } from "../http-calls";
import ConfigureString from "./ConfigureString";
import ErrorBoundary from "./ErrorBoundary";
import SkeletonLoading from "./SkeletonLoading";
import VaultCardComponent from "./VaultCardComponent";
import ReadMoreReadLess from "./custom/ReadMoreReadLess";

const InfluencerVaultsComponent = ({ data }) => {
  const history = useHistory();

  const foldersObserver = useRef();

  const influencersOnlineData = useSelector(
    (state) => state?.influencersOnlineData
  );

  const [folders, setFolders] = useState([]);
  const [foldersCount, setFoldersCount] = useState(0);
  const [foldersDataPayload, setFoldersDataPayload] = useState({
    skip: 0,
    limit: 20,
    pageSize: 20,
    pageNumber: 1,
    filterType: "Influencer",
  });
  const [loadingState, setLoadingState] = useState({});
  const [isOpenFolderPopover, setIsOpenFolderPopover] = useState(false);
  const [flag, setFlag] = useState(true);

  const _manageLoadingState = (key = "", value = false) => {
    setLoadingState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _getAllFolders = async (payload) => {
    try {
      _manageLoadingState("folders", true);

      const res = await getAllFolders({ id: data?._influencer?._id, payload });

      if (res?.folders?.length === 0) {
        setFoldersCount(0);
        // setFolders([]);
        setFlag(false);
        _manageLoadingState("folders", false);
        return;
      }

      setFolders((prev) =>
        payload?.skip ? prev.concat(res.folders) : res.folders
      );

      setFoldersCount(res.count);

      _manageLoadingState("folders", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("folders", false);
    }
  };

  const _updateVaultKey = ({ folderId, key, value }) => {
    try {
      const newFolders = [...folders];

      const findFolder = newFolders?.find((each) => each._id === folderId);
      findFolder[key] = value;

      setFolders(newFolders);
    } catch (error) {
      _getAllFolders({
        ...foldersDataPayload,
        skip: 0,
        pageNumber: 1,
      });
    }
  };

  const _onChangeFolderSort = (value) => {
    const newDataPayload = { ...foldersDataPayload };
    newDataPayload.skip = 0;
    newDataPayload.pageNumber = 1;
    newDataPayload.filterType = value;
    setFoldersDataPayload(newDataPayload);
    _getAllFolders(newDataPayload);
  };

  const foldersLastElementRef = useCallback(
    (node) => {
      if (loadingState?.folders) return;

      if (foldersObserver.current) foldersObserver.current.disconnect();

      foldersObserver.current = new IntersectionObserver((entries) => {
        if (
          entries[0].isIntersecting &&
          folders?.length < foldersCount &&
          flag
        ) {
          const newDataPayload = { ...foldersDataPayload };
          newDataPayload.skip = folders?.length || 0;
          if (folders?.length) {
            newDataPayload.pageNumber++;
          }
          setFoldersDataPayload(newDataPayload);
          _getAllFolders(newDataPayload);
        }
      });

      if (node) foldersObserver.current.observe(node);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loadingState?.folders, folders]
  );

  useEffect(() => {
    if (data?._influencer?._id && data?.isExpired === false) {
      _getAllFolders({
        ...foldersDataPayload,
        skip: 0,
        pageNumber: 1,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?._influencer?._id, data?.isExpired]);

  return (
    <>
      <div className="creatorInfoVault mb-1 mb-sm-2">
        <div className="d-flex justify-content-start align-items-center">
          <div className="position-relative">
            <img
              src={
                getLowResolutionLink(data?._influencer?.profilePicture) ||
                DEFAULT_PROFILE_PICTURE
              }
              onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
              alt="Profile Img"
              className="userImg mr-1"
              loading="lazy"
            />

            {/* Online Status */}
            {influencersOnlineData?.influencers?.[data?._influencer?._id] ? (
              <span className="fa fa-circle onlineStatus" />
            ) : null}
          </div>
          <h5>
            {data?._influencer?.username
              ? `@${data._influencer.username}`
              : "N/A"}
          </h5>

          <Button
            color="link"
            className="otherPgsProfileID ml-auto"
            onClick={() =>
              history.push(`/influencer/${data?._influencer?.username}`)
            }
          >
            {/* Profile Link */}
            <i
              className="fa fa-user-circle-o"
              style={{ fontSize: 20, verticalAlign: -2 }}
            />
          </Button>
        </div>
        {/* Below is the creator bio. It should not exceed 600 characters */}

        <ReadMoreReadLess
          className="readMore"
          count={50}
          text={
            data?._influencer?.vaultDescription ||
            "Want to get instant access to my most exclusive content? Click on a folder below and unlock it now!"
          }
        />
      </div>

      {data?._influencer && data?.isExpired ? (
        <div className="text-center mt-3">
          <b>Subscription Expired. Renew to access content.</b>{" "}
          <Button
            color="link"
            className="otherPgsProfileID"
            style={{ verticalAlign: 0 }}
            onClick={() =>
              history.push(
                `/manage-subscriptions?username=${data?._influencer?.username}`
              )
            }
          >
            Click here
          </Button>{" "}
          <b>to manage subscription</b>
        </div>
      ) : (
        <>
          <div className="text-right mb-2 mb-sm-3">
            <Button
              id={`vault_page_isOpenFolderPopover${data?._id}`}
              className="customPopoverBtn filterBtnInfluencerDetails"
              onClick={() => setIsOpenFolderPopover((prev) => !prev)}
            >
              <img
                src="/assets/img/filter-icon.png"
                alt="Filter"
                loading="lazy"
              />
            </Button>

            <UncontrolledPopover
              className="customPopover"
              placement="bottom"
              isOpen={isOpenFolderPopover}
              target={`vault_page_isOpenFolderPopover${data?._id}`}
              toggle={() => setIsOpenFolderPopover((prev) => !prev)}
              trigger="legacy"
            >
              <PopoverBody>
                {vaultSortOptionsConfig.map((each) => (
                  <div key={each.value} className="radioWrap">
                    <CustomInput
                      type="radio"
                      id={`vault_page_${each.value}`}
                      name="radios"
                      checked={foldersDataPayload?.filterType === each.value}
                      value={each.value}
                      onChange={() => _onChangeFolderSort(each.value)}
                      label={each.label}
                    />
                  </div>
                ))}
              </PopoverBody>
            </UncontrolledPopover>
          </div>

          {folders?.length ? (
            <>
              <Row className="vaultFolderRow influencerVaultSection">
                {folders.map((each, index) => (
                  <Fragment
                    key={`influencer_vault_section_${each._id}_${index}`}
                  >
                    <ErrorBoundary>
                      <Suspense
                        fallback={
                          <SkeletonLoading
                            type={"vaultContentCard"}
                            count={1}
                          />
                        }
                      >
                        <VaultCardComponent
                          {...(index === folders.length - 1
                            ? { lastElementRef: foldersLastElementRef }
                            : {})}
                          folder={each}
                          influencer={data?._influencer}
                          updateVaultKey={_updateVaultKey}
                        />
                      </Suspense>
                    </ErrorBoundary>

                    {index === folders.length - 1 && loadingState.folders && (
                      <SkeletonLoading type={"vaultContentCard"} count={6} />
                    )}
                  </Fragment>
                ))}
              </Row>
            </>
          ) : loadingState.folders ? (
            <Row className="vaultFolderRow influencerVaultSection">
              <SkeletonLoading type={"vaultContentCard"} count={6} />
            </Row>
          ) : (
            <div className="noContentFound">
              <ConfigureString keyString={"No vault found"} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default InfluencerVaultsComponent;
