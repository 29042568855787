import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "../node_modules/@coreui/coreui/dist/css/coreui.min.css";
import FullPageLoader from "./containers/FullPageLoader";
import "./assets/styles/scss/App.scss";
import ProtectedRoute from "./components/ProtectedRoute";
import { Toaster } from "react-hot-toast";
import PromptModal from "./components/modals/PromptModal";
import PublicRoute from "./components/PublicRoute";
import LoginPage from "./pages/LoginPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import SignupPublicPage from "./pages/SignupPublicPage";
import SignupProtectedPage from "./pages/SignupProtectedPage";
import HomePage from "./pages/HomePage";
import { loadStripe } from "@stripe/stripe-js";
import {
  //  APP_LOGO_ICON,
  ONE_SIGNAL_APP_ID,
  STRIPE_API_KEY,
} from "./config";
import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { setupDescription } from "./helper-methods";
import runOneSignal from "./one-signal";
import PublicInfluencerProfile from "./pages/PublicInfluencerProfile";

const stripePromise = loadStripe(STRIPE_API_KEY);

const App = () => {
  const [promptModalVisible, setPromptModalVisible] = useState(false);

  if (process.env.REACT_APP_BACKEND_ENV === "prod") {
    console.log = () => {};
    document.addEventListener("contextmenu", (event) => event.preventDefault());
    document.onkeydown = function (event) {
      // c/C => 67 /,/ i/I => 73 /,/ u/U => 85 /,/ p/P => 80 /,/ F12 => 123 //
      const key = event.key ? event.key.toLowerCase() : null;
      if (
        key &&
        (key === "f12" ||
          (event.ctrlKey && (key === "i" || key === "u" || key === "p")))
      ) {
        return false;
      }
      return true;
    };
  }

  // const _setupTitleAndIcon = () => {
  //   try {
  //     document.title = process.env.REACT_APP_NAME;

  //     let link = document.querySelector("link[rel~='icon']");
  //     if (!link) {
  //       link = document.createElement("link");
  //       link.rel = "icon";
  //       document.getElementsByTagName("head")[0].appendChild(link);
  //     }
  //     link.href = APP_LOGO_ICON;
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // };

  useEffect(() => {
    setupDescription();
    // _setupTitleAndIcon();
    runOneSignal({ appId: ONE_SIGNAL_APP_ID });
  }, []);

  return (
    <StripeElements stripe={stripePromise}>
      <Router>
        <div data-test="component-app">
          <FullPageLoader />
          <PromptModal
            isVisible={promptModalVisible} // promptModalVisible
            message={`<i class="fa fa-exclamation-triangle text-warning"> </i> NOTICE: <br>
                Our system detected a screenshot and informed the owner.
                <i class="fa fa-ban text-danger"> </i> WARNING: Users whom take content without expressed permissions will be prosecuted. <br> 
                <a href="https://drive.google.com/file/d/1c045XgeJQ9EYLRDiOkB526yj5JD-twkO/view?usp=sharing">Terms & Conditions </a>`}
            onSuccess={() => setPromptModalVisible(false)}
            onDismiss={() => setPromptModalVisible(false)}
          />
          <Toaster position="bottom-right" reverseOrder={false} />
          <Switch>
            <PublicRoute
              exact
              path="/login"
              component={LoginPage}
              redirectRoute="/feed"
            />
            <PublicRoute
              exact
              path="/forgot-password"
              component={ForgotPasswordPage}
              redirectRoute="/feed"
            />

            <PublicRoute
              exact
              path="/register"
              component={SignupPublicPage}
              redirectRoute="/feed"
            />

            <Route
              exact
              path="/public/:id/:postId"
              component={PublicInfluencerProfile}
            />

            <Route
              exact
              path="/public/:id"
              component={PublicInfluencerProfile}
            />

            <ProtectedRoute
              exact
              path="/updateProfile"
              component={SignupProtectedPage}
              redirectRoute="/login"
            />
            <Route exact path="*" component={HomePage} redirectRoute="/login" />
          </Switch>
        </div>
      </Router>
    </StripeElements>
  );
};

export default App;
