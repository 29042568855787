import React, { useCallback, useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  errorHandler,
  formatCurrencyValue,
  generateCalenderDateForMedia,
  getSrcUrl,
  sleepTime,
} from "../../helper-methods";
import MultiMediaModal from "./MultiMediaModal";
import { getMessageMediaContents } from "../../http-calls";
import CustomLoader from "../custom/CustomLoader";
import SkeletonLoading from "../SkeletonLoading";
import { DEFAULT_COVER_PICTURE } from "../../config";
import SvgIcons from "../SvgIcons";

const ViewChatMediaModal = ({
  isOpen,
  toggle,
  threadId,
  imageQuality,
  setImageQuality,
}) => {
  const mediaRefs = useRef({});

  const [data, setData] = useState([]);
  const [contents, setContents] = useState([]);
  const [contentBreak, setContentBreak] = useState({});
  const [unlockedContents, setUnlockedContents] = useState([]);
  const [dataPayload, setDataPayload] = useState({
    threadId: null,
    skip: 0,
    limit: 12,
  });

  const [dataLoading, setDataLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const [multiMediaModal, setMultiMediaModal] = useState({
    isOpen: false,
    activeIndex: 0,
  });

  const _toggleMultiMediaModal = (isOpen = false, _id = "") => {
    if (isOpen) {
      _pauseAllMediaWhenToggleIsOpen();
      toggle();
    }

    let activeIndex = 0;

    if (_id && unlockedContents?.length) {
      activeIndex = unlockedContents.findIndex((each) => each._id === _id);
    }

    setMultiMediaModal({
      isOpen,
      activeIndex,
    });
  };

  const _resetState = () => {
    // setData([]);
    // setContents([]);
    setContentBreak({});
    setHasMore(false);
    setDataPayload({
      threadId: null,
      skip: 0,
      limit: 12,
    });
  };

  const _closeModal = () => {
    _pauseAllMediaWhenToggleIsOpen();
    _resetState();
    toggle();
  };

  const _pauseAllMediaWhenToggleIsOpen = async () => {
    await sleepTime(400);
    if (mediaRefs?.current && Object.keys(mediaRefs.current)?.length) {
      Object.values(mediaRefs.current).forEach((each) => {
        try {
          if (each?.pause) each.pause();
        } catch (error) {
          console.log("error>>", error);
        }
      });
    }
  };

  const _renderContent = ({ item, index }) => {
    switch (item?.mediaType || item?.contentType) {
      case "image": {
        return (
          <>
            {item.isLocked ? (
              <i
                className="fa fa-image videoIcon"
                style={{ color: "#5F42AA" }}
              />
            ) : null}

            {contentBreak[index] ? ( // {/* if the media is not loading */}
              <Button
                className="reloadBtn"
                onClick={() =>
                  setContentBreak((prev) => ({ ...prev, [index]: false }))
                }
              >
                <i className="fa fa-refresh" />
              </Button>
            ) : (
              <img
                src={getSrcUrl(item, imageQuality)}
                alt="Media"
                loading="lazy"
                onClick={() =>
                  !item.isLocked ? _toggleMultiMediaModal(true, item._id) : {}
                }
                onError={() =>
                  setContentBreak((prev) => ({ ...prev, [index]: true }))
                }
              />
            )}
          </>
        );
      }

      case "video": {
        return (
          <>
            {item?.isLocked ? (
              <i
                className="fa fa-video-camera videoIcon"
                style={{ color: "#5F42AA" }}
              />
            ) : null}

            {contentBreak[index] ? (
              // {/* if the media is not loading */}
              <Button
                className="reloadBtn"
                onClick={() =>
                  setContentBreak((prev) => ({ ...prev, [index]: false }))
                }
              >
                <i className="fa fa-refresh" />
              </Button>
            ) : item.isLocked ? (
              <img
                src={item?._contentId.thumbnail}
                alt="Media"
                loading="lazy"
              />
            ) : (
              <video
                ref={(ref) => (mediaRefs.current[index] = ref)}
                src={""} //multimediaModal will play the video hence no src is needed
                muted
                onClick={() =>
                  !item.isLocked ? _toggleMultiMediaModal(true, item._id) : {}
                }
                poster={item?._contentId.thumbnail || DEFAULT_COVER_PICTURE}
              />
            )}
          </>
        );
      }
      case "audio": {
        return (
          <div
            className="h-100 w-100"
            onClick={() =>
              !item.isLocked ? _toggleMultiMediaModal(true, item._id) : {}
            }
          >
            <img
              src="/assets/img/microphone.png"
              alt="Microphone"
              className="audioImg"
              loading="lazy"
            />
          </div>
        );
      }
      default: {
        return <div>Oops! Not found.</div>;
      }
    }
  };

  const _getMessageMediaContents = (payload) => {
    setDataLoading(true);

    getMessageMediaContents(payload)
      .then((res) => {
        const newData = res.mediaContents?.length
          ? payload.skip > 0
            ? data.concat(res.mediaContents)
            : res.mediaContents
          : [];
        setData(newData);
        const newContents = [];
        newData.forEach((each) =>
          each.content.forEach((subEach) =>
            newContents.push({
              ...subEach,
              timestamp: each.timestamp,
              messageId: each.messageId,
              hasPurchased: each.hasPurchased,
              isLocked: subEach.amount && !each.hasPurchased ? true : false,
            })
          )
        );
        setContents(newContents);
        setUnlockedContents(newContents.filter((each) => !each.isLocked));
        setDataLoading(false);
        setHasMore(res.chatMessageCount > newData.length);
      })
      .catch((error) => {
        setDataLoading(false);
        errorHandler(error);
      });
  };

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (dataLoading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          const newDataPayload = { ...dataPayload };
          newDataPayload["skip"] = data.length;
          newDataPayload["search"] = "";
          newDataPayload["filters"] = {};

          setDataPayload(newDataPayload);

          _getMessageMediaContents(newDataPayload);
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataLoading, hasMore]
  );

  useEffect(() => {
    if (isOpen && threadId) {
      const newDataPayload = { ...dataPayload };
      newDataPayload.threadId = threadId;
      setDataPayload(newDataPayload);
      _getMessageMediaContents(newDataPayload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, threadId]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        className="modal-dialog-centered modal-lg modal-dialog-scrollable"
      >
        <ModalHeader toggle={() => _closeModal()}>
          <span className="d-flex">
            Media Library{" "}
            {dataLoading ? <CustomLoader className="mx-2" /> : null}
          </span>
        </ModalHeader>
        <ModalBody>
          {contents?.length ? (
            <>
              <div className="mediaLibraryMsgPg">
                {contents.map((item, index) => (
                  <React.Fragment key={index}>
                    {index === 0 ||
                    generateCalenderDateForMedia(item.timestamp) !==
                      generateCalenderDateForMedia(
                        contents[index - 1].timestamp
                      ) ? (
                      <div className="notificationDay">
                        {generateCalenderDateForMedia(item.timestamp)}
                      </div>
                    ) : null}
                    <div
                      key={index}
                      ref={
                        contents.length === index + 1 ? lastElementRef : null
                      }
                      className={`mediaLibraryFilesMsg ${
                        item.isLocked ? "blurPreview" : ""
                      }`}
                    >
                      {item.amount &&
                      (index === 0 ||
                        item.messageId !== contents[index - 1].messageId) ? (
                        <span className="mediaPrice">
                          {formatCurrencyValue(item.amount)}
                        </span>
                      ) : null}
                      {item.isLocked ? (
                        <div className="postLockedOverlay mediaPhotoWrap">
                          <SvgIcons type="lock" className="iconLock" />
                        </div>
                      ) : null}

                      {_renderContent({ item, index })}
                    </div>
                  </React.Fragment>
                ))}
              </div>

              {dataLoading ? (
                <SkeletonLoading type="viewChatMedia" count={18} />
              ) : null}
            </>
          ) : (
            <>
              {dataLoading ? (
                <SkeletonLoading type="viewChatMedia" count={18} />
              ) : (
                <div className="noContentFound my-3">
                  There is no media to display.
                </div>
              )}
            </>
          )}
        </ModalBody>

        <ModalFooter>
          <Button className="modalBtnCancel" onClick={() => _closeModal()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <MultiMediaModal
        isOpen={multiMediaModal.isOpen}
        contents={unlockedContents}
        activeIndex={multiMediaModal.activeIndex}
        toggle={() => _toggleMultiMediaModal()}
        imageQuality={imageQuality}
        setImageQuality={setImageQuality}
      />
    </>
  );
};

export default ViewChatMediaModal;
