import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAllCreatorBundles,
  getPostDetails,
  getPosts,
} from "../../http-calls";

const storeFeeds = createAction("storeFeeds");
const clearAllFeedData = createAction("clearAllFeedData");
const updateAPost = createAction("updateAPost");
const updateList = createAction("updateList");
const deletePostById = createAction("deletePostById");
const addSuggestedCreators = createAction("addSuggestedCreators");
const addSuggestedEvents = createAction("addSuggestedEvents");
const updateSuggestedCreator = createAction("updateSuggestedCreator");
const updateSuggestedEvent = createAction("updateSuggestedEvent");

const fetchPosts = createAsyncThunk(
  "feedData/fetchPosts",
  async (payload, thunkAPI) => {
    const res = await getPosts(payload);

    return {
      posts: res.posts,
      total: res.total,
      subCount: res.subCount,
      bundles: res.bundles,
      isConcat: payload?.skip > 0 ? true : false,
    };
  }
);

const updatePostById = createAsyncThunk(
  "feedData/updatePostById",
  async (payload, thunkAPI) => {
    const res = await getPostDetails(payload);

    return {
      post: res.post,
    };
  }
);

const getAndUpdateAllCreatorBundles = createAsyncThunk(
  "feedData/getAndUpdateAllCreatorBundles",
  async (payload, thunkAPI) => {
    const res = await getAllCreatorBundles(payload);

    return res.bundles?.length ? res.bundles : [];
  }
);

export {
  storeFeeds,
  clearAllFeedData,
  updateAPost,
  updatePostById,
  updateList,
  fetchPosts,
  getAndUpdateAllCreatorBundles,
  deletePostById,
  addSuggestedCreators,
  addSuggestedEvents,
  updateSuggestedCreator,
  updateSuggestedEvent,
};
