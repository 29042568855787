import React, { useEffect, useMemo, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ViewStoryModal from "./modals/ViewStoryModal";
import SkeletonLoading from "./SkeletonLoading";
import StoryPreviewComponent from "./StoryPreviewComponent";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 5000, min: 992 },
    items: 5,
    partialVisibilityGutter: 1,
  },
  tablet: {
    breakpoint: { max: 991, min: 576 },
    items: 6,
    partialVisibilityGutter: 1,
  },
  mobile: {
    breakpoint: { max: 575, min: 100 },
    items: 5,
    partialVisibilityGutter: 1,
  },
};

const StorySection = ({
  stories = [],
  storiesNonSub = [],
  liveEvent = [],
  liveEventNonSub = [],
  loading,
  influencerId,
  subscribedData
}) => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [viewStoryModal, setViewStoryModal] = useState({
    isOpen: false,
    data: null,
    isSubscribed: false,
  });
 
  const shareStory = queryParams.get("share-story");

  // console.log("shareStory", shareStory);
  // console.log("stories>................", stories);
  // console.log("storiesNonSub>................", storiesNonSub);


  // useEffect(() => {
  //   if (shareStory !== null) {
  //     const findStory = [];
  //     let allStories = stories.map((story) => {
  //       return story.stories.find((storyItem) => storyItem.id === shareStory);
  //     }) && storiesNonSub.map((story) => {
  //       return story.stories.find((storyItem) => storyItem.id === shareStory);
  //     });

  //     // if(allStories.length === 0) {
  //     //   allStories = storiesNonSub.map((story) => {
  //     //     return story.stories.find((storyItem) => storyItem.id === shareStory);
  //     //   })
  //     // }

  //     if (allStories.length > 0) {
  //       allStories.forEach((item) => {
  //         if (item !== undefined) {
  //           findStory.push(item);
  //         }
  //       });
  //     }

  //     console.log("allStoies", allStories);
  //     if (findStory.length > 0) {
  //       setViewStoryModal({
  //         isOpen: true,
  //         data: findStory,
  //         isSubscribed: true,
  //       });
  //     }
  //   }
  // }, [shareStory, stories, storiesNonSub]);

  useEffect(() => {
    if (!shareStory) return; // Early exit
  
    const allStories = [
      ...stories.map((story) =>
        story.stories.find((storyItem) => storyItem.id === shareStory)
      ),
      ...storiesNonSub.map((story) =>
        story.stories.find((storyItem) => storyItem.id === shareStory)
      ),
    ];
  
    const findStory = allStories.filter((item) => item !== undefined);
  
    // console.log("allStories", allStories);
    // console.log("findStory", findStory);

    if (findStory.length > 0) {
      setViewStoryModal({
        isOpen: true,
        data: findStory,
        isSubscribed: true,
      });
    }
  }, [shareStory, stories, storiesNonSub]);
  

  const totalCarouselSlides = useMemo(() => {
    return (
      stories.length +
      storiesNonSub.length +
      liveEvent.length +
      liveEventNonSub.length
    );
  }, [stories, storiesNonSub, liveEvent, liveEventNonSub]);

  const _toggleViewStoryModal = (
    isOpen = false,
    data = null,
    isSubscribed = false
  ) => {
    setViewStoryModal({ isOpen, data, isSubscribed });
  };

  const _goToInfluencerProfilePage = (story) => {
    const influencerUsername = story?._createdBy?.username;
    history.replace(`/influencer/${influencerUsername}`);
  };

  const _goToLiveEventsPage = () => {
    history.push("/live-events");
  };

  const _handleLiveEventRedirect = (liveEventStory, isSubscribed = false) => {
    if (isSubscribed) {
      _goToLiveEventsPage();
    } else {
      _goToInfluencerProfilePage(liveEventStory);
    }
  };

  // const isAllStoriesViewed=stories?.length?stories.map((each) => {
  //     console.log(each?.stories);
  //     let isViewData=true;
  //       each?.stories?.forEach((data) => {
  //         if(!data?.isViewed){
  //           isViewData=false;
  //         }
  //         console.log("isViewed",data);
  //       });
  //       return isViewData;
  //     }):""
  // console.log("sto>>>>>>>",isAllStoriesViewed);
  
  return (
    <>
      {stories?.length ||
      storiesNonSub?.length ||
      liveEvent?.length ||
      liveEventNonSub?.length ? (
        <Carousel
          responsive={responsive}
          partialVisible={true}
          className="storyCarousel"
          draggable={false}
          showDots={false}
          keyBoardControl={true}
          customTransition="all .5"
          transitionDuration={500}
          renderButtonGroupOutside={true}
          slidesToSlide={totalCarouselSlides || 0}
          removeArrowOnDeviceType={["mobile"]}
          arrows={true}
          swipeable={true}
        >
          {/* 1. Live of subscribed influencers */}
          {liveEvent?.length
            ? liveEvent.map((each, index) => (
                <StoryPreviewComponent
                  key={each?._createdBy?.id}
                  story={each}
                  handleLiveEventRedirect={() =>
                    _handleLiveEventRedirect(each, true)
                  }
                  influencerId={influencerId}
                  isLiveEventStory={true}
                />
              ))
            : null}

          {/* 2. Live of non-subscribed influencers */}
          {liveEventNonSub?.length
            ? liveEventNonSub.map((each, index) => (
                <StoryPreviewComponent
                  key={each?._createdBy?.id}
                  story={each}
                  handleLiveEventRedirect={() =>
                    _handleLiveEventRedirect(each, false)
                  }
                  influencerId={influencerId}
                  isLiveEventStory={true}
                />
              ))
            : null}

          {/* 3. stories of subscribed creators */}

          {stories?.length
            ? stories?.map((each) => (
                <StoryPreviewComponent
                  key={each?._createdBy?._id}
                  story={each}
                  toggleViewStoryModal={(stories) => {
                    _toggleViewStoryModal(true, stories, true);
                  }}
                  influencerId={influencerId}
                  isSubscribed={true}
                />
              ))
            : null}

          {/* 4. stories of non-subscribed creators */}

          {storiesNonSub?.length
            ? storiesNonSub?.map((each) => (
                <StoryPreviewComponent
                  key={each?._createdBy?.id}
                  story={each}
                  toggleViewStoryModal={(stories) =>
                    _toggleViewStoryModal(true, stories, false)
                  }
                  influencerId={influencerId}
                  isSubscribed={false}
                />
              ))
            : null}
        </Carousel>
      ) : loading ? (
        <SkeletonLoading type={"story"} count={4} />
      ) : (
        <></>
      )}

      {viewStoryModal?.isOpen ? (
        <ViewStoryModal
          isOpen={viewStoryModal?.isOpen}
          data={viewStoryModal?.data}
          isSubscribed={viewStoryModal?.isSubscribed}
          subscribedData={subscribedData}
          toggle={_toggleViewStoryModal}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default StorySection;
