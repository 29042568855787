import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { DEFAULT_COVER_PICTURE, DEFAULT_PROFILE_PICTURE } from "../../config";
import {
  copyPostPublicLink,
  formatDate,
  getCommentIconClassName,
  getLikeIconClassName,
  getLowResolutionLink,
  mentionUserInputParse,
} from "../../helper-methods";
import ConfigureString from "../ConfigureString";
import ReadMoreReadLess from "../custom/ReadMoreReadLess";
import SvgIcons from "../SvgIcons";

const PayPerViewer = ({
  feed,
  isSubscriptionRequired,
  toggleCommentSection,
  toggleLikePost,
  toggleFlagModal,
  goToPostDetailsPage,
  toggleFeedTipModal,
  scrollToTop = () => {},
}) => {
  const history = useHistory();

  const influencersOnlineData = useSelector(
    (state) => state?.influencersOnlineData
  );

  const [isVideoOpen, setIsVideoOpen] = useState(false);

  const _subscribe = () => {
    try {
      window.scrollTo(0, 0);
      scrollToTop();
    } catch (error) {
      console.log({ error });
    }
  };
  console.log(feed);
  return (
    <Card className="cardFeed">
      <CardHeader onClick={() => goToPostDetailsPage(isSubscriptionRequired)}>
        <div className="d-flex align-items-center">
          <div className="mr-1 position-relative">
            <img
              src={
                getLowResolutionLink(feed?._influencer?.profilePicture) ||
                DEFAULT_PROFILE_PICTURE
              }
              onError={(e) => (e.target.src = DEFAULT_PROFILE_PICTURE)}
              alt="Profile Img"
              className="userImg"
              loading="lazy"
            />
            {/* online status */}
            {influencersOnlineData?.influencers?.[feed?._influencer?._id] ? (
              <span className="fa fa-circle onlineStatus" />
            ) : null}
          </div>
          <div>
            <div className="profileID">
              @{feed?._influencer?.username || "N/A"}
            </div>
          </div>
        </div>
        <p>
          <ConfigureString keyString={"ppv"} />
        </p>
      </CardHeader>
      <CardBody>
        {/* Clicking the below div should take the user to the details page for payment of the PPV. */}
        <div className="customPaddingMob">
          {feed?.text?.length ? (
            <div>{mentionUserInputParse(feed.text)}</div>
          ) : null}

          <div className="sharedContent">
            <div className="customPaddingMob">
              {feed?._payperview?.title ? (
                <div className="ppvVideoName">{feed._payperview.title}</div>
              ) : null}

              {feed?._payperview?.description?.length ? (
                <ReadMoreReadLess
                  className="readMore"
                  text={feed._payperview.description}
                />
              ) : null}
            </div>

            <div className="mediaPhotoWrap-Feed">
              {isVideoOpen ? (
                <video
                  src={feed?._payperview?.videoUrl}
                  controls
                  controlsList="nodownload"
                  disablePictureInPicture
                  onError={(e) => setIsVideoOpen(false)}
                />
              ) : null}

              {!isVideoOpen ? (
                <>
                  <div className="shadowOverlayImg" />
                  <img
                    className="mediaPhoto-Feed"
                    src={feed?._payperview?.coverImage || DEFAULT_COVER_PICTURE}
                    onError={(e) => (e.target.src = DEFAULT_COVER_PICTURE)}
                    alt="Cover Img"
                    loading="lazy"
                  />
                  {!feed._ppvPaid || isSubscriptionRequired ? (
                    <div className="postLockedOverlay">
                      <SvgIcons type="lock" className="iconLock" />

                      <Button
                        disabled={!feed?._influencer?.paymentEnabled}
                        className="paymentBtnTier"
                        onClick={() => {
                          if (isSubscriptionRequired) {
                            _subscribe();
                          } else {
                            history.push(`/ppv/${feed?._payperview?.id}`);
                          }
                        }}
                      >
                        {isSubscriptionRequired ? "Subscribe" : "Unlock now"}
                      </Button>
                    </div>
                  ) : (
                    <Button
                      className="videoPlayBtn"
                      onClick={() =>
                        history.push(`/ppv/${feed?._payperview?.id}`)
                      }
                    >
                      <i className="fa fa-play" />
                    </Button>
                  )}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </CardBody>

      <CardFooter className={"footerFeed"}>
        <div className="d-flex">
          <Button
            className={
              "mr-2 mr-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleLikePost()}
          >
            <i className={getLikeIconClassName(feed)} />
          </Button>

          <Button
            className={
              "mr-2 mr-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleCommentSection()}
          >
            <i className={getCommentIconClassName(feed)} />
          </Button>

          <Button
            className={
              "mr-2 mr-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleFeedTipModal(true)}
            disabled={!feed?._influencer?.paymentEnabled}
          >
            <img src="/assets/img/dollar.png" alt="Dollar" loading="lazy" />
          </Button>

          <Button
            className={
              "ml-auto " +
              (isSubscriptionRequired
                ? feed?.isPublic && feed?.isFree
                  ? ""
                  : "disabled-feed-options"
                : "")
            }
            title="Share"
            onClick={() =>
              copyPostPublicLink({
                influencerUsername: feed?._influencer?.username,
                postId: feed?._id,
              })
            }
          >
            <img
              src="/assets/img/quick-link.png"
              alt="share"
              loading="lazy"
              style={{ verticalAlign: -2 }}
            />
          </Button>

          <Button
            title="Report"
            className={
              "ml-3 ml-sm-3 " +
              (isSubscriptionRequired ? "disabled-feed-options" : "")
            }
            onClick={() => toggleFlagModal(true)}
          >
            <i className="fa fa-flag-o" />
          </Button>
        </div>

        {feed?.likes || feed?.comments ? (
          <div className="mt-1">
            {feed?.likes ? (
              <span>
                {feed?.likes} {feed?.likes > 1 ? "Likes" : "Like"}
              </span>
            ) : null}
            {feed?.comments ? (
              <span>
                {feed?.comments} {feed?.comments > 1 ? "Comments" : "Comment"}
              </span>
            ) : null}
          </div>
        ) : null}

        {/* post time */}
        <div className="postTime-Feed">{formatDate(feed?.startDate)}</div>
      </CardFooter>
    </Card>
  );
};

export default PayPerViewer;
