import React, { useState, useRef, useEffect } from "react";
import VisibilitySensor from "react-visibility-sensor";

const AudioPlayer = ({ className = "", wrapperClassName = "", url }) => {
  const audioRef = useRef();
  const [isAudioPlay, setIsAudioPlay] = useState(false);

  // Ensure audio starts from the beginning when the component is first rendered
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.currentTime = 0; // Reset audio position to the start
    }
  }, [url]);

  const _onToggleAudioPlayPause = (isPlay = false) => {
    try {
      if (audioRef?.current) {
        if (!isPlay && audioRef.current.pause) {
          audioRef.current.pause();
        } else if (isPlay && audioRef.current.play) {
          audioRef.current.play();
        }
      }
      setIsAudioPlay(isPlay);
    } catch (error) {
      console.error("Error handling audio play/pause:", error);
      setIsAudioPlay(false);
    }
  };
  console.log(audioRef);
  return (
    <VisibilitySensor
      onChange={(isVisible) => {
        if (!isVisible) {
          _onToggleAudioPlayPause(false);
        }
      }}
    >
      <div className={className}>
        <div className={`audioWrapper ${wrapperClassName}`}>
          <div
            className={`audioRipple ${isAudioPlay ? "audioRippleStart" : ""}`}
          >
            <img
              src="/assets/img/microphone.png"
              alt="Microphone"
              loading="lazy"
            />
          </div>
          <audio
            ref={audioRef}
            controls
            onPlay={() => _onToggleAudioPlayPause(true)}
            onPause={() => _onToggleAudioPlayPause(false)}
            controlsList="nodownload"
            preload="auto"
            src={url}
            onLoadedMetadata={(e) => {
              // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
              if (e.target.duration === Infinity) {
                e.target.currentTime = Number.MAX_SAFE_INTEGER;
                e.target.ontimeupdate = () => {
                  e.target.ontimeupdate = null;
                  e.target.currentTime = 0;
                };
              }
            }}
          />
        </div>
      </div>
    </VisibilitySensor>
  );
};

export default AudioPlayer;
